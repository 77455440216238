import React, {Component} from 'react'
import {PencilIcon} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    fillFieldsFromData,
    getProp
} from "../../util/util";
import {Field} from "../../data/services/fields";
import PageHeader from "../../components/layout-dashboard/page-header";
import Loader from "../../components/loader";
import {getResource, updateResource} from "../../data/actions/resource";
import {ClipboardListIcon} from "@heroicons/react/outline";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import SurveyBuilder from "./survey-builder";

class SurveysSinglePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editModalOpen: false,
        };

        this.assessmentTabRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.SurveysQuestions
        }))
    };

    /** UI Events
     ================================================================= */
    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        });
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getQuery = () => {
        return {
            SurveyID: this.getId(),
        }
    };

    getFields = (item = null) => {
        const fieldTemplates = {
            Survey: new Field("Survey", '', ['empty'], false, "text"),
            Description: new Field("Description", '', ['empty'], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const Survey = getProp(this.props, "resource.data", {});

        const isLoading = getProp(this.props, "resource.isLoading", null)

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.survey")}
                >
                    <div className="mt-5 flex xl:mt-0 xl:ml-4">
                        <button
                            onClick={this.handleToggleEditModal}
                            className="btn btn-primary mt-5 sm:mt-0"
                        >
                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                            Edit
                        </button>
                    </div>
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between"
                    >
                        {(!!isLoading) && (
                            <Loader/>
                        )}

                        {(!isLoading) && (
                            <React.Fragment>
                                <div className="flex-1 min-w-0">
                                    <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                        {Survey.Survey}
                                    </h1>

                                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                       aria-hidden="true"/>
                                            {Survey.Description}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 flex xl:mt-0 xl:ml-4">
                                    <span className="hidden sm:block">

                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </header>

                <main className="pb-16 relative custom-min-h-tab bg-inverse">
                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        {/* Content */}
                        <div className="mt-6">

                            <SurveyBuilder
                                id={this.getId()}
                                translate={this.props.translate}
                                data={getProp(this.props, "resource.data", {})}
                                isLoading={getProp(this.props, "resource.isLoading", false)}
                                {...this.props}
                            />

                        </div>
                    </div>
                </main>

                <ModalSaveResource
                    title={"Edit survey"}
                    addClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(getProp(this.props, "resource.data", {}))}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = getProp(this.props, "resource.data.SurveyID", {})
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Surveys,
                                piggyResource: Resources.SurveysQuestions
                            }));
                            this.handleToggleEditModal(null)
                        }
                    }}
                    translate={this.props.translate}
                />
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(SurveysSinglePage);
