import React, {Component} from 'react'
import {fillFieldsFromData, getProp} from "../../../util/util";
import {Field} from "../../../data/services/fields";
import QuestionMatrix from "./question-matrix";
import RatingScales from "./rating-scales";
import LikertScales from "./likert-scales";
import QuestionDropdown from "./question-dropdown";
import FieldText from "../../../components/field-text";
import FieldTextarea from "../../../components/field-textarea";
import {InformationCircleIcon, PlusIcon} from "@heroicons/react/outline";
import Loader from "../../../components/loader";
import SurveyBuilderSlideOver from "./survey-builder-slide-over";
import ModalConfirm from "../../../components/modal/modal-confirm";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {createResource, deleteResource, updateResource} from "../../../data/actions/resource";

export default class SurveyBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null,
            selectedItemIndex: -1,
            questions: [],
            questionItems: {},
            isSurveyEditVisible: false,
            confirmModalOpen: false
        };

        this.ratingScalesTop = 10;
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.isLoading && !this.props.isLoading) {
            let questionItems = getProp(this.props.data, "Items", []).reduce((memo, it, i) => {
                const orderNumber = i + 1;

                if (it.Items.length) {
                    memo[orderNumber] = it.Items.map(it => it.SurveyQuestionItemName);
                }

                return memo
            }, {});

            this.setState({
                questions: getProp(this.props.data, "Items", []),
                questionItems: questionItems
            })
        }
    }

    /** Data Events
     ================================================================= */


    /** UI Events
     ================================================================= */
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    toggleSurveyForm = (it = null, i = -1) => {
        this.setState({
            isSurveyEditVisible: !this.state.isSurveyEditVisible,
            selectedItem: it,
            selectedItemIndex: i
        })
    }

    getQuestionFields = (item = null, excludeFields = null) => {
        // ArchivedDate: null
        // CompanyID: 1
        // CreateUpdateDate: "1900-01-01 00:00:00"
        // DesiredAnswer: "1"
        // Instructions: "Instrukcije"
        // Items: []
        // OrderNum: null
        // RankingPoints: 1
        // SurveyID: 1
        // SurveyQuestion: "Pitanje"
        // SurveyQuestionID: 3
        // SurveyQuestionTypeID: 7
        // UpdatedByContactID: 1

        const fieldTemplates = {
            SurveyQuestionTypeID: new Field("SurveyQuestionTypeID", 4, ['empty'], false, 'select'),
            SurveyQuestion: new Field("SurveyQuestion", null, ['empty'], false, 'text'),
            Instructions: new Field("Instructions", '', [''], false, 'textarea'),

            DesiredAnswer: new Field("DesiredAnswer", null, [], false, 'hidden'),
            RankingPoints: new Field("RankingPoints", 1, [], false, 'hidden'),

            OrderNum: new Field("OrderNum", 0, ['integer_positive'], false, 'text'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getQuery = () => {
        return {
            id: this.state.selectedItem.SurveyID
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, isLoading} = this.props;

        const questionMatrixID = 3;
        const ratingScalesID = 1;
        const likertScalesID = 2;
        const dropDownID = 4;
        const rankingQuestionsID = 5;
        const textQuestionID = 6;
        const numberQuestionID = 7;

        const surveyAnswers = getProp(this.props.data, "answers", null);

        const survey = getProp(this.state, "questions", []).map((item, i) => {
            let dropdownValues = {};
            let dropdownDesiredValues = null;
            if (item.SurveyQuestionTypeID == dropDownID) {
                dropdownValues = item.Items.reduce((memo, it, i) => {
                    memo[it.SurveyQuestionItemID] = it.SurveyQuestionItemName;
                    return memo
                }, {});

                dropdownDesiredValues = item?.DesiredAnswer ? item.DesiredAnswer.split(",").map((it, i) => {
                    return {label: item?.Items[it - 1]?.SurveyQuestionItemName, value: item?.Items[it - 1].SurveyQuestionItemID}
                }) : [];
            }
            return (
                <div key={item.SurveyQuestion} className="flex flex-col justify-center p-4 border-t ">
                    <div className={"flex justify-between items-center px-4 pb-4 mb-4 border-b border-dashed"}>
                        <div>
                            <p className="m-0">
                                <span>{i + 1}. </span>

                                <span className="text-lg text-secondary-800">{item.SurveyQuestion}</span>

                                {!surveyAnswers && item.RankingPoints && (
                                    <span className="text-gray-400 font-bold"> - {item.RankingPoints} points </span>
                                )}
                            </p>
                        </div>

                        {!surveyAnswers && (
                            <div>
                                <button
                                    className="btn btn-outline mr-3"
                                    onClick={() => this.toggleSurveyForm(item, i)}
                                >
                                    {translate("btn.edit")}
                                </button>

                                <button
                                    className="mr-2 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"
                                    onClick={() => this.handleToggleConfirmModal(item)}
                                >
                                    {translate("btn.delete")}
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="container mx-4">
                        {item.SurveyQuestionTypeID == questionMatrixID && (
                            <QuestionMatrix
                                index={i}
                                matrixQuestions={this.state.questionItems[i + 1]}
                                translate={translate}
                                preview={true}
                            />
                        )}

                        {item.SurveyQuestionTypeID == ratingScalesID && (
                            <RatingScales
                                desiredAnswer={item.DesiredAnswer}
                                topRating={this.ratingScalesTop}
                                preview={true}
                            />
                        )}

                        {item.SurveyQuestionTypeID == likertScalesID && (
                            <LikertScales
                                index={i}
                                desiredAnswer={item.DesiredAnswer}
                                preview={true}
                            />
                        )}

                        {item.SurveyQuestionTypeID == dropDownID && (
                            <QuestionDropdown
                                preview={true}
                                desiredQuestions={dropdownDesiredValues}
                                values={dropdownValues}
                            />
                        )}

                        {item.SurveyQuestionTypeID == numberQuestionID && (
                            <div className="flex items-center">
                                <span className="mr-2 text-primary-600">>=</span>

                                <FieldText
                                    className="form-control w-48"
                                    name={"number_" + item.name + "_" + i + 1}
                                    value={item.DesiredAnswer}
                                    disabled={true}
                                    placeholder={this.props.translate("field.placeholder.type_question")}
                                />
                            </div>
                        )}

                        {item.SurveyQuestionTypeID == textQuestionID && (
                            <React.Fragment>
                                <FieldTextarea
                                    className="form-control"
                                    name={"number_" + item.name + "_" + i + 1}
                                    value={item.DesiredAnswer}
                                    disabled={true}
                                    placeholder={this.props.translate("field.placeholder.type_text_question")}
                                />
                            </React.Fragment>
                        )}

                        {item.SurveyQuestionTypeID == rankingQuestionsID && (
                            item.Items.map((it, i) => {
                                return (
                                    <div className="flex mb-5">
                                        <div className="px-2 mt-1 w-12 flex items-center">
                                            <div className="mr-2 cursor-move select-none mr-3">
                                                <div className="w-3 grid grid-cols-2 gap-1">
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">{i + 1}. {it.SurveyQuestionItemName}</div>
                                    </div>
                                )
                            })
                        )}

                        {!!item.Instructions && (
                            <div className="rounded-md bg-secondary-50 p-4 mt-6">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon
                                            className="h-5 w-5 text-secondary-400"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-secondary-700">{item.Instructions}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <div className="mt-6 flex flex-wrap">
                    {!isLoading && (
                        <div className="flex-shrink-0 ml-auto mb-3">
                            <button
                                type="button"
                                onClick={() => this.toggleSurveyForm()}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                Add question
                            </button>
                        </div>
                    )}
                </div>

                <div className="mt-4">
                    <section className="mb-8">
                        <div className="bg-white shadow sm:rounded-md">
                            <div className="px-4 py-1 sm:px-6 bg-secondary-100 flex justify-between items-center">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">
                                    Survey questions
                                </h2>
                            </div>

                            {!survey.length && !isLoading && (
                                <div className="px-4 py-2">
                                    <p className="text-secondary-600">No questions. Click on create button to add
                                        some.</p>
                                </div>
                            )}

                            {!!survey.length && !isLoading && (
                                <div className="">
                                    {survey}
                                </div>
                            )}

                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}
                        </div>
                    </section>

                    <SurveyBuilderSlideOver
                        panelTitle={!!this.state.selectedItem ? "Update question" : "Add question"}
                        show={this.state.isSurveyEditVisible}
                        onClose={this.toggleSurveyForm}
                        canSubmit={true}
                        translate={translate}
                        fields={this.getQuestionFields(this.state.selectedItem)}
                        questionItems={this.state.questionItems[this.state.selectedItemIndex + 1]}
                        questionNum={this.state.questions.length}
                        topRating={this.ratingScalesTop}
                        onSubmit={(fields, params, additionalItems = {}) => {

                            if (params) {
                                let options = [];
                                if (!!additionalItems?.Items) {
                                    options = additionalItems.Items.map((question, i) => {
                                        let q = {}
                                        q.SurveyQuestionItemName = question;
                                        q.ItemOrderNum = i + 1;

                                        return q;
                                    });

                                    params.Items = options;

                                    if (params.PreferredQuestion) params.PreferredQuestion = additionalItems.PreferredQuestion + 1;
                                }

                                params.SurveyID = this.props.id;


                                // Update or create
                                if (!!this.state.selectedItem) {
                                    params.SurveyID = this.state.selectedItem.SurveyID;
                                    params.SurveyQuestionID = this.state.selectedItem.SurveyQuestionID;
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get("user"),
                                        params: params,
                                        query: {SurveyID: this.props.id},
                                        resource: Resources.SurveysQuestions,
                                        piggyResource: Resources.SurveysQuestions
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get("user"),
                                        params: params,
                                        query: {SurveyID: this.props.id},
                                        resource: Resources.SurveysQuestions,
                                        piggyResource: Resources.SurveysQuestions
                                    }))
                                }

                                this.toggleSurveyForm();
                            }
                        }}
                    >

                    </SurveyBuilderSlideOver>

                    <ModalConfirm
                        title={"Confirm delete"}

                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    SurveyQuestionID: this.state.selectedItem.SurveyQuestionID
                                }, this.getQuery()),
                                piggyQuery: {SurveyID: this.props.id},
                                resource: Resources.SurveysQuestions,
                                piggyResource: Resources.SurveysQuestions
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </div>
            </React.Fragment>
        )
    }
}
