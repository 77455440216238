import React from "react";
import NavResponsive from "../nav-responsive";

export const Tabs = ({tabs, onTabChange}) => {

    return (
        <>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>

                <select
                    id="tabs"
                    name="tabs"
                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                    defaultValue={tabs.find((tab) => tab.current).name}
                    onChange={e => onTabChange(e.target.value)}
                >
                    {tabs.map((tab) => (
                        <option
                            key={tab.name}
                            value={tab.resource}
                        >
                            {tab.name}
                        </option>
                    ))}
                </select>
                <div className="border-b-2 border-primary-500 mt-6"/>
            </div>

            <div className="hidden sm:block">
                <div className="border-b border-secondary-200">
                    <NavResponsive
                        tabs={tabs}
                        onTabChange={onTabChange}
                    />
                </div>
            </div>
        </>
    )
}
