import React, {useEffect, useState} from 'react'
import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import {getProp} from "../../../util/util";
import {XIcon} from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function QuestionDropdown(props) {
    let question = {};

    const {preview, onChange, name, value = 0, disabledEdit} = props;

    const selectedQuestions = getProp(props, "desiredQuestions", []).reduce((memo, it) => { memo[it.value] = it.label; return memo}, {});

    const selectDefault = {id:0, name: props.placeholder ?? "Select...", selected: false};

    for (const [key, value] of Object.entries(props.values)) {
        const item = {}
        item.id = key;
        item.name = value;
        item.selected = !!preview ? selectedQuestions[key] == value : props.value == key;
        question[key] = item;
    }

    question[0] = selectDefault;

    const [selected, setSelected] = useState(question[value])

    useEffect(() => {
        setSelected(question[!!value ? value : 0])
    }, [value]);

    return (
        <div className="w-72">
            {selected && (
                <Listbox value={selected} onChange={(preview || disabledEdit) ? () => null : (select) => onChange(name, select.id)}>
                    <div className="mt-1 relative">
                        {selected.id !== 0 && (
                            <button
                                type="button"
                                className="absolute z-10 right-10 top-2 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => setSelected(selectDefault)}
                            >
                                <span className="sr-only">Clear dropdown</span>
                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        )}

                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                            <div className="flex items-center">
                                {!!preview && (
                                    <span
                                        className={classNames(
                                            selected.selected ? 'bg-green-400' : 'bg-gray-200',
                                            'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                        )}
                                    />
                                )}

                                <span className="ml-3 block truncate">{selected.name}</span>
                            </div>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {Object.values(question).filter(it => it.id !== 0).map((question) => (
                                <Listbox.Option
                                    key={question.id}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'text-black bg-primary-600' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                        )
                                    }
                                    value={question}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <div className="flex items-center">
                                                {!!preview &&  (
                                                    <span
                                                        className={classNames(active ? "bg-black" : question.selected ? 'bg-primary-600' : 'bg-gray-200',
                                                            'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                                        )}
                                                    />
                                                )}

                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                    {question.name}
                                                </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? 'text-black' : 'text-primary-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                            ) : null}

                                            {preview && question.selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? 'text-black' : 'text-primary-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>

                    </div>
                </Listbox>
            )}
        </div>
    )
}
