import {call, put, takeLatest} from "redux-saga/effects";
import {checkUserHelper} from "../services/api-util";
import Api from "../services/api";
import {doneGetNotificationList, errorGetNotificationList} from "../actions/notifications";

const TEST = [
    {
        id: 3,
        person: {
            name: "Lindsay Walton",
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'
        },
        content: 'Accepted you for the role of',
        target: "CIO",
        time: '5m',
        unread: true
    }, {
        id: 2,
        person: {
            name: "John Doe",
            imageUrl:
                'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'
        },
        content: "Applied for the role of",
        target: "CIO",
        time: '1h',
        unread: true
    }, {
        id: 1,
        person: {
            name: "John Doe",
            imageUrl:
                'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'
        },
        content: "Joined",
        target: "GoLeir",
        time: '2h',
        unread: false
    },
    // More items...
]

export function* getNotificationListCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    // const result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    const result = {data: {
            list: TEST,
            unreadCount: 2
        }, status: 0};

    if (result && result.status === 0) {
        yield put(doneGetNotificationList(result.data));
    } else {
        yield put(errorGetNotificationList(result.data));
    }
}

export function* watchGetNotificationList() {
    yield takeLatest('GET_NOTIFICATION_LIST', getNotificationListCall);
}