export default class Resources {
    static User = "user";
    static Users = "users";
    static UserDevices = "user/logged-devices";

    static Ailments = "ailments";
    static Ailment = "ailment";
    static AilmentIndications = "ailment/indications";
    static AilmentTreatment = "ailment/treatment";
    static AilmentTraining = "ailment/training";
    static AilmentSupplements = "ailment/supplements";
    static AilmentHomework = "ailment/homework";
    static AilmentDiet = "ailment/diet";

    static Client = "client";
    static Clients = "clients";
    static ClientAssessments = "client/assessments";
    static ClientAssessmentsInfo = "client/assessments/info";
    static ClientAppointments = "client/appointments";
    static ClientAppointmentNotes = "client/appointments-notes";
    static ClientAppointmentsAssessments = "client/appointments/assessments";
    static ClientTests = "client/tests";
    static ClientAilments = "client/ailments";
    static ClientPlans = "client/plans";
    static ClientNotes = "client/notes";

    static ClientInvite = "client/invite";

    static ClientHistory = "client/medical-history";
    static ClientMedications = "client/medications";
    static ClientFamilyHistory = "client/family-history";
    static ClientSurgeries = "client/surgeries";
    static ClientAllergies = "client/allergies";

    static ClientInitialAssessments = "client/assessments/initial-assessment";
    static ClientPainAssessments = "client/assessments/pain-assessment";
    static ClientOneOffAssessmentsQuestions = "client/assessments/one-off-assessment/question";
    static ClientOneOffAssessmentsAilment = "client/assessments/one-off-assessment/ailment";
    static ClientPainAssessmentsQuestions = "client/assessments/pain-assessment/question";
    static ClientPainAssessmentsAilments = "client/assessments/pain-assessment/ailment";
    static ClientPerformanceAssessment = "client/assessments/performance-assessment";
    static ClientBodyScan = "client/assessments/body-scan";

    static ClientConcussionAssessments = "client/assessments/concussion-assessment";
    static ClientConcussionAssessmentsQuestions = "client/assessments/concussion-assessment/questions";
    static ClientConcussionAssessmentsQuestion = "client/assessments/concussion-assessment/question";
    static ClientConcussionAssessmentsAilments = "client/assessments/concussion-assessment/ailment"

    static ExtClient = 'ext-client';
    static ExtClientHistory = 'ext-client/medical-history';
    static ExtClientMedications = 'ext-client/medications';
    static ExtClientFamilyHistory = 'ext-client/family-history';
    static ExtClientSurgeries = 'ext-client/surgeries';
    static ExtClientAllergies = 'ext-client/allergies';

    static ExtClientAssessments = "ext-client/assessments";
    static ExtClientAssessmentsInfo = "ext-client/assessments/info";
    static ExtClientAppointments = "ext-client/appointments";
    static ExtClientAppointmentNotes = "client/external-appointments-notes";
    static ExtClientAppointmentsAssessments = "ext-client/appointments/assessments";
    static ExtClientTests = "ext-client/tests";
    static ExtClientAilments = "ext-client/ailments";
    static ExtClientPlans = "ext-client/plans";
    static ExtClientNotes = "client/external-client-notes";

    static ExtClientRegister = "ext-client/register";

    static Locations = "locations";

    static Surveys = "surveys";
    static SurveysQuestions = "surveys/questions";

    static LookupSubPart = "lookup/sub-part";

    static Physicians = "physicians";

    static Company = "company";
    static CompanyImage = "company/image";

    static LookupPartOfBody = "lookup/part-of-body";
    static LookupSubPartOfBody = "lookup/sub-part-of-body";
    static LookupSubPartOfBodyPalpate = "lookup/sub-part-of-body-palpate";
    static LookupSubPartOfBodyVisual = "lookup/sub-part-of-body-visual";
    static LookupSubPartOfBodyWhen = "lookup/sub-part-of-body-when";
    static LookupSubPartOfBodyPlace = "lookup/sub-part-of-body-place";
}
