import React, {Component} from 'react'
import LayoutWelcome from "../../components/layout-welcome";
import {connect} from "react-redux";
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    fieldsToHtml,
    fillFieldsFromData,
    getHomePage,
    getProp,
    scrollErrorIntoView,
    userBelongsToType
} from "../../util/util";
import PageHeader from "../../components/layout-dashboard/page-header";
import Steps from "../../components/steps";
import DialogResourceListTab from "../../components/resource-dialog-list-tab";
import Loader from "../../components/loader";
import ModalConfirm from "../../components/modal/modal-confirm";

class WelcomePage extends Component {

    constructor(props) {
        super(props);

        this.homepage = "/client-profile";

        this.steps = [
            {
                step: 0,
                id: '01',
                name: this.props.translate("tab.personal_information"),
                desc: 'Use a permanent address where you can receive mail.',
                resource: Resources.ExtClient,
                status: 'current'
            }, // 'complete'
            {
                step: 1,
                id: '02',
                name: this.props.translate("tab.client_medical_history"),
                resource: Resources.ExtClientHistory,
                status: 'upcoming'
            },
            {
                step: 2,
                id: '03',
                name: this.props.translate("tab.client_medications"),
                resource: Resources.ExtClientMedications,
                status: 'upcoming'
            },
            {
                step: 3,
                id: '04',
                name: this.props.translate("tab.client_family_history"),
                resource: Resources.ExtClientFamilyHistory,
                status: 'upcoming'
            },
            {
                step: 4,
                id: '05',
                name: this.props.translate("tab.client_surgeries"),
                resource: Resources.ExtClientSurgeries,
                status: 'upcoming'
            },
            {
                step: 5,
                id: '06',
                name: this.props.translate("tab.client_allergies"),
                resource: Resources.ExtClientAllergies,
                status: 'upcoming'
            },
        ]

        this.state = {
            fields: this.getFields(),
            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            viewModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
            steps: this.steps,
            stepsProgress: 0,
            selectedTab: this.steps[0],
            mainFormUpdate: true
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (!userBelongsToType(this.props.user.data, ["Client"])) {
            this.props.history.push(getHomePage(this.props.user.data));
        } else {
            this.fetchData();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedTab === this.steps[0] && !!this.props.resource.data && (this.props.resource.data !== prevProps.resource.data)) {
            const data = getProp(this.props.resource, "data", []);

            if (data.Registered) {
                this.props.history.push(getHomePage(this.props.user.data, true));
            }

            this.setState({
                fields: this.getFields(data)
            })
        }

        if (this.props.resource !== prevProps.resource && !!this.props.resource.update) {
            this.changeStep(1);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value),
            mainFormUpdate: true
        });
    };

    handleStepChange = (index) => {
        if (!!this.state.mainFormUpdate) {
            this.submit();
        } else if (index === this.state.steps.length) {
            this.setState({
                confirmModalOpen: () => this.props.history.push(this.homepage)
            }, () => this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: {},
                resource: Resources.ExtClientRegister
            })))
        } else {
            this.changeStep(index)
        }
    };

    submit = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                query: {},
                params: Object.assign({}, FieldsManager.getFieldKeyValues(validatedFields), {
                    LocationName: null,
                    PrimaryPhysician: null,
                }),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName()
            }));

            this.setState({
                mainFormUpdate: false
            })
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    changeStep = (index) => {
        let stepsProgress = this.state.stepsProgress;
        if (index > stepsProgress) {
            stepsProgress = index;
        }

        this.setState({
            steps: this.state.steps.map((it, i) => {
                it.status = i === index ? 'current' : it.status === 'upcoming' ? "upcoming" : "complete";
                return it;
            }),
            stepsProgress: stepsProgress,
            selectedTab: this.state.steps[index]
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ClientID";
    }

    getResourceName = () => {
        return this.state.selectedTab.resource;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const labelClass = "block text-sm font-medium text-gray-700";
        const formGroupClass = "col-span-6 sm:col-span-3";

        const fieldTemplates = {
            LocationName: new Field("LocationName", '', [''], true, "text", {labelClass, formGroupClass}),
            PrimaryPhysician: new Field("PrimaryPhysician", '', [''], true, "text", {labelClass, formGroupClass}),

            FirstName: new Field("FirstName", '', ['empty'], false, "text", {labelClass, formGroupClass}),
            LastName: new Field("LastName", '', ['empty'], false, "text", {labelClass, formGroupClass}),

            Email: new Field("Email", '', ['empty'], true, "text", {labelClass, formGroupClass}),
            Phone: new Field("Phone", '', ['empty'], false, "text", {labelClass, formGroupClass}),
            GenderID: new Field("GenderID", '', [''], false, "select", {
                labelClass,
                formGroupClass: "col-span-6 sm:col-span-2"
            }),
            Birthdate: new Field("Birthdate", '', [''], false, "date", {
                labelClass,
                formGroupClass: "col-span-6 sm:col-span-2"
            }),
            Address1: new Field("Address1", '', ['empty'], false, "text", {labelClass, formGroupClass: "col-span-6"}),
            Address2: new Field("Address2", '', [''], false, "text", {labelClass, formGroupClass: "col-span-6"}),
            City: new Field("City", '', ['empty'], false, "text", {
                labelClass,
                formGroupClass: "col-span-6 sm:col-span-2"
            }),
            StateID: new Field("StateID", '', ['empty'], false, "select", {
                labelClass,
                formGroupClass: "col-span-6 sm:col-span-2"
            }),
            Zip: new Field("Zip", '', ['empty'], false, "text", {
                labelClass,
                formGroupClass: "col-span-6 sm:col-span-2"
            }),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (includeFields) {
            for (const [key] of Object.entries(fieldTemplates)) {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key];
                }
            }
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;
        const {selectedTab} = this.state;

        const isLoading = getProp(resource, "isLoading", []);

        const metadata = "";

        return (
            <LayoutWelcome {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto min-h-[calc(100vh-8.75rem)]">
                    <PageHeader
                        title={translate("page.title.welcome")}
                    />

                    {/* Page content */}
                    <div className="max-w-7xl mx-auto mt-8">
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                <Steps
                                    steps={this.state.steps}
                                    locked={!this.state.stepsProgress}
                                    onStepChange={this.handleStepChange}
                                />

                                {!!isLoading && (
                                    <div className={"inset-center"}>
                                        <Loader/>
                                    </div>
                                )}

                                {selectedTab.resource === Resources.ExtClient && !isLoading && (
                                    <React.Fragment>
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">{selectedTab.name}</h3>
                                            {!!selectedTab.desc && (
                                                <p className="mt-1 text-sm text-gray-500">{selectedTab.desc}</p>
                                            )}
                                        </div>

                                        <div className="grid grid-cols-6 gap-6">
                                            {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}
                                        </div>
                                    </React.Fragment>
                                )}

                                {selectedTab.resource === Resources.ExtClientHistory && (
                                    <DialogResourceListTab
                                        titleClass="text-lg leading-6 font-medium text-gray-900 flex items-center"
                                        primaryButtonClass="btn btn-outline"
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        dialogResource={this.props.dialogResource}

                                        tabTitle={translate("tab.client_medical_history")}
                                        dialogCreateTitle={translate("modal.title.client_medical_history")}
                                        dialogEditTitle={translate("modal.title.client_medical_history")}

                                        sortBy={"ClientMedicalHistoryID"}
                                        primaryKey={"ClientMedicalHistoryID"}
                                        resourceName={selectedTab.resource}
                                        fields={{
                                            ConditionTypeID: new Field("ConditionTypeID", '', ['empty'], false, 'select'),
                                            CurrentCondition: new Field("CurrentCondition", '', [''], false, 'checkbox'),
                                            PastCondition: new Field("PastCondition", '', [''], false, 'checkbox'),
                                            Notes: new Field("Notes", '', [], false, 'textarea'),
                                        }}
                                        onSelectItem={this.onSelectItem}
                                        metadata=""
                                    />
                                )}

                                {selectedTab.resource === Resources.ExtClientMedications && (
                                    <DialogResourceListTab
                                        titleClass="text-lg leading-6 font-medium text-gray-900 flex items-center"
                                        primaryButtonClass="btn btn-outline"
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        dialogResource={this.props.dialogResource}

                                        tabTitle={translate("tab.client_medications")}
                                        dialogCreateTitle={translate("modal.title.add_client_medications")}
                                        dialogEditTitle={translate("modal.title.edit_client_medications")}

                                        sortBy={"ClientMedicationID"}
                                        primaryKey={"ClientMedicationID"}
                                        resourceName={selectedTab.resource}
                                        fields={{
                                            Medication: new Field("Medication", '', ['empty'], false, 'text'),
                                            Dose: new Field("Dose", '', ['empty'], false, 'text'),
                                            TimesPerDay: new Field("TimesPerDay", '', ['empty'], false, 'text'),
                                            Notes: new Field("Notes", '', [], false, 'textarea'),
                                        }}

                                        onSelectItem={this.onSelectItem}
                                        metadata=""
                                    />
                                )}

                                {selectedTab.resource === Resources.ExtClientFamilyHistory && (
                                    <DialogResourceListTab
                                        titleClass="text-lg leading-6 font-medium text-gray-900 flex items-center"
                                        primaryButtonClass="btn btn-outline"
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        dialogResource={this.props.dialogResource}

                                        tabTitle={translate("tab.client_family_history")}
                                        dialogCreateTitle={translate("modal.title.add_family_history")}
                                        dialogEditTitle={translate("modal.title.edit_family_history")}
                                        addDialogClass="max-w-3xl"

                                        sortBy={"ClientMedicalFamilyHistoryID"}
                                        primaryKey={"ClientMedicalFamilyHistoryID"}
                                        resourceName={selectedTab.resource}
                                        fields={{
                                            ConditionTypeID: new Field("ConditionTypeID", '', ['empty'], false, 'select'),
                                            Mother: new Field("Mother", '', [''], false, 'checkbox'),
                                            Father: new Field("Father", '', [''], false, 'checkbox'),
                                            Brother: new Field("Brother", '', [], false, 'checkbox'),
                                            Sister: new Field("Sister", '', [], false, 'checkbox'),
                                            Child: new Field("Child", '', [], false, 'checkbox'),
                                            MGM: new Field("MGM", '', [], false, 'checkbox'),
                                            MGF: new Field("MGF", '', [], false, 'checkbox'),
                                            PGM: new Field("PGM", '', [], false, 'checkbox'),
                                            PGF: new Field("PGF", '', [], false, 'checkbox'),
                                            Notes: new Field("Notes", '', [], false, 'textarea'),
                                        }}

                                        onSelectItem={this.onSelectItem}
                                        metadata=""
                                    />
                                )}

                                {selectedTab.resource === Resources.ExtClientSurgeries && (
                                    <DialogResourceListTab
                                        titleClass="text-lg leading-6 font-medium text-gray-900 flex items-center"
                                        primaryButtonClass="btn btn-outline"
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        dialogResource={this.props.dialogResource}

                                        tabTitle={translate("tab.client_surgeries")}
                                        dialogCreateTitle={translate("modal.title.add_client_surgery")}
                                        dialogEditTitle={translate("modal.title.edit_client_surgery")}

                                        sortBy={"ClientSurgeryID"}
                                        primaryKey={"ClientSurgeryID"}
                                        resourceName={selectedTab.resource}
                                        fields={{
                                            Surgery: new Field("Surgery", '', ['empty'], false, 'text'),
                                            Date: new Field("Date", '', ['empty'], false, 'date'),
                                            Location: new Field("Location", '', [''], false, 'text'),
                                            Notes: new Field("Notes", '', [], false, 'textarea')
                                        }}

                                        onSelectItem={this.onSelectItem}
                                        metadata=""
                                    />
                                )}

                                {selectedTab.resource === Resources.ExtClientAllergies && (
                                    <DialogResourceListTab
                                        titleClass="text-lg leading-6 font-medium text-gray-900 flex items-center"
                                        primaryButtonClass="btn btn-outline"
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        dialogResource={this.props.dialogResource}

                                        tabTitle={translate("tab.client_allergies")}
                                        dialogCreateTitle={translate("modal.title.add_client_allergy")}
                                        dialogEditTitle={translate("modal.title.edit_client_allergy")}

                                        sortBy={"ClientAllergyID"}
                                        primaryKey={"ClientAllergyID"}
                                        resourceName={selectedTab.resource}
                                        fields={{
                                            Allergy: new Field("Allergy", '', ['empty'], false, 'text'),
                                            Reaction: new Field("Reaction", '', ['empty'], false, 'text'),
                                            Notes: new Field("Notes", '', [], false, 'textarea')
                                        }}

                                        onSelectItem={this.onSelectItem}
                                        metadata=""
                                    />
                                )}
                            </div>

                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <button
                                    onClick={() => this.handleStepChange(this.state.selectedTab.step + 1)}
                                    type="submit"
                                    className="btn btn-primary focus:ring-offset-inverse"
                                >
                                    {selectedTab.resource === Resources.ExtClientAllergies ? translate("btn.finish") : translate("btn.next")}
                                </button>
                            </div>
                        </div>
                    </div>
                </main>

                <ModalConfirm
                    visible={!!this.state.confirmModalOpen}
                    title={"Registration successful"}
                    text={"Thank you for submitting your information. You will be redirected to home page after you close this dialog."}
                    onClose={this.state.confirmModalOpen}
                    onConfirm={this.state.confirmModalOpen}
                    hideCancelButton={true}
                />
            </LayoutWelcome>
        )
    }
}

export default connect(state => state)(WelcomePage);
