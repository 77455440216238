import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {checkPerm, fillFieldsFromData, getProp, UPDATE_PERM} from "../../../util/util";
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import NavResponsive from "../../../components/nav-responsive";
import DialogDefault from "../../../components/dialog-default";
import DialogResourceListTab from "../../../components/resource-dialog-list-tab";
import {Field, FieldsManager} from "../../../data/services/fields";
import ResourceFormTab from "../../../components/resource-form-tab";
import ModalConfirm from "../../../components/modal/modal-confirm";

export default class IntakeAssessmentModal extends Component {

    constructor(props) {
        super(props);

        // TODO: Update Resources, perms and add filter in tabs
        const tabs = [
            {name: 'Info', resource: Resources.ClientAssessmentsInfo, perm: true, current: true},
            {name: 'Initial Assessment', resource: Resources.ClientInitialAssessments, perm: false, current: false},
            {name: 'Pain Assessments', resource: Resources.ClientPainAssessments, perm: false, current: false},
            {
                name: 'Performance Assessment',
                resource: Resources.ClientPerformanceAssessment,
                perm: false,
                current: false
            },
            {name: 'Body Scan', resource: Resources.ClientBodyScan, perm: false, current: false},
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            submitConfirmModal: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                selectedTab: null
            }, () => {
                // this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.ClientAssessmentsInfo)
            });
        }

        if (!this.props.dialogResource.isLoading && !!prevProps.dialogResource.isLoading && (this.state.selectedTab == Resources.ClientAssessmentsInfo)) {
            let tabs = this.state.tabs;

            if (this.props.dialogResource?.data?.AssessmentTypeID === 1) {
                tabs = tabs.map(it => {
                    if ([Resources.ClientInitialAssessments, Resources.ClientPainAssessments, Resources.ClientPerformanceAssessment, Resources.ClientBodyScan].includes(it.resource)) {
                        it.perm = true
                    }
                    return it;
                });
            } else {
                tabs = tabs.map(it => {
                    if ([Resources.ClientInitialAssessments, Resources.ClientPainAssessments, Resources.ClientPerformanceAssessment, Resources.ClientBodyScan].includes(it.resource)) {
                        it.perm = false
                    }
                    return it;
                });
            }

            this.setState({tabs});
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: {
                ClientAssessmentID: this.getId()
            },
            resource: Resources.ClientAssessmentsInfo
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Data events
     ================================================================= */
    handleInputChange = (fields, name, value) => {
        if ("AssessmentTypeID" === name) {
            this.setState({
                submitConfirmModal: true
            })
        }

        return FieldsManager.updateField(fields, name, value);
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getInfoFields = () => {
        const isAssessmentFieldDisabled = this.props.dialogResource?.data?.AssessmentTypeID;

        const fieldTemplates = {
            AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], isAssessmentFieldDisabled, 'select'),
            AssessmentDate: new Field("AssessmentDate", '', [''], true, 'date'),
            ProviderName: new Field("ProviderName", '', [''], true, 'text'),
            Summary: new Field("Summary", '', [''], checkPerm(Resources.ClientAssessmentsInfo, UPDATE_PERM) ? false : true, 'textarea'),
        };

        let item = getProp(this.props, "dialogResource.data", {});

        if (item.LocationName) {
            item.Location = item.LocationName;
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getPainAssessmentsFields = () => {
        const defaultMetadata = {
            formGroupClass: "sm:col-span-3",
            labelClass: "block text-sm font-medium text-gray-700"
        };
        const fullRowMetadata = {formGroupClass: "col-span-6", labelClass: "block text-sm font-medium text-gray-700"}

        const fieldTemplates = {
            PainLevelToday: new Field("PainLevelToday", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            HowActive: new Field("HowActive", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            PainLevelDaily: new Field("PainLevelDaily", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            HowActiveBeforePain: new Field("HowActiveBeforePain", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            MoveThroughPain: new Field("MoveThroughPain", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            PainThreshold: new Field("PainThreshold", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),

            PainWorsePosition: new Field("PainWorsePosition", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', {
                formGroupClass: "sm:col-span-3",
                labelClass: "block text-sm font-medium text-gray-700",
                htmlBefore: <div className="col-span-6 mt-2 text-secondary-600">Is your pain worse when:</div>
            }),
            PainWorseTimeOfDay: new Field("PainWorseTimeOfDay", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            PainWorseActive: new Field("PainWorseActive", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),

            PainProvoke: new Field("PainProvoke", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'text', {
                formGroupClass: "col-span-6 mt-4",
                labelClass: "block text-sm font-medium text-gray-700"
            }),
            PainCannotDo: new Field("PainCannotDo", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'text', fullRowMetadata),

            StressLevel: new Field("StressLevel", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            HappinessLevel: new Field("HappinessLevel", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),

            PainTried: new Field("PainTried", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'text', fullRowMetadata),
            PainWhatWorks: new Field("PainWhatWorks", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'text', fullRowMetadata),
            PainFrequency: new Field("PainFrequency", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'text', fullRowMetadata),

            Vision: new Field("Vision", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),
            Balance: new Field("Balance", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata),

            Coordination: new Field("Coordination", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', fullRowMetadata),
            BodyChanges: new Field("BodyChanges", '', [''], checkPerm(Resources.ClientPainAssessments, UPDATE_PERM) ? false : true, 'select', defaultMetadata)
        };

        let item = getProp(this.props, "secondResource.data", {});

        return fillFieldsFromData(fieldTemplates, item);
    };

    getPerformanceAssessmentsFields = () => {
        const defaultMetadata = {
            formGroupClass: "sm:col-span-3",
            labelClass: "block text-sm font-medium text-gray-700"
        };
        const fullRowMetadata = {formGroupClass: "col-span-6", labelClass: "block text-sm font-medium text-gray-700"};

        const fieldTemplates = {
            WhatCantYouDo: new Field("WhatCantYouDo", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'textarea', fullRowMetadata),
            WhyCantYouDoIt: new Field("WhyCantYouDoIt", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'textarea', fullRowMetadata),
            WhatHappenedToCauseThis: new Field("WhatHappenedToCauseThis", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'textarea', fullRowMetadata),
            DidYouHitYourHeadOrGetWhiplash: new Field("DidYouHitYourHeadOrGetWhiplash", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'checkbox', fullRowMetadata),
            WhatWereSymptomsAfterTheIncident: new Field("WhatWereSymptomsAfterTheIncident", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'textarea', fullRowMetadata),
            HowLongDidItTakeForSymptomsToTakeEffect: new Field("HowLongDidItTakeForSymptomsToTakeEffect", '', [''], checkPerm(Resources.ClientPerformanceAssessment, UPDATE_PERM) ? false : true, 'textarea', fullRowMetadata),
        };

        let item = getProp(this.props, "secondResource.data", {});

        return fillFieldsFromData(fieldTemplates, item);
    };

    confirmClientAssessmentsSubmit = (params) => {
        if (!!this.state.submitConfirmModal) {
            this.handleToggleConfirmModal(params);
        } else {
            this.clientAssessmentsSubmit(params);
        }
    }

    clientAssessmentsSubmit = (params) => {
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            params: params,
            resource: Resources.ClientAssessmentsInfo,
            piggyResource: Resources.ClientAssessmentsInfo
        }));
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        const scaleOptions = {1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6", 7: "7", 8: "8", 9: "9"};
        const activityOptions = {1: "Low", 2: "Medium", 3: "High"};
        const yesNoOptions = {1: "Yes", 2: "No"};

        return (
            <DialogDefault
                title={translate("modal.title.assessment")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)]"
                visible={!!visible}
                disableOverflow={true}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>
                    <div className="">
                        {/* Tabs */}
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                {translate("text.select_tab")}
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                defaultValue={this.state.tabs.find((tab) => tab.current.name)}
                            >
                                {this.state.tabs.filter(it => !!it.perm).map((tab) => (
                                    <option key={tab.name}
                                            onClick={() => this.handleTabChange(tab.resource)}>{tab.name}</option>
                                ))}
                            </select>

                            <div className="border-b-2 border-primary-500 mt-6"/>
                        </div>

                        <div className="hidden sm:block">
                            <div className="border-b border-secondary-200">
                                <NavResponsive
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                />
                            </div>
                        </div>

                        {/* Content */}
                        <div className="mt-10">
                            {this.state.selectedTab === Resources.ClientAssessmentsInfo && (
                                <ResourceFormTab
                                    tabTitle={"Info"}
                                    id={this.getId()}
                                    className="mx-auto"
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.dialogResource}
                                    resourceName={this.state.selectedTab}
                                    primaryKeyName={"ClientAssessmentID"}
                                    handleInputChange={this.handleInputChange}
                                    fields={this.getInfoFields()}
                                    onSubmit={this.confirmClientAssessmentsSubmit}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientInitialAssessments && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.level_1_assessments")}
                                    dialogCreateTitle={translate("modal.title.create_initial_assessment")}
                                    dialogEditTitle={translate("modal.title.edit_initial_assessment")}

                                    sortBy={"ClientAssessmentInitialID"}
                                    primaryKey={"ClientAssessmentInitialID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        ObserveID: new Field("ObserveID", '', [''], false, 'select'),
                                        RightCheck: new Field("RightCheck", '', [''], false, 'checkbox'),
                                        LeftCheck: new Field("LeftCheck", '', [''], false, 'checkbox'),
                                        Assessment: new Field("Assessment", '', [], false, 'textarea'),
                                    }}
                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientPainAssessments && (
                                <ResourceFormTab
                                    tabTitle={translate("tab.ClientPainAssessments")}
                                    id={this.getId()}
                                    className="mx-auto"
                                    formClassName="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-6"
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.dialogResource}
                                    resourceName={this.state.selectedTab}

                                    fields={this.getPainAssessmentsFields()}
                                    metadata={{
                                        PainLevelToday: scaleOptions,
                                        PainLevelDaily: scaleOptions,
                                        HowActive: activityOptions,
                                        HowActiveBeforePain: activityOptions,
                                        MoveThroughPain: yesNoOptions,
                                        PainThreshold: yesNoOptions,
                                        PainWorsePosition: {1: "Neither", 2: "Sit", 3: "Stand"},
                                        PainWorseTimeOfDay: {1: "Neither", 2: "Morning", 3: "Night"},
                                        PainWorseActive: {1: "Neither", 2: "Active", 3: "Still"},
                                        StressLevel: scaleOptions,
                                        HappinessLevel: scaleOptions,
                                        Vision: activityOptions,
                                        Balance: activityOptions,
                                        Coordination: yesNoOptions,
                                        BodyChanges: yesNoOptions
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientPerformanceAssessment && (
                                <ResourceFormTab
                                    tabTitle={translate("tab.ClientPerformanceAssessment")}
                                    id={this.getId()}
                                    className="mx-auto"
                                    formClassName="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.dialogResource}
                                    resourceName={this.state.selectedTab}
                                    primaryKey={"ClientAssessmentPerformanceID"}

                                    fields={this.getPerformanceAssessmentsFields()}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientBodyScan && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.assessment_body_scan")}
                                    dialogCreateTitle={translate("modal.title.create_body_scan")}
                                    dialogEditTitle={translate("modal.title.edit_body_scan")}

                                    sortBy={"BodyScanAreaID"}
                                    primaryKey={"ClientAssessmentBodyScanID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        BodyScanAreaID: new Field("BodyScanAreaID", '', [''], false, 'select'),
                                        Assessment: new Field("Assessment", '', [], false, 'textarea'),
                                    }}
                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            <ModalConfirm
                                visible={this.state.confirmModalOpen}
                                title={"Confirm delete"}
                                text={'Are you sure you want to submit changes? \n`Assessment Type` field will be locked!'}
                                onClose={this.handleToggleConfirmModal}
                                onConfirm={() => {
                                    this.clientAssessmentsSubmit(this.state.selectedItem);
                                    this.handleToggleConfirmModal();
                                }}
                            />
                        </div>
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}
