import React from "react";
import { CheckIcon } from '@heroicons/react/solid';
import { classNames } from '../../util/util'

export default function Steps(props) {
    const {steps, onStepChange, locked} = props;

    let handleStepChange = !!locked ? () => null : onStepChange;

    return (
        <nav aria-label="Progress">
            <ol role="list" className="border border-gray-300 border-t md:border-t-0 rounded-md lg:border-t divide-y divide-gray-300 md:flex lg:divide-y-0 flex-wrap lg:flex-nowrap overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative lg:flex-1 lg:flex w-full md:w-1/2 md:border-t lg:border-t-0 border-gray-300">
                        {step.status === 'complete' ? (
                            <button
                                className={classNames("group flex items-center w-full", !!locked ? "cursor-default" : "")}
                                onClick={() => handleStepChange(stepIdx)}
                            >
                                <span className="p-4 pr-6 flex items-center text-sm font-medium">
                                  <span className={classNames("flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full", !locked ? "group-hover:bg-primary-800" : "")}>
                                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                  </span>
                                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                </span>
                            </button>
                        ) : step.status === 'current' ? (
                            <button
                                className={classNames("p-4 pr-6 flex items-center text-sm font-medium", !!locked ? "cursor-default" : "")} aria-current="step"
                            >
                                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full">
                                  <span className="text-primary-600">{step.id}</span>
                                </span>

                                <span className="ml-4 text-sm font-medium text-primary-600">{step.name}</span>
                            </button>
                        ) : (
                            <button
                                className={classNames("group flex items-center", !!locked ? "cursor-default" : "")}
                                onClick={() => handleStepChange(stepIdx)}
                            >
                                <span className="p-4 pr-6 flex items-center text-sm font-medium">
                                  <span className={classNames("flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full" , !locked ? "group-hover:border-gray-400" : "")}>
                                    <span className={classNames("text-gray-500", !locked ? "group-hover:text-gray-900" : "")}>{step.id}</span>
                                  </span>
                                  <span className={classNames("ml-4 text-sm font-medium text-gray-500", !locked ? "group-hover:text-gray-900" : "")}>{step.name}</span>
                                </span>
                            </button>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="hidden lg:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
