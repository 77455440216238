import React from "react";
import {CheckCircleIcon} from "@heroicons/react/solid";




export default function ProgressBar({tabs}) {


    const doneTab = (item, index) => (
        <li className="relative md:flex-1 md:flex" onClick={() => item.onClick(item.resource)}>
            <a href="#" className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span
                      className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full text-primary-600">
                      <CheckCircleIcon/>
                  </span>
                  <span className="ml-4 text-sm font-medium text-primary-600">{item.name}</span>
                </span>
            </a>

            {tabs.length > (index + 1) && (
                <div className="hidden md:block absolute top-0 right-0 h-full w-5"
                 aria-hidden="true">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"
                         preserveAspectRatio="none">
                        <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke"
                              stroke="currentcolor" stroke-linejoin="round"/>
                    </svg>
                </div>
            )}
        </li>
    )

    const inProgressTab = (item, index) => (
        <li className="relative md:flex-1 md:flex" onClick={() => item.onClick(item.resource)}>
            <a href="#" className="px-6 py-4 flex items-center text-sm font-medium"
               aria-current="step">
                <span
                    className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-500 rounded-full">
                  <span className="">{index + 1}</span>
                </span>
                <span
                    className="ml-4 text-sm font-medium text-primary-600">
                    {item.name}
                </span>
            </a>

            {tabs.length > (index + 1) && (
                <div className="hidden md:block absolute top-0 right-0 h-full w-5"
                     aria-hidden="true">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"
                         preserveAspectRatio="none">
                        <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke"
                              stroke="currentcolor" stroke-linejoin="round"/>
                    </svg>
                </div>
            )}
        </li>
    )

    const disabledTab = (item, index) => (
        <li className="relative md:flex-1 md:flex disabled">
            <a href="#" className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span
                      className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full ">
                    <span className="text-gray-500 ">{index + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 ">{item.name}</span>
                </span>
            </a>
        </li>
    )

    const list = tabs.map((item, i) => {
        if (item.isDone) return doneTab(item)

        if (item.isDisabled) return disabledTab(item, i)

        return inProgressTab(item, i)
    })

    return (
        <nav aria-label="Progress">
            <ol role="list"
                className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                {list}
            </ol>
        </nav>
    )
}
