import React, {Component} from 'react'
import Loader from "../../../components/loader";
import RatingScales from "../../survey-single-page/survey-builder/rating-scales";
import {fieldsToHtml, getProp} from "../../../util/util";
import LikertScales from "../../survey-single-page/survey-builder/likert-scales";
import QuestionMatrix from "../../survey-single-page/survey-builder/question-matrix";
import QuestionDropdown from "../../survey-single-page/survey-builder/question-dropdown";
import {getDialogResource} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import FieldTextarea from "../../../components/field-textarea";
import FieldText from "../../../components/field-text";
import {InformationCircleIcon} from "@heroicons/react/outline";
import QuestionRanking from "../../survey-single-page/survey-builder/question-ranking";
import {Field, FieldsManager} from "../../../data/services/fields";

export default class Survey extends Component {

    constructor(props) {
        super(props);

        this.state = {
            surveyFields: {},
            questionItems: {},
            fields: {
                Notes: new Field("Notes", '', [''], this.props.disableSave, "textarea"),
            }
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading && !!this.props.resource?.data?.survey) {
            let questionItems = getProp(this.props, "resource.data.survey", {}).reduce((memo, it, i) => {
                const orderNumber = it.SurveyQuestionID;

                if (it.Items.length) {
                    memo[orderNumber] = it.Items

                    if (it.Answer && it.SurveyQuestionTypeID == 5) {
                        const items = it.Items.reduce((memo, it) => {
                            memo[it.SurveyQuestionItemID] = it;
                            return memo
                        }, {});

                        const userAnswers = it.Answer.split(",");

                        // Ignore if questions are added or removed
                        if (userAnswers.length === it.Items.length) {
                            memo[orderNumber] = userAnswers.map(it => {
                                let itemID = it.split('-')[0]
                                return items[itemID]
                            });
                        }
                    }
                }

                return memo
            }, {});

            this.setState({
                fields: {
                    Notes: new Field("Notes", getProp(this.props, "resource.data.question.Notes", []), [''], false, "textarea"),
                },
                surveyFields: getProp(this.props, "resource.data.survey", []).reduce((memo, it) => {

                    memo[it.SurveyQuestionID] = it.Answer;

                    // Ranking
                    if (it.SurveyQuestionTypeID == 5) {
                        const items = it.Items.reduce((memo, it) => {
                            memo[it.SurveyQuestionItemID] = it;
                            return memo
                        }, {});

                        memo[it.SurveyQuestionID] = it.Answer.split(",").map(it => {
                            let itemID = it.split('-')[0]
                            return items[itemID]
                        });
                    }

                    // Matrix
                    if (it.SurveyQuestionTypeID == 3) {
                        memo[it.SurveyQuestionID] = it.Answer.split(",").reduce((memo, it, i) => {
                            let index = i + 1;
                            let value = it.split('-')[1]
                            switch (value) {
                                case "1":
                                    memo[index] = "very_satisfied"
                                    break;
                                case "2":
                                    memo[index] = "satisfied"
                                    break;
                                case "3":
                                    memo[index] = "neither"
                                    break;
                                case "4":
                                    memo[index] = "dissatisfied";
                                    break;
                                case "5":
                                    memo[index] = "very_dissatisfied";
                                    break;
                                default:
                                    return memo[i] = "";
                            }
                            return memo
                        }, {})
                    }

                    return memo;
                }, {}),
                questionItems: questionItems
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.id
            },
            resource: this.props.resourceName
        }))
    };

    submit = () => {
        const surveyFields = this.state.surveyFields;

        this.setState({
            selectedForInterview: null,
            questionsFields: {},
            surveyFields: {}
        }, () => {
            const allQuestionTypes = Object.values(getProp(this.props, "resource.data.survey", {})).reduce((memo, it) => {
                memo[it.SurveyQuestionID] = it.SurveyQuestionTypeID;
                return memo
            }, {});

            const params = {
                Notes: this.state.fields.Notes?.value ?? "",
                Answers: Object.keys(surveyFields).filter(key => !(allQuestionTypes[key] == 4 && !surveyFields[key])).map((key) => {
                    let answer = surveyFields[key];

                    if (allQuestionTypes[key] == 3) {
                        answer = this.formatMatrixQuestionsForSubmit(surveyFields[key], key);
                    }

                    // Ranking questions
                    if (allQuestionTypes[key] == 5 && !!answer.length) {
                        // Get default (randomized) order if user didn't made any ranking changes
                        if (answer[0] == undefined) {
                            this.state.questionItems[key].map(it => it.SurveyQuestionItemID).join(",");
                        } else {
                            answer = answer.map(it => it.SurveyQuestionItemID).join(",")
                        }
                    }

                    return {
                        SurveyQuestionID: key,
                        Answer: answer
                    }
                }),
            }

            this.props.onSubmit(params);
        })
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleSurveyAnswerChange = (survey, answer) => {
        let surveyFields = this.state.surveyFields;
        surveyFields[survey] = answer;
        this.setState({
            surveyFields: surveyFields
        })
    }

    handleDragStart = (i) => {
        this.setState({itemIndexDragged: i})
    }

    handleDragEnter = (i, questionNum, SurveyQuestionID) => {
        let questionItems = this.state.questionItems;
        let rowOrder = JSON.parse(JSON.stringify(questionItems[questionNum]));

        [rowOrder[this.state.itemIndexDragged], rowOrder[i]] = [rowOrder[i], rowOrder[this.state.itemIndexDragged]];

        questionItems[questionNum] = rowOrder;

        this.setState({
            questionItems: questionItems,
            itemIndexDragged: i,
        }, () => {
            this.handleSurveyAnswerChange(SurveyQuestionID, rowOrder)
        })
    }

    /** Helpers
     ================================================================= */
    formatMatrixQuestionsForSubmit = (itemValue, questionID) => {
        let formatedValue = [];
        for (const [key, value] of Object.entries(itemValue)) {
            const SurveyQuestionItemID = this.state.questionItems[questionID][key - 1].SurveyQuestionItemID
            switch (value) {
                case "very_satisfied":
                    formatedValue.push(SurveyQuestionItemID + "-1");
                    break;
                case "satisfied":
                    formatedValue.push(SurveyQuestionItemID + "-2");
                    break;
                case "neither":
                    formatedValue.push(SurveyQuestionItemID + "-3");
                    break;
                case "dissatisfied":
                    formatedValue.push(SurveyQuestionItemID + "-4");
                    break;
                case "very_dissatisfied":
                    formatedValue.push(SurveyQuestionItemID + "-5");
                    break;
                default:
                    formatedValue.push(SurveyQuestionItemID + "-0")
            }
        }
        return formatedValue.join(",");
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        // Move constants to util
        const questionMatrixID = 3;
        const ratingScalesID = 1;
        const likertScalesID = 2;
        const dropDownID = 4;
        const rankingQuestionsID = 5;
        const textQuestionID = 6;
        const numberQuestionID = 7;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const survey = getProp(this.props, "resource.data.survey", []);

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {});

        const surveyFields = survey.map((it, i) => {
            let dropdownValues = {};
            if (it.SurveyQuestionTypeID == dropDownID) {
                dropdownValues = it.Items.reduce((memo, it, i) => {
                    memo[it.SurveyQuestionItemID] = it.SurveyQuestionItemName;
                    return memo
                }, {});
            }

            let field = "";
            let disc = "";
            switch (it.SurveyQuestionTypeID) {
                case 1:
                case ratingScalesID:
                    field = (
                        <RatingScales
                            onClick={(name, value) => {
                                this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                            }}
                            value={this.state.surveyFields[it.SurveyQuestionID] ?? ""}
                            topRating={10}
                            disabledEdit={this.props.disableSave}
                        />
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Enter text as an answer.</p>);
                    break;
                case 2:
                case likertScalesID:
                    field = (
                        <LikertScales
                            name={"question-" + it.SurveyQuestionID}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                            }}
                            value={this.state.surveyFields[it.SurveyQuestionID]}
                            disabledEdit={this.props.disableSave}
                        />
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Enter number as an answer.</p>);
                    break;
                case 3:
                case questionMatrixID: {
                    this.state.questionItems[it.SurveyQuestionID] && (
                        field = (
                            <QuestionMatrix
                                index={i}
                                name={"question-" + it.SurveyQuestionID}
                                matrixQuestions={this.state.questionItems[it.SurveyQuestionID]}
                                translate={translate}
                                onChange={(name, value) => {
                                    this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                                }}
                                value={this.state.surveyFields[it.SurveyQuestionID]}
                                disabledEdit={this.props.disableSave}
                            />
                        )
                    )
                }
                    break;
                case 4:
                case dropDownID:
                    field = (
                        <div className={"w-3"}>
                            <QuestionDropdown
                                value={this.state.surveyFields[it.SurveyQuestionID]}
                                onChange={(name, value) => {
                                    this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                                }}
                                name={"question-" + it.SurveyQuestionID}
                                values={dropdownValues}
                                disabledEdit={this.props.disableSave}
                            />
                        </div>
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Mark checkbox for yes answer.</p>);
                    break;
                case 5:
                case rankingQuestionsID: {
                    !!this.state.questionItems[it.SurveyQuestionID]?.length && (
                        field = (
                            <QuestionRanking
                                translate={translate}
                                questionID={it.SurveyQuestionID}
                                rankingOptions={this.state.questionItems[it.SurveyQuestionID]}
                                itemIndexDragged={this.state.itemIndexDragged}
                                onDragStart={this.handleDragStart}
                                onDragEnter={(i, questionNum) => this.handleDragEnter(i, questionNum, it.SurveyQuestionID)}
                                onDragEnd={this.handleDragStart}
                                onDragLeave={this.handleDragLeave}
                                onDrop={this.handleDragLeave}
                                disabledEdit={this.props.disableSave}
                            />
                        )
                    )
                }
                    break;
                case 6:
                case textQuestionID:
                    field = (
                        <FieldTextarea
                            className="form-control w-full"
                            name={"question-" + it.SurveyQuestionID}
                            value={this.state.surveyFields[it.SurveyQuestionID]}
                            placeholder={this.props.translate("placeholder.enter_answer")}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                            }}
                            disabled={this.props.disableSave}
                        />
                    )
                    break;
                case 7:
                case numberQuestionID:
                    field = (
                        <FieldText
                            className="form-control w-48"
                            name={"question-" + it.SurveyQuestionID}
                            value={this.state.surveyFields[it.SurveyQuestionID]}
                            placeholder={this.props.translate("placeholder.enter_number")}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.SurveyQuestionID, value)
                            }}
                            disabled={this.props.disableSave}
                        />
                    )
                    break;
                default:
                    field = <div/>
            }
            return (
                <>
                    <div key={it.SurveyQuestion} className="flex flex-col justify-center p-4 border-t ">
                        <div className={"flex justify-between items-center px-4 pb-4 mb-4 border-b border-dashed"}>
                            <div>
                                <p className="m-0">
                                    <span>{i + 1}. </span>
                                    <span className="text-lg text-secondary-800">{it.SurveyQuestion}</span>
                                </p>
                            </div>

                            <div>

                            </div>
                        </div>

                        {field}

                        {!!it.Instructions && (
                            <div className="rounded-md bg-secondary-50 p-4 mt-6">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon
                                            className="h-5 w-5 text-secondary-400"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-secondary-700">{it.Instructions}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )
        });

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                {!isLoading && (
                    <div className="pb-6">
                        <div className="flex flex-col mt-2">
                            <p className="mb-4">Please submit your assessment answers</p>

                            <section className="mb-8">
                                <div className="bg-white shadow sm:rounded-md">
                                    <div
                                        className="px-4 py-1 sm:px-6 bg-secondary-100 flex justify-between items-center">
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                                            <span className="text-secondary-600">Assessment questions</span>
                                        </h2>
                                    </div>

                                    {surveyFields}
                                </div>

                                {fieldsHtml}
                            </section>
                        </div>

                        {!this.props.disableCancel && (
                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse"
                                    onClick={this.props.onClose}
                            >
                                Cancel
                            </button>
                        )}

                        {!this.props.disableSave && (
                            <button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                onClick={this.submit}
                            >
                                Save
                            </button>
                        )}
                    </div>
                )}
            </div>
        )
    }
}