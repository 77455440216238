import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, DEFAULT_CRUD_STATE, fillFieldsFromData, getProp, READ_PERM, stateToCrud} from "../../util/util";
import {getSecondResource} from "../../data/actions/secondResource";
import {Field} from "../../data/services/fields";
import PageHeader from "../../components/layout-dashboard/page-header";
import ResourceListTab from "../../components/resource-list-tab";
import Loader from "../../components/loader";
import {Tabs} from "../../components/tabs";
import ResourceFormTab from "../../components/resource-form-tab";
import {updateDialogResource} from "../../data/actions/dialogResource";

class AilmentsSinglePage extends Component {

    constructor(props) {
        super(props);

        let tabs = [
            {name: "Info", resource: Resources.Ailment, current: false},
            {name: "Indications", resource: Resources.AilmentIndications, current: false},
            {name: "Treatment", resource: Resources.AilmentTreatment, current: false},
            {name: "Training", resource: Resources.AilmentTraining, current: false},
            {name: "Supplements", resource: Resources.AilmentSupplements, current: false},
            {name: "Homework", resource: Resources.AilmentHomework, current: false},
            {name: "Diet", resource: Resources.AilmentDiet, current: false},
        ].filter((it) => checkPerm(it.resource, READ_PERM));
        tabs[0].current = true;

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "CreateUpdateDate",
            sort: "DESC",

            tabs: tabs,
            selectedTab: tabs[0].resource,
            assessmentModalOpen: false,
            editModalOpen: false,
        };

        this.indicationTabRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.secondResource.data === null) && (!!this.props.secondResource.data)) {
            LocalStorage.getAndSave('ailmentsHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.match.params.id,
                    name: this.props.secondResource.data.Ailment
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

        if (this.getId() !== prevProps.match.params.id) {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Ailment
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getQuery = () => {
        return {
            id: this.getId(),
            ...(stateToCrud(this.state))
        }
    };

    getPrimaryKey = () => {
        return "AilmentID"
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            Ailment: new Field("Ailment", '', [''], false, "text"),
            PartOfBodyID: new Field("PartOfBodyID", '', [''], false, "select"),
            Summary: new Field("Summary", '', [''], false, "textarea"),
            Details: new Field("Details", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getIndicationFields = (item = null) => {
        const fieldTemplates = {
            Indication: new Field("Indication", '', ['empty'], false, "text"),

            SubPartOfBodyID: new Field("SubPartOfBodyID", '', ['empty'], false, 'select', {}, { }),
            SubPlacePartOfBodyID: new Field("SubPlacePartOfBodyID", '', [], false, 'select-search', {}, {

            }),
            SubPartOfBodyWhenID: new Field("SubPartOfBodyWhenID", '', [], false, 'select-search', {}, {

            }),
            SubPartOfBodyVisualID: new Field("SubPartOfBodyVisualID", '', [], false, 'select-search', {}, {

            }),
            SubPartOfBodyPalpateID: new Field("SubPartOfBodyPalpateID", '', [], false, 'select-search', {}, {

            }),

            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getTreatmentFields = (item = null) => {
        const fieldTemplates = {
            TreatmentTypeID: new Field("TreatmentTypeID", '', ['empty'], false, "select"),
            Grade: new Field("Grade", '', [''], false, "text"),
            Frequency: new Field("Frequency", '', [''], false, "textarea"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getTrainingFields = (item = null) => {
        const fieldTemplates = {
            TrainingTypeID: new Field("TrainingTypeID", '', ['empty'], false, "select"),
            Grade: new Field("Grade", '', [''], false, "text"),
            Frequency: new Field("Frequency", '', [''], false, "textarea"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSuplementFields = (item = null) => {
        const fieldTemplates = {
            SupplementTypeID: new Field("SupplementTypeID", '', ['empty'], false, "select"),
            Frequency: new Field("Frequency", '', [''], false, "textarea"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getHomeworkFields = (item = null) => {
        const fieldTemplates = {
            HomeworkTypeID: new Field("HomeworkTypeID", '', ['empty'], false, "select"),
            Frequency: new Field("Frequency", '', [''], false, "textarea"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getDietFields = (item = null) => {
        const fieldTemplates = {
            DietTypeID: new Field("DietTypeID", '', ['empty'], false, "select"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const Ailment = getProp(this.props, "secondResource.data", {});

        const isLoading = getProp(this.props, "secondResource.isLoading", null)

        const PartOfBody = LocalStorage.get('lookup').PartOfBody.find(it => it.PartOfBodyID == Ailment.PartOfBodyID);

        const SubPartOfBodyID = getProp(PartOfBody, "PartOfBodySubItems", []).reduce((memo, it) => {
            memo[it.PartOfBodySubItemID] = it.PartOfBodySubItem
            return memo
        }, {});

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.ailment")}
                >
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between"
                    >
                        {(!!isLoading) && (
                            <Loader/>
                        )}

                        {(!isLoading) && (
                            <React.Fragment>
                                <div className="flex-1 min-w-0">
                                    <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                        {Ailment.Ailment}
                                    </h1>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </header>

                <main className="pb-16 relative custom-min-h-tab bg-inverse">
                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("tab." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <Tabs
                                tabs={this.state.tabs}
                                onTabChange={this.handleTabChange}
                            />
                        </div>

                        {/* Content */}
                        <div className="mt-6">
                            {this.state.selectedTab === Resources.Ailment && (
                                <ResourceFormTab
                                    tabTitle={"Info"}
                                    id={this.getId()}
                                    className="mx-auto"
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.dialogResource}
                                    resourceName={this.state.selectedTab}
                                    primaryKeyName={this.getPrimaryKey()}
                                    fields={this.getFields()}
                                    metadata=""
                                    onSubmit={(params) => {
                                        this.props.dispatch(updateDialogResource({
                                            user: LocalStorage.get("user"),
                                            query: {id: this.getId()},
                                            params: params,
                                            resource: Resources.Ailment,
                                            piggyResource: Resources.Ailment
                                        }));
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.AilmentIndications && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"Indication"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentIndicationID"}
                                    primaryKey={"AilmentIndicationID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getIndicationFields()}
                                    ref={this.indicationTabRef}
                                    metadata={{
                                        SubPartOfBodyID: SubPartOfBodyID,
                                        SubPlacePartOfBodyID: {
                                            visibleFilter: (fields) => (!fields[1]?.value),
                                            api: 'api/' + Resources.LookupSubPart,
                                            query: (fields) => {
                                                return {
                                                    id: fields[1]?.value,
                                                    Type: "SubPlacePartOfBody"
                                                }
                                            },
                                            searchMap: (item) => ({
                                                value: item.SubPlacePartOfBodyID,
                                                label: item.SubPlacePartOfBody
                                            })
                                        },
                                        SubPartOfBodyWhenID: {
                                            visibleFilter: (fields) => (!fields[1]?.value),
                                            api: 'api/' + Resources.LookupSubPart,
                                            query: (fields) => {
                                                return {
                                                    id: fields[1]?.value,
                                                    Type: "SubPartOfBodyWhen"
                                                }
                                            },
                                            searchMap: (item) => ({
                                                value: item.SubPartOfBodyWhenID,
                                                label: item.SubPartOfBodyWhen
                                            })
                                        },
                                        SubPartOfBodyVisualID: {
                                            visibleFilter: (fields) => (!fields[1]?.value),
                                            api: 'api/' + Resources.LookupSubPart,
                                            query: (fields) => {
                                                return {
                                                    id: fields[1]?.value,
                                                    Type: "SubPartOfBodyVisual"
                                                }
                                            },
                                            searchMap: (item) => ({
                                                value: item.SubPartOfBodyVisualID,
                                                label: item.SubPartOfBodyVisual
                                            })
                                        },
                                        SubPartOfBodyPalpateID: {
                                            visibleFilter: (fields) => (!fields[2]?.value?.value),
                                            api: 'api/' + Resources.LookupSubPart,
                                            query: (fields) => {
                                                return {
                                                    id: fields[2]?.value?.value,
                                                    Type: "SubPartOfBodyPalpate"
                                                }
                                            },
                                            searchMap: (item) => ({
                                                value: item.SubPartOfBodyPalpateID,
                                                label: item.SubPartOfBodyPalpate
                                            })
                                        }
                                    }}
                                />
                            )}
                            {this.state.selectedTab === Resources.AilmentTreatment && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"treatment"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentTreatmentID"}
                                    primaryKey={"AilmentTreatmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getTreatmentFields()}
                                    ref={this.indicationTabRef}
                                />
                            )}
                            {this.state.selectedTab === Resources.AilmentTraining && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"training"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentTrainingID"}
                                    primaryKey={"AilmentTrainingID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getTrainingFields()}
                                    ref={this.indicationTabRef}
                                />
                            )}
                            {this.state.selectedTab === Resources.AilmentSupplements && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"supplements"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentSupplementsID"}
                                    primaryKey={"AilmentSupplementsID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getSuplementFields()}
                                    ref={this.indicationTabRef}
                                />
                            )}
                            {this.state.selectedTab === Resources.AilmentHomework && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"homework"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentHomeworkID"}
                                    primaryKey={"AilmentHomeworkID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getHomeworkFields()}
                                    ref={this.indicationTabRef}
                                />
                            )}
                            {this.state.selectedTab === Resources.AilmentDiet && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"diet"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AilmentDietID"}
                                    primaryKey={"AilmentDietID"}
                                    resourceName={this.state.selectedTab}
                                    fields={this.getDietFields()}
                                    ref={this.indicationTabRef}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(AilmentsSinglePage);
