import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../../util/util";
import {getDialogResourceDetails} from "../../../data/actions/dialogResource";
import NavResponsive from "../../../components/nav-responsive";
import DialogDefault from "../../../components/dialog-default";
import DialogResourceListTab from "../../../components/resource-dialog-list-tab";
import {Field, FieldsManager} from "../../../data/services/fields";
import ResourceFormTab from "../../../components/resource-form-tab";

export default class ClientModal extends Component {

    constructor(props) {
        super(props);

        // TODO: Update perms and add filter in tabs
        const tabs = [
            {name: 'Info', resource: Resources.Client, perm: true, current: true},
            {name: 'History', resource: Resources.ClientHistory, perm: true, current: false},
            {name: 'Medications', resource: Resources.ClientMedications, perm: true, current: false},
            {name: 'Family history', resource: Resources.ClientFamilyHistory, perm: true, current: false},
            {name: 'Surgeries', resource: Resources.ClientSurgeries, perm: true, current: false},
            {name: 'Allergies', resource: Resources.ClientAllergies, perm: true, current: false},
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            LocationID: getProp(this.props, "secondResource.data.LocationID", 0),
            remountFields: 0
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                selectedTab: null,
            }, () => {
                this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.Client)
            });
        }

        if (!this.props.secondResource.isLoading && !!prevProps.secondResource.isLoading ) {
            this.setState({
                remountFields: this.state.remountFields + 1,
                LocationID: getProp(this.props, "secondResource.data.LocationID", 0)
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.Client
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {

        console.log(resource);
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getInfoFields = () => {
        const fieldTemplates = {
            LocationID: new Field("LocationID", '', ['empty'], false, "select-search"),
            PrimaryPhysicianID: new Field("PrimaryPhysicianID", '', ['empty'], false, "select-search"),
            MembershipLevelID: new Field("MembershipLevelID", '', [''], false, "select"),

            FirstName: new Field("FirstName", '', ['empty'], false, "text"),
            LastName: new Field("LastName", '', ['empty'], false, "text"),
            Email: new Field("Email", '', ['empty'], false, "text"),

            Phone: new Field("Phone", '', [''], false, "text"),
            Birthdate: new Field("Birthdate", '', [''], false, "date"),
            GenderID: new Field("GenderID", '', [''], false, "select"),
            Address1: new Field("Address1", '', [''], false, "text"),
            Address2: new Field("Address2", '', [''], false, "text"),
            City: new Field("City", '', [''], false, "text"),
            StateID: new Field("StateID", '', [''], false, "select"),
            Zip: new Field("Zip", '', [''], false, "text"),
            Notes: new Field("Notes", '', [''], false, "textarea"),
        };

        let item = getProp(this.props, "secondResource.data", {});

        if (item.LocationName) {
            item.Location = item.LocationName;
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    handleInputChange = (fields, name, value) => {
        if ("LocationID" === name) {
            const LocationID = this.state.LocationID ;
            fields = FieldsManager.updateField(fields, "PrimaryPhysicianID", null);
            if (value != LocationID) {
                this.setState({
                    LocationID: value?.value,
                    remountFields: this.state.remountFields + 1
                })
            }
        }

        return FieldsManager.updateField(fields, name, value);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose, dialogResource} = this.props;

        return (
            <DialogDefault
                title={translate("modal.title.edit_client")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)]"
                visible={!!visible}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>
                    <div className="">
                        {/* Tabs */}
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                {translate("text.select_tab")}
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                onChange={e => this.handleTabChange(e.target.value)}
                            >
                                {this.state.tabs.map((tab) => (
                                    <option
                                        key={tab.name}
                                        value={tab.resource}
                                    >
                                        {tab.name}
                                    </option>
                                ))}
                            </select>

                            <div className="border-b-2 border-primary-500 mt-6"></div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="border-b border-secondary-200">
                                <NavResponsive
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                />
                            </div>
                        </div>

                        {/* Content */}
                        <div className="mt-10">
                            {this.state.selectedTab === Resources.Client && (
                                <ResourceFormTab
                                    bodyKey={this.state.remountFields}
                                    tabTitle={"Info"}
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.dialogResource}
                                    resourceName={this.state.selectedTab}
                                    handleInputChange={this.handleInputChange}

                                    fields={this.getInfoFields()}
                                    metadata={{
                                        LocationID: {
                                            api: 'api/' + Resources.Locations,
                                            query: {},
                                            searchMap: (item) => ({
                                                value: item.LocationID,
                                                label: item.LocationName
                                            })
                                        },
                                        PrimaryPhysicianID: {
                                            api: 'api/' + Resources.Physicians,
                                            query: {LocationID: this.state.LocationID},
                                            searchMap: (item) => ({
                                                value: item.PhysicianID,
                                                label: item.FirstName + " " + item.LastName
                                            })
                                        },
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientHistory && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.client_medical_history")}
                                    dialogCreateTitle={translate("modal.title.client_medical_history")}
                                    dialogEditTitle={translate("modal.title.client_medical_history")}

                                    sortBy={"ClientMedicalHistoryID"}
                                    primaryKey={"ClientMedicalHistoryID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        ConditionTypeID: new Field("ConditionTypeID", '', ['empty'], false, 'select'),
                                        CurrentCondition: new Field("CurrentCondition", '', [''], false, 'checkbox'),
                                        PastCondition: new Field("PastCondition", '', [''], false, 'checkbox'),
                                        Notes: new Field("Notes", '', [], false, 'textarea'),
                                    }}
                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientMedications && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.client_medications")}
                                    dialogCreateTitle={translate("modal.title.add_client_medications")}
                                    dialogEditTitle={translate("modal.title.edit_client_medications")}

                                    sortBy={"ClientMedicationID"}
                                    primaryKey={"ClientMedicationID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        Medication: new Field("Medication", '', ['empty'], false, 'text'),
                                        Dose: new Field("Dose", '', ['empty'], false, 'text'),
                                        TimesPerDay: new Field("TimesPerDay", '', ['empty'], false, 'text'),
                                        Notes: new Field("Notes", '', [], false, 'textarea'),
                                    }}

                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientFamilyHistory && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.client_family_history")}
                                    dialogCreateTitle={translate("modal.title.add_family_history")}
                                    dialogEditTitle={translate("modal.title.edit_family_history")}
                                    addDialogClass="max-w-3xl"

                                    sortBy={"ClientMedicalFamilyHistoryID"}
                                    primaryKey={"ClientMedicalFamilyHistoryID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        ConditionTypeID: new Field("ConditionTypeID", '', ['empty'], false, 'select'),
                                        Mother: new Field("Mother", '', [''], false, 'checkbox'),
                                        Father: new Field("Father", '', [''], false, 'checkbox'),
                                        Brother: new Field("Brother", '', [], false, 'checkbox'),
                                        Sister: new Field("Sister", '', [], false, 'checkbox'),
                                        Child: new Field("Child", '', [], false, 'checkbox'),
                                        MGM: new Field("MGM", '', [], false, 'checkbox'),
                                        MGF: new Field("MGF", '', [], false, 'checkbox'),
                                        PGM: new Field("PGM", '', [], false, 'checkbox'),
                                        PGF: new Field("PGF", '', [], false, 'checkbox'),
                                        Notes: new Field("Notes", '', [], false, 'textarea'),
                                    }}

                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientSurgeries && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.client_surgeries")}
                                    dialogCreateTitle={translate("modal.title.add_client_surgery")}
                                    dialogEditTitle={translate("modal.title.edit_client_surgery")}

                                    sortBy={"ClientSurgeryID"}
                                    primaryKey={"ClientSurgeryID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        Surgery: new Field("Surgery", '', ['empty'], false, 'text'),
                                        Date: new Field("Date", '', ['empty'], false, 'date'),
                                        Location: new Field("Location", '', [''], false, 'text'),
                                        Notes: new Field("Notes", '', [], false, 'textarea')
                                    }}

                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientAllergies && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    tabTitle={translate("tab.client_allergies")}
                                    dialogCreateTitle={translate("modal.title.add_client_allergy")}
                                    dialogEditTitle={translate("modal.title.edit_client_allergy")}

                                    sortBy={"ClientAllergyID"}
                                    primaryKey={"ClientAllergyID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        Allergy: new Field("Allergy", '', ['empty'], false, 'text'),
                                        Reaction: new Field("Reaction", '', ['empty'], false, 'text'),
                                        Notes: new Field("Notes", '', [], false, 'textarea')
                                    }}

                                    onSelectItem={this.onSelectItem}
                                    metadata=""
                                />
                            )}
                        </div>
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}