import React, {Component} from 'react'
import {deleteResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getProp, scrollErrorIntoView} from "../../util/util";
import ModalConfirm from "../../components/modal/modal-confirm";
import {FieldsManager} from "../../data/services/fields";
import Loader from "../loader";
import {getDialogResource, updateDialogResource} from "../../data/actions/dialogResource";

export default class ResourceFormTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: Object.assign({}, this.props.fields),
            originalFields: Object.assign({}, this.props.fields),
            confirmModalOpen: false,
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleSubmitClick = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            const params = Object.assign({}, FieldsManager.getFieldKeyValues(validatedFields), {
                    id: this.getId()
                });

            if (!!this.props.onSubmit) {
                this.props.onSubmit(params)
            }  else {
                this.props.dispatch(updateDialogResource({
                    user: LocalStorage.get("user"),
                    query: {id: this.getId()},
                    params: params,
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName()
                }));
            }
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields, canSubmit: true});
    };

    handleCancelClick = () => {
        this.setState({
            fields: this.getFields(getProp(this.props, "resource.data", {})),
            canSubmit: false
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = this.props.fields ?? {};

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, metadata, tabTitle, className, formClassName} = this.props;
        const {canSubmit} = this.state;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const addFormClassName = !!formClassName ? formClassName : "space-y-8 divide-y divide-gray-200 grid max-h-[calc(100vh-20rem)] overflow-y-auto mx-auto -mx-4 sm:-mx-6 px-4 sm:px-6";

        return (
            <div className={className}>

                {!isLoading && (
                    <div className="">
                        <div className="flex flex-wrap sticky top-0 bg-white z-10 border-b border-primary-500 pt-1">
                            {tabTitle && (
                                <h2 className="text-xl font-bold text-secondary-600">{tabTitle}</h2>
                            )}

                            <div className="flex-shrink-0 ml-auto mb-3">
                                {!this.props.disableCancel && (
                                    <button
                                        type="button"
                                        onClick={canSubmit ? this.handleCancelClick : null}
                                        className={"btn focus:ring-offset-inverse mr-3" + (canSubmit ? " btn-outline" : " btn-disabled")}
                                    >
                                        {translate("btn.cancel")}
                                    </button>
                                )}

                                {!this.props.disableSave && (
                                    <button
                                        type="button"
                                        onClick={canSubmit ? this.handleSubmitClick : null}
                                        className={"btn focus:ring-offset-inverse" + (canSubmit ? " btn-primary" : " btn-disabled")}
                                    >
                                        {translate("btn.save")}
                                    </button>
                                )}
                            </div>
                        </div>

                        <form key={this.props.bodyKey} className={addFormClassName}>
                            {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}
                        </form>
                    </div>
                )}

                {!!isLoading && (
                    <div className="text-center">
                        <Loader/>
                    </div>
                )}

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
