import React, {Component, Fragment} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {MenuAlt2Icon, XIcon} from '@heroicons/react/outline'
import {Link} from "react-router-dom";
import {checkPasswordStrength, classNames, getHomePage, getProp, getUserLabel, setDocumentTitle} from "../../util/util";
import Logout from "../logout";
import LocalStorage from "../../util/localStorage";
import {logoutClear, resetUserMessage, updatePassword} from "../../data/actions/user";
import Notification from "../notification";
import {hideNotification} from "../../data/actions/ui";
import FieldPassword from "../field-password";
import {Field, FieldsManager} from "../../data/services/fields";
import moment from "moment";
import Loader from "../loader";
import {getNotificationList} from "../../data/actions/notifications";
import ChevronDownIcon from "@heroicons/react/outline/ChevronDownIcon";

export default class LayoutDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            isSidebarOpen: false,
            ThemeDark: false,
            sidebarIsCollapsed: false,
            sidebarAnimation: false,
            page: "",
            changePasswordModalOpen: false,

            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            no_match: false,
            passwordStrength: {
                passed: 0,
                strength: "",
                color: "white"
            },

            selectedMenuDropdownItem: null
        }
    }

    componentDidMount() {
        // TEST!!!
        this.props.dispatch(getNotificationList({
            user: LocalStorage.get("user")
        }));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== this.props.location.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }
    }

    setSidebarOpen = (open) => {
        this.setState({
            isSidebarOpen: open
        })
    }

    changePasswordSubmit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (this.state.passwordStrength.strength < 4) {
                return;
            }
            if (this.state.fields.password.value !== this.state.fields.password_confirm.value) {
                this.setState({
                    no_match: true
                });
            } else if (!this.state.fields.password.errorMessage && !this.state.fields.password_confirm.errorMessage) {
                this.props.dispatch(updatePassword({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields)
                }))
            }
        })
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});

        if (name === 'password') {
            this.setState({
                passwordStrength: checkPasswordStrength(value)
            })
        }
    };

    handleChangePassword = () => {
        this.setState({
            changePasswordModalOpen: !this.state.changePasswordModalOpen
        })
    }

    handleOpenMenuDropdown = (name = null) => {
        this.setState({
            selectedMenuDropdownItem: name
        })
    }

    render() {
        const {translate} = this.props;

        const isClient = !!this.props.user.data["Internal"] && this.props.user.data["Internal"]["UserType"] === 'Client';

        const homePagePath = getHomePage(this.props.user.data);

        let currentPage = this.props.match.path.substring(1)
        currentPage = currentPage.substring(0, currentPage.indexOf('/')) || currentPage;

        if (currentPage == "locations" || currentPage == "physicians" || currentPage == "assessments" || currentPage == "settings-ailments") {
            currentPage = "admin";
        }

        let userNavigation = [
            {name: 'Preferences', href: '/preferences', perm: true},
            // {name: 'Users', href: '/users'},
            {name: 'Logged devices', href: '/devices', perm: !isClient},
            {
                name: 'Change Password', perm: true, action: () => {
                    this.setState({
                        changePasswordModalOpen: true
                    })
                }
            }
        ];

        let mainNavigation = [
            {
                name: 'Profile', href: '/client-profile', perm: !!isClient
            },
            {
                name: 'Clients', href: '/clients', perm: !isClient
            }, {
                name: 'Ailments', href: '/ailments', perm: !isClient
            }, {
                name: 'Admin', href: null, perm: !isClient,
                submenu: [
                    {name: 'Physicians', href: '/physicians', perm: true},
                    {name: 'Locations', href: '/locations', perm: true},
                    {name: 'Assessments', href: '/assessments', perm: true},
                    {name: 'Ailments - Settings', href:'/settings-ailments', perm: true}
                ]
            },
        ];

        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg = it.message;
            // eslint-disable-next-line default-case
            switch (it.message) {
                case 'CREATE_RESOURCE':
                case 'CREATE_DIALOG_RESOURCE':
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                case 'UPDATE_DIALOG_RESOURCE':
                case 'UPDATE_SECOND_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                case 'DELETE_DIALOG_RESOURCE':
                    msg = "Entry deleted";
                    break;
            }
            return (
                <Notification
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    title={it.notificationTitle ?? "Notification"}
                    message={msg}
                />
            )
        });

        const userMenuBtnLabel = getUserLabel();

        return (
            <>
                <div className="h-screen flex bg-secondary-100">
                    <Transition.Root show={this.state.isSidebarOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            static
                            className="fixed inset-0 flex z-40 lg:hidden"
                            open={this.state.isSidebarOpen}
                            onClose={() => this.setSidebarOpen(true)}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                {/* Dynamic sidebar for mobile */}
                                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-sidebar">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-inverse"
                                                onClick={() => this.setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XIcon className="h-6 w-6 text-inverse" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-shrink-0 flex items-center px-4 text-inverse">
                                        <div className="w-48">
                                            <img src="/images/logos/zenden-logo.png" alt="ZenDen"/>
                                        </div>
                                    </div>
                                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                        <nav
                                            className="mt-5 flex-shrink-0 h-full divide-y divide-sidebar-light overflow-y-auto"
                                            aria-label="Sidebar">
                                            <div className="px-2 space-y-1">
                                                {mainNavigation.filter(it => !!it.perm).map(it => {

                                                    const hasSubmenu = !!it.submenu && !!it.submenu.length;
                                                    let subMenuItems = null;

                                                    if (hasSubmenu) {
                                                        subMenuItems = it.submenu.map(item => {
                                                            return (
                                                                <Link
                                                                    to={item.href}
                                                                    className={"text-sidebar-light group flex items-center px-6 py-2 text-sm leading-6 font-medium rounded-md hover:text-white"}>
                                                                    {item.name}
                                                                </Link>
                                                            )
                                                        });
                                                    }

                                                    return (
                                                        <>
                                                            {!hasSubmenu && (
                                                                <Link
                                                                    to={it.href}
                                                                    className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md hover:text-white"}>
                                                                    {it.name}
                                                                </Link>
                                                            )}


                                                            {!!hasSubmenu && (
                                                                <>
                                                                    <div
                                                                        className={"text-sidebar-light group flex items-center pl-2 pt-2 pb-0 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                                        {it.name}
                                                                    </div>

                                                                    {subMenuItems}
                                                                </>
                                                            )}

                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <div className="flex flex-col w-0 flex-1 overflow-hidden ">
                        <div className="relative z-20 flex-shrink-0 flex h-16 bg-secondary-100 shadow">
                            <button
                                type="button"
                                className="px-4 border-r border-secondary-200 text-secondary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
                                onClick={() => this.setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true"/>
                            </button>

                            <div className="flex-1 px-4 sm:px-6 lg:px-8 flex justify-between">

                                <div className="sm:mr-4 flex items-center md:ml-6 lg:w-60 justify-start">
                                    <div className="w-32 sm:w-48 rounded-xl overflow-hidden text-base sm:text-3xl">
                                        <Link to={homePagePath}>
                                            <img src="/images/logos/zenden-logo-black.png" alt="ZenDen"/>
                                        </Link>
                                    </div>
                                </div>

                                <div className="flex-1 flex justify-center">
                                    <nav onMouseLeave={() => this.handleOpenMenuDropdown()}
                                         className="hidden justify-center lg:pt-6 lg:flex lg:space-x-8"
                                         aria-label="Global">

                                        {mainNavigation.filter(it => !!it.perm).map(it => {
                                            const hasSubmenu = !!it.submenu && !!it.submenu.length;
                                            return (
                                                <div key={it.name} className="relative inline-block text-left">
                                                    {!!hasSubmenu && (
                                                        <div className="relative inline-block text-left -top-0.5">
                                                            <div
                                                                onMouseEnter={() => this.handleOpenMenuDropdown(it.name)}
                                                                onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === it.name ? null : it.name)}
                                                                className={classNames('cursor-pointer text-gray-900 py-2 inline-flex items-center font-medium border-b-2 hover:border-primary-600', it.name.toLowerCase().includes(currentPage) ? "border-primary-600" : "border-secondary-100")}
                                                            >
                                                                {it.name}

                                                                {hasSubmenu && <span><ChevronDownIcon className={"ml-1 w-4 h-4"}/></span>}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!hasSubmenu && (
                                                        <div className="relative inline-block text-left -top-0.5">
                                                            <Link
                                                                to={it.href}
                                                                className={'cursor-pointer text-gray-900 py-2 inline-flex items-center font-medium border-b-2 hover:border-primary-600 ' + (it.name.toLowerCase().includes(currentPage) ? "border-primary-600" : "border-secondary-100")}
                                                            >
                                                                {it.name}
                                                            </Link>
                                                        </div>
                                                    )}

                                                    {hasSubmenu && this.state.selectedMenuDropdownItem === it.name && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left top-10 absolute right-0 w-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30 "
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                {it.submenu.filter(subIt => !!it.perm).map(subIt => (
                                                                    <Link
                                                                        key={subIt.name}
                                                                        to={subIt.href}
                                                                        className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                        role="menuitem" tabIndex="-1"
                                                                        id="menu-item-0"
                                                                    >
                                                                        {subIt.name}
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </nav>
                                </div>

                                <div className="sm:ml-4 flex items-center md:ml-6 lg:w-60 justify-end">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative dark">
                                        {({open}) => (
                                            <>
                                                <div>
                                                    <Menu.Button
                                                        className="max-w-xs bg-transparent flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ring-offset-secondary-100 text-secondary-900">
                                                        <span className="sr-only">Open user menu</span>
                                                        <div
                                                            className="w-10 h-10 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                                            <div
                                                                className="text-inverse font-black">{userMenuBtnLabel.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                                        </div>

                                                        <span className="select-none">
                                                            {userMenuBtnLabel}
                                                        </span>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                             viewBox="0 0 10 6" className="ml-2">
                                                            <path fill="currentColor"
                                                                  d="M8.292893.292893c.390525-.390524 1.023689-.390524 1.414214 0 .390524.390525.390524 1.023689 0 1.414214l-4 4c-.390525.390524-1.023689.390524-1.414214 0l-4-4c-.390524-.390525-.390524-1.023689 0-1.414214.390525-.390524 1.023689-.390524 1.414214 0L5 3.585786 8.292893.292893z"/>
                                                        </svg>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="theme-dark-popup origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-inverse ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        {userNavigation.filter(it => !!it.perm).map((item) => (

                                                            <Menu.Item key={item.name}>
                                                                {({active}) => {
                                                                    if (item.action) {
                                                                        return (
                                                                            <div
                                                                                onClick={item.action}
                                                                                className={classNames(
                                                                                    active ? 'bg-secondary-100' : '',
                                                                                    'cursor-pointer block px-4 py-2 text-sm text-secondary-700'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Link
                                                                            to={item.href}
                                                                            className={classNames(
                                                                                active ? 'bg-secondary-100' : '',
                                                                                'block px-4 py-2 text-sm text-secondary-700'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    );
                                                                }}
                                                            </Menu.Item>
                                                        ))}
                                                        <Menu.Item key={"logout"}>
                                                            {({active}) => (
                                                                <Logout
                                                                    className={classNames(
                                                                        active ? 'bg-secondary-100' : '',
                                                                        'block w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-100'
                                                                    )}
                                                                    history={this.props.history}
                                                                    translate={this.props.translate}
                                                                    dispatch={this.props.dispatch}/>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>

                            </div>

                        </div>

                        <main className="flex-1 relative overflow-y-auto focus:outline-none">
                            {this.props.children}

                            {!this.props.noFooter && (
                                <div
                                    className={"bg-primary-700 text-white py-3 text-center text-sm px-4 footer-wrapper" + (!!this.props.footerBorder ? " border-t" : "")}>
                                    <p className="mb-3">©{moment().year()} ZenDen | Developed in partnership with, LUKASA, a modernization business technology consulting firm. </p>

                                    <p>
                                        All rights reserved.
                                    </p>
                                </div>
                            )}
                        </main>
                    </div>
                </div>

                {/* Update Password Dialog */}
                <Transition show={this.state.changePasswordModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.changePasswordModalOpen}
                        onClose={this.handleChangePassword}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                                </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        {this.props.user.isLoading && (
                                            <div className={"inset-center block w-full m-4"}>
                                                <Loader/>
                                            </div>
                                        )}
                                        {!this.props.user.isLoading && !this.props.user.reset && (
                                            <>
                                                <p>Enter new password:</p>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password}
                                                    className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.new_password")}/>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                    className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.password_confirm")}/>

                                                <span className={"passwordStrength"}
                                                      style={{backgroundColor: this.state.passwordStrength.color}}></span>

                                                {this.state.no_match && (
                                                    <div
                                                        className={"text-red-500"}>{translate("reset_password.no_match")}</div>
                                                )}
                                            </>
                                        )}

                                        {!this.props.user.isLoading && this.props.user.reset && (
                                            <div>
                                                <p
                                                    className={"text-lg m-3"}
                                                >
                                                    {translate("reset_password.success_new_pass")}
                                                </p>
                                                <button
                                                    type="button"
                                                    className="m-auto mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={() => {
                                                        this.props.dispatch(resetUserMessage());
                                                        this.handleChangePassword();
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {!this.props.user.isLoading && !this.props.user.reset && (
                                        <div className="pt-5">
                                            <div className="flex justify-end">
                                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                    <button
                                                        type="button"
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                        onClick={this.changePasswordSubmit}
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                        onClick={this.handleChangePassword}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </>
        );
    }
}
