import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {
    createDialogResource,
    deleteDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import DialogDefault from "../../../components/dialog-default";
import DialogResourceListTab from "../../../components/resource-dialog-list-tab";
import {Field, FieldsManager} from "../../../data/services/fields";

export default class AppointmentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /** Lifecycle
     ================================================================= */

    /** Data Events
     ================================================================= */
    handleUpdateResource = (params, query) => {
        params.ClientID = this.getClientID();
        params.ClientAppointmentID = this.getClientAppointmentID();
        delete params.ProviderName
        delete params.AssessmentDate
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get("user"),
            query: query,
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    handleCreateResource = (params, query) => {
        params.ClientID = this.getClientID();
        params.ClientAppointmentID = this.getClientAppointmentID();
        delete params.ProviderName
        delete params.AssessmentDate
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get("user"),
            query: query,
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    handleDeleteResource = (item, query) => {
        this.props.dispatch(deleteDialogResource({
            user: LocalStorage.get("user"),
            query: {
                ClientAssessmentID: item.ClientAssessmentID
            },
            piggyQuery: query,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    /** UI Events
     ================================================================= */

    /** Helpers
     ================================================================= */
    getClientID = () => {
        return this.props.item?.ClientID;
    };

    getClientAppointmentID = () => {
        return this.props.item?.ClientAppointmentID;
    };

    getResourceName = () => {
        return Resources.ClientAppointmentsAssessments
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        return (
            <DialogDefault
                title={translate("modal.title.assessment")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)]"
                visible={!!visible}
                disableOverflow={true}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>
                    <div className="">
                        {/* Content */}
                        <div className="mt-10">
                            <DialogResourceListTab
                                query={{
                                    ClientID: this.getClientID(),
                                    ClientAppointmentID: this.getClientAppointmentID()
                                }}
                                dispatch={this.props.dispatch}
                                translate={translate}
                                dialogResource={this.props.dialogResource}

                                dialogCreateTitle={translate("modal.title.client_medical_history")}
                                dialogEditTitle={translate("modal.title.client_medical_history")}

                                sortBy={"AssessmentTypeID"}
                                primaryKey={"ClientAssessmentID"}
                                resourceName={this.getResourceName()}
                                getFields={(item) => {
                                    return {
                                        AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], !!item, 'select'),
                                        SurveyID: new Field("SurveyID", '', [!!(item?.AssessmentTypeID == 4) ? 'empty' : ''], false, 'select-search', {
                                            hideDialog: !!(item?.AssessmentTypeID != 4)
                                        }),
                                        AssessmentDate: new Field("AssessmentDate", '', [''], false, 'date', {
                                            hideDialog: true
                                        }),
                                        ProviderName: new Field("ProviderName", '', [''], false, 'text', {
                                            hideDialog: true
                                        }),
                                        Summary: new Field("Summary", '', [''], false, 'textarea'),
                                    }
                                }}
                                metadata={{
                                    SurveyID: {
                                        api: 'api/' + Resources.Surveys,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.SurveyID,
                                            label: item.Survey
                                        })
                                    }
                                }}
                                onUpdateResource={this.handleUpdateResource}
                                onCreateResource={this.handleCreateResource}
                                onDeleteResource={this.handleDeleteResource}
                                handleInputChange={(fields, name, value) => {
                                    if (name == "AssessmentTypeID") {
                                        if (value == 4) { // ONE-OFF ASSESSMENT
                                            fields.SurveyID.validate = ['empty']
                                            fields.SurveyID.metadata.hideDialog = false
                                        } else {
                                            fields.SurveyID.validate = []
                                            fields.SurveyID.value = ''
                                            fields.SurveyID.metadata.hideDialog = true
                                        }
                                    }

                                    return FieldsManager.updateField(fields, name, value);
                                }}
                            />
                        </div>
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}
