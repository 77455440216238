import React, {Component} from 'react'
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../data/actions/dialogResource";
import LocalStorage from "../../util/localStorage";
import SimpleTable from "../../components/simple-table";
import {checkPerm, CREATE_PERM, DELETE_PERM, fillFieldsFromData, getProp, UPDATE_PERM} from "../../util/util";
import ModalSaveDialogResource from "../../components/modal/modal-save-resource";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import {FieldsManager} from "../../data/services/fields";

export default class DialogResourceListTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: this.props.sortBy ?? "",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return Object.assign({
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }, (this.props.query) ? this.props.query : {id: this.getId()})
    }

    getFields = (item = null) => {
        if (this.props.getFields) {
            return fillFieldsFromData(this.props.getFields(item), item)
        }
        const fieldTemplates = this.props.fields ?? {};

        return fillFieldsFromData(fieldTemplates, item);
    };

    getTitleClass = () => {
        return this.props.titleClass ?? "text-xl font-bold text-secondary-600";
    }

    getPrimaryButtonClass = () => {
        return this.props.primaryButtonClass ?? "btn btn-primary focus:ring-offset-inverse";
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, addDialogClass, tabTitle, disableCreate} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <h2 className={this.getTitleClass()}>{tabTitle}</h2>

                    <div className="flex-shrink-0 ml-auto mb-3">
                        {checkPerm(this.getResourceName(), CREATE_PERM) && !disableCreate && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className={this.getPrimaryButtonClass()}
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.add")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) ? this.handleToggleEditModel : null}
                    onDelete={checkPerm(this.getResourceName(), DELETE_PERM) ? this.handleToggleConfirmModal : null}
                />

                <ModalSaveDialogResource
                    title={(!!this.props.dialogEditTitle ? this.props.dialogEditTitle : "Edit")}
                    addClass={addDialogClass ?? "max-w-lg"}
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.props.onUpdateResource) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                                this.props.onUpdateResource(params, this.getQuery())
                            } else {
                                params.id = this.getId();
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateDialogResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            }
                            this.handleToggleEditModel();
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                    handleInputChange={this.props.handleInputChange}
                />

                <ModalSaveDialogResource
                    title={(!!this.props.dialogCreateTitle ? this.props.dialogCreateTitle : "Create")}
                    addClass={addDialogClass ?? "max-w-lg"}
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={FieldsManager.cleanFields(this.getFields())}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.props.onCreateResource) {
                                this.props.onCreateResource(params, this.getQuery())
                            } else {
                                params.id = this.getId();
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            }
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                    handleInputChange={this.props.handleInputChange}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        if (this.props.onDeleteResource) {
                            this.props.onDeleteResource(this.state.selectedItem, this.getQuery())
                        } else {
                            this.props.dispatch(deleteDialogResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                        }
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
