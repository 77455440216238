import React, {Component} from 'react'
import {
    BriefcaseIcon,
    DocumentTextIcon,
    EyeIcon,
    LocationMarkerIcon,
    PencilIcon,
    PhoneIcon,
} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    CLIENT_ASSESSMENT_STATUS_ID,
    DEFAULT_CRUD_STATE,
    getProp,
    READ_PERM,
    stateToCrud
} from "../../util/util";
import {getSecondResource} from "../../data/actions/secondResource";
import {Field} from "../../data/services/fields";
import PageHeader from "../../components/layout-dashboard/page-header";
import ResourceListTab from "../../components/resource-list-tab";
import Loader from "../../components/loader";
import ClientModal from "./client-modal";
import IntakeAssessmentModal from "./intake-assessment-modal";
import AppointmentModal from "./appointment-modal";
import {Tabs} from "../../components/tabs";
import {createResource} from "../../data/actions/resource";
import ModalInviteClient from "../../components/modal/modal-invite-client";
import {MailIcon} from "@heroicons/react/outline";
import PainAssessmentModal from "./pain-assessment-modal";
import OneOffAssessmentModal from "./one-off-assessment-modal";
import {Link} from "react-router-dom";
import AppointmentNotesModal from "./appointment-notes-modal";
import ConcussionAssessmentModal from "./concussion-assesment-modal";

class ClientSinglePage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Appointments', resource: Resources.ClientAppointments},
            {name: "Assessments", resource: Resources.ClientAssessments},
            {name: 'Ailments', resource: Resources.ClientAilments},
            {name: 'Tests', resource: Resources.ClientTests},
            {name: 'Plans', resource: Resources.ClientPlans},
            {name: 'Notes', resource: Resources.ClientNotes}
        ].filter((it) => checkPerm(it.resource, READ_PERM));
        tabs[0].current = true;

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "CreateUpdateDate",
            sort: "DESC",

            tabs: tabs,
            selectedTab: tabs[0].resource,

            intakeAssessmentModal: false,
            painAssessmentModal: false,
            oneOffAssessmentModal: false,
            concussionAssessmentModal: false,
            editModalOpen: false,
            clientAppointmentModalOpen: false,
            clientAppointmentNotesModalOpen: false,
            inviteModalOpen: false
        };

        this.assessmentTabRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.secondResource.data === null) && (!!this.props.secondResource.data)) {
            LocalStorage.getAndSave('clientsHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.match.params.id,
                    name: this.props.secondResource.data.ClientName
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

        if (this.getId() !== prevProps.match.params.id) {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Client
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        });
    };

    handleToggleAssessmentModal = (item = null) => {
        switch (item.AssessmentTypeID) {
            case 1:
                // Intake Assessment
                this.handleToggleAssessmentModalHelper(item, 'intakeAssessmentModal');
                break;
            case 2:
                // Pain Assessment
                this.handleToggleAssessmentModalHelper(item, 'painAssessmentModal');
                break;
            case 4:
                // One-Off Assessment
                this.handleToggleAssessmentModalHelper(item, 'oneOffAssessmentModal');
                break;
            case 5:
                // Concussion Assessment
                this.handleToggleAssessmentModalHelper(item, 'concussionAssessmentModal');
                break;
            default:
                break;
        }
    }

    handleToggleAssessmentModalHelper = (item, state) => {
        this.setState({
            selectedItem: item,
            [state]: !this.state[state]
        }, () => {
            if (!this.state[state] && (this.state.selectedTab === Resources.ClientAssessments)) {
                this.assessmentTabRef?.current?.fetchData();
            }
        });
    }

    handleToggleClientAppointmentView = (item = null) => {
        this.setState({
            selectedItem: item,
            clientAppointmentModalOpen: !this.state.clientAppointmentModalOpen
        });
    }

    handleToggleClientAppointmentNotesView = (item = null) => {
        this.setState({
            selectedItem: item,
            clientAppointmentNotesModalOpen: !this.state.clientAppointmentNotesModalOpen
        });
    }

    handleToggleInviteModal = () => {
        this.setState({
            inviteModalOpen: !this.state.inviteModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getQuery = () => {
        return {
            id: this.getId(),
            ...(stateToCrud(this.state))
        }
    };

    returnAilments = (item) => {
        return getProp(item, "Ailments.Ailments", []).map(it => {

            return <>
                <Link className="hover:text-secondary-500" to={"/ailment/" + it.AilmentID}><p>{it.Ailment}</p></Link>
            </>
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const Client = getProp(this.props, "secondResource.data", {});

        const isLoading = getProp(this.props, "secondResource.isLoading", null)

        const InviteSend = getProp(this.props, "secondResource.data.ContactID", false);

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.client")}
                >
                    <div className="mt-5 flex xl:mt-0 xl:ml-4">
                        <button
                            onClick={this.handleToggleInviteModal}
                            type="button"
                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            <MailIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                            {!InviteSend ? "Send Invite" : "Resend Invite"}
                        </button>
                    </div>
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between"
                    >
                        {(!!isLoading) && (
                            <Loader/>
                        )}

                        {(!isLoading) && (
                            <React.Fragment>
                                {!Client.ImagePath && (
                                    <div
                                        className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                        <div
                                            className="text-inverse font-black">{(Client.FirstName + " " + Client.LastName + "").split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}
                                        </div>

                                    </div>
                                )}
                                {Client.ImagePath && (
                                    <div
                                        className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none overflow-hidden">
                                    </div>
                                )}

                                <div className="flex-1 min-w-0">
                                    <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                        {Client.FirstName + " " + Client.LastName}

                                        <button
                                            onClick={this.handleToggleEditModal}
                                            className="btn btn-primary mt-5 sm:mt-0 ml-4"
                                        >
                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                                            Edit
                                        </button>
                                    </h1>

                                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                       aria-hidden="true"/>
                                            {Client.AreaCode} {Client.Phone} {Client.PhoneExtension}
                                        </div>
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <LocationMarkerIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                aria-hidden="true"/>
                                            {Client.Address1 ? Client.Address1 + ",": ""} {Client.City ? Client.City + ",": ""} {Client.State ? Client.State + ",": ""} {Client.Zip}
                                        </div>
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                           aria-hidden="true"/>
                                            {Client.MembershipLevel}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 flex xl:mt-0 xl:ml-4">
                                    <span className="hidden sm:block">

                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </header>

                <main className="pb-16 relative custom-min-h-tab bg-inverse">
                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("tab." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <Tabs
                                tabs={this.state.tabs}
                                onTabChange={this.handleTabChange}
                            />
                        </div>

                        {/* Content */}
                        <div className="mt-6">

                            {this.state.selectedTab === Resources.ClientAssessments && (
                                <ResourceListTab
                                    disableCreate={true}
                                    id={this.getId()}
                                    dialogTitle={"Assessment"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    disableDefaultEdit={true}
                                    onEdit={(item) => this.handleToggleAssessmentModal(item)}
                                    sort={"DESC"}
                                    sortBy={"AppointmentDate"}
                                    primaryKey={"ClientAssessmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], false, 'select', {
                                            render: (item) => (
                                                <td onClick={() => this.handleToggleAssessmentModal(item)} className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12 cursor-pointer text-primary-600'}>
                                                    {item.AssessmentType}
                                                </td>
                                            )
                                        }),
                                        ClientAssessmentStatusID: new Field("ClientAssessmentStatusID", '', ['empty'], false, 'select', {
                                            render: (item) => (
                                                <td className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12'}>
                                                    {CLIENT_ASSESSMENT_STATUS_ID[item.ClientAssessmentStatusID]}
                                                </td>
                                            ),
                                            hideDialog: true,
                                        }),
                                        Ailments: new Field("Ailments", '', ['empty'], false, 'custom', {
                                            render: (item) => (
                                                <td className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12'}>
                                                    {this.returnAilments(item)}
                                                </td>
                                            ),
                                            omitSort: true,
                                            hideDialog: true
                                        }),
                                        AppointmentDate: new Field("AppointmentDate", '', [''], false, 'date'),
                                        ProviderName: new Field("ProviderName", '', [''], false, 'text', {
                                            hideDialog: true
                                        }),
                                        Summary: new Field("Summary", '', [''], false, 'textarea'),
                                    }}
                                    searchFields={{
                                        StartDate: new Field("StartDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        }),
                                        EndDate: new Field("EndDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        })
                                    }}
                                    ref={this.assessmentTabRef}
                                    metadata={{
                                        ClientAssessmentStatusID: CLIENT_ASSESSMENT_STATUS_ID
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientAppointments && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dialogTitle={"Appointment"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AppointmentDate"}
                                    primaryKey={"ClientAppointmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AppointmentTypeID: new Field("AppointmentTypeID", '', ['empty'], false, 'select', {
                                            render: (item) => (
                                                <td onClick={() => item.AppointmentTypeID == 1 ? this.handleToggleClientAppointmentView(item) : null}
                                                    className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12 ' + (item.AppointmentTypeID == 1 ? "text-primary-600 cursor-pointer" : "")}>
                                                    {item.AppointmentType}
                                                </td>
                                            )
                                        }),
                                        AppointmentDate: new Field("AppointmentDate", '', [''], false, 'date'),
                                        AppointmentStatusID: new Field("AppointmentStatusID", '', [''], false, 'select'),
                                        Cost: new Field("Cost", '', ['float_or_empty'], false, 'text'),
                                        Description: new Field("Description", '', [''], false, 'textarea')
                                    }}

                                    searchFields={{
                                        StartDate: new Field("StartDate", '', [], false, "date", {
                                            searchCompare: '>=',
                                            searchKey: 'AppointmentDate'
                                        }, {isClearable: true}),
                                        EndDate: new Field("EndDate", '', [], false, "date", {
                                            searchCompare: '<=',
                                            searchKey: 'AppointmentDate'
                                        }, {isClearable: true}),
                                        AppointmentStatusID: new Field("AppointmentStatusID", '', [''], false, 'select', {}, {
                                            isClearable: true
                                        }),
                                    }}

                                    onCustomAction={(it) => {
                                        if (it.AppointmentTypeID == 1) {
                                            return (
                                                <>
                                                    <button
                                                        onClick={() => this.handleToggleClientAppointmentView(it)}
                                                        type="button"
                                                        className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                    >
                                                        <EyeIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                                 aria-hidden="true"/>
                                                        <span className="hidden md:inline">View</span>
                                                    </button>

                                                    <button
                                                        onClick={() => this.handleToggleClientAppointmentNotesView(it)}
                                                        type="button"
                                                        className="ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                    >
                                                        <DocumentTextIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                                 aria-hidden="true"/>
                                                        <span className="hidden md:inline">Notes</span>
                                                    </button>
                                                </>
                                            )
                                        } else {
                                            return (
                                                <button
                                                    onClick={() => this.handleToggleClientAppointmentNotesView(it)}
                                                    type="button"
                                                    className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                >
                                                    <DocumentTextIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                                      aria-hidden="true"/>
                                                    <span className="hidden md:inline">Notes</span>
                                                </button>
                                            )
                                        }


                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientTests && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Test"}
                                    sortBy={"TestType"}
                                    primaryKey={"ClientTestID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        TestTypeID: new Field("TestTypeID", '', ['empty'], false, 'select'),
                                        TestDate: new Field("TestDate", '', [''], false, 'date'),
                                        Summary: new Field("Summary", '', [''], false, 'textarea'),
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientAilments && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Ailment"}
                                    sortBy={"AilmentID"}
                                    primaryKey={"ClientAilmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AilmentID: new Field("AilmentID", '', ['empty'], false, 'select-search', {
                                            render: (item) => (
                                                <td onClick={() => {
                                                    this.props.history.push('/ailment/' + item.AilmentID);
                                                }}
                                                    className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12 text-primary-600 cursor-pointer'}>
                                                    {item.Ailment}
                                                </td>
                                            )
                                        }),
                                        AilmentDate: new Field("AilmentDate", '', [''], false, 'date'),
                                        Active: new Field("Active", '', [''], false, 'checkbox'),
                                        Description: new Field("Description", '', [''], false, 'textarea'),
                                    }}
                                    metadata={{
                                        AilmentID: {
                                            api: 'api/' + Resources.Ailments,
                                            query: {},
                                            searchMap: (item) => ({
                                                value: item.AilmentID,
                                                label: item.Ailment
                                            })
                                        },
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientPlans && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Plan"}
                                    sortBy={"ClientPlanID"}
                                    primaryKey={"ClientPlanID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        PlanTypeID: new Field("PlanTypeID", '', ['empty'], false, 'select'),
                                        PlanDate: new Field("PlanDate", '', [''], false, 'date'),
                                        PlanFrequency: new Field("PlanFrequency", '', [''], false, 'text'),
                                        PlanStatusID: new Field("PlanStatusID", '', [''], false, 'select'),
                                        Description: new Field("Description", '', [''], false, 'text')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientNotes && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Note"}
                                    sortBy={"NoteTitle"}
                                    primaryKey={"ClientNoteID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        NoteTitle: new Field("NoteTitle", '', ['empty'], false, 'text'),
                                        Description: new Field("Description", '', ['empty'], false, 'textarea'),
                                        IsVisibleToClient: new Field("IsVisibleToClient", '', [''], false, 'checkbox')
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <ClientModal
                        {...this.props}
                        id={this.getId()}
                        visible={!!this.state.editModalOpen}
                        onClose={this.handleToggleEditModal}
                    />

                    <IntakeAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.intakeAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <PainAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.painAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <OneOffAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.oneOffAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <ConcussionAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.concussionAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <AppointmentModal
                        {...this.props}
                        item={this.state.selectedItem}
                        visible={!!this.state.clientAppointmentModalOpen}
                        onClose={this.handleToggleClientAppointmentView}
                    />

                    <AppointmentNotesModal
                        {...this.props}
                        item={this.state.selectedItem}
                        visible={!!this.state.clientAppointmentNotesModalOpen}
                        onClose={this.handleToggleClientAppointmentNotesView}
                    />

                    <ModalInviteClient
                        title={"Send Invite"}
                        visible={this.state.inviteModalOpen}
                        onClose={this.handleToggleInviteModal}
                        onSubmit={(params) => {
                            if (params) {
                                params.ClientID = this.getId();
                                params.id = this.getId();
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    params: params,
                                    resource: Resources.ClientInvite,
                                    notificationMessage: "Email invitation is sent to the client"
                                }));
                                this.handleToggleInviteModal()
                            }
                        }}
                        translate={this.props.translate}
                    />
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ClientSinglePage);
