import React, {Component, Fragment} from "react";
import {classNames} from "../../util/util";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline";

export default class DialogDefault extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.blurBackdrop && this.props.visible && (this.props.visible !== prevProps.visible)) {
            const root = document.getElementById("root");
            if (root){
                root.classList.add("filter", "blur-sm")
            }
        }

        if (!!this.props.blurBackdrop && !this.props.visible && (this.props.visible !== prevProps.visible)) {
            const root = document.getElementById("root");
            if (root){
                root.classList.remove("filter", "blur-sm")
            }
        }
    }

    componentWillUnmount() {
        const root = document.getElementById("root");
        if (root){
            root.classList.remove("filter", "blur-sm")
        }
    }

    close = () => {
        // Prevent closing dialog when clicking on notification
        const notificationElement = document.querySelector(".js-notification");

        if (!notificationElement) {
            this.props.onClose()
        }
    }

    render() {
        const {visible, onClose, title, addClass, translate} = this.props;
        return (
            <Transition show={visible} as={Fragment}>
                <Dialog
                    as="div"
                    id="modal"
                    className="fixed inset-0 z-20 overflow-y-auto max-h-screen"
                    static
                    open={visible}
                    onClose={this.close}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={classNames("inline-block w-full my-6 text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl", (!!this.props.widthClass ? " " + this.props.widthClass : " max-w-7xl"), (this.props.disableOverflow ? "" : " overflow-hidden"))}>
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() => onClose(false)}
                                    >
                                        <span className="sr-only">{translate("text.close")}</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>


                                <div className={classNames(addClass, "px-4 py-5 border-b border-gray-200 sm:px-6", (this.props.bgClass ? this.props.bgClass : "bg-white"), (this.props.disableOverflow ? "rounded-2xl" : " "))}>
                                    <h2 className={"text-2xl font-bold leading-7 mb-2 text-gray-900 sm:text-3xl"}>{title}</h2>
                                    {this.props.children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        )
    }
}
