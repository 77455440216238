import React from 'react';
import Button from "../../../components/button";

const RatingScales = (props) => {
    let {topRating, desiredAnswer, preview, value, name, disabledEdit} = props;

    topRating = topRating ?? 10;

    const ratingScales = [ ...Array(topRating).keys() ]

    return (
        <React.Fragment>
            <div className="inline-block w-64 sm:w-96 flex justify-between ml-4 text-secondary-600">
                <p>Not at all likely</p>
                <p>Extremely likely</p>
            </div>

            <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
            >
                {ratingScales.map((it, i) => {
                    let classes = "z-10 relative inline-flex items-center px-[10px] sm:px-4 py-[5px] sm:py-2 border text-sm focus:bg-primary-500 focus:text-white";

                    if (preview) {
                        classes = classes + (desiredAnswer > i + 1 ? " bg-white border-red-400 text-red-600" : " bg-primary-50 border-primary-500 text-primary-600") + (desiredAnswer == i + 1 ? " font-bold border-2" : " font-medium ");
                    } else {
                        classes = classes + " cursor-pointer border border-secondary-500 " + (value == i+1 ? " font-bold bg-primary-600 text-white" : " bg-secondary-50 text-secondary-600")
                    }

                    return (
                        <Button
                            onClick={(preview || disabledEdit) ? () => null : () => props.onClick(name, i+1)}
                            key={"button" + it}
                            className={classes}
                        >
                            {it + 1}
                        </Button>
                    )
                })}
            </nav>
        </React.Fragment>
    )
}

export default RatingScales;
