import {
    watchLoginCall,
    watchRegisterCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall,
    watchUpdatePasswordCall
} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateResource,
    watchCreateSecondResource,
    watchDeleteResource, watchDeleteSecondResource,
    watchDownloadDocument,
    watchGetResource,
    watchGetResourceDetails,
    watchGetSecondResource, watchGetThirdResource,
    watchUpdateResource,
    watchUpdateSecondResource,
    watchUploadDocument
} from "./resourceSaga";
import {
    watchCreateDialogResource,
    watchDeleteDialogResource,
    watchGetDialogResource,
    watchGetDialogResourceDetails,
    watchUpdateDialogResource,
    watchUploadDialogDocument
} from "./dialogResourceSaga";
import {watchPushNotification} from "./ui";
import {watchGetContacts, watchGetConversations, watchGetMessages, watchGetSmsMessage} from "./messagingSaga";
import {watchGetNotificationList} from "./notifications"
/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        // Login/Register
        watchLoginCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),
        watchUpdatePasswordCall(),

        // Resource
        watchGetResource(),
        watchGetSecondResource(),
        watchGetResourceDetails(),
        watchCreateResource(),
        watchCreateSecondResource(),
        watchUpdateResource(),
        watchUpdateSecondResource(),
        watchDeleteResource(),
        watchDeleteSecondResource(),
        watchGetThirdResource(),

        watchUploadDocument(),
        watchDownloadDocument(),

        // Resource dialog
        watchGetDialogResource(),
        watchGetDialogResourceDetails(),
        watchCreateDialogResource(),
        watchUpdateDialogResource(),
        watchDeleteDialogResource(),
        watchUploadDialogDocument(),

        // Notifications
        watchPushNotification(),

        // Messages
        watchGetMessages(),
        watchGetConversations(),
        watchGetContacts(),
        watchGetSmsMessage(),

        // Notifications
        watchGetNotificationList()
    ]);
}
