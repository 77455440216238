import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";

let timezone = require('./timezone.json')
let dateTimeFormat = require('./datetimeformat.json')

export default class AccountTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            checkboxes: this.getCheckBoxes(),
            canSubmit: false,
            NotificationSetting: {}
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data.Contact", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {

                        let user = LocalStorage.get('user');
                        user.Contact.Timezone = this.state.fields.Timezone.value;
                        user.Contact.DateTimeFormat = this.state.fields.DateTimeFormat.value;
                        LocalStorage.set('user', user);

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get("user"),
                            params: Object.assign({NotificationSetting: Object.keys(this.state.NotificationSetting).map((it) => (
                                    {
                                        NotificationChannelID: it,
                                        NotificationLevelID: 1,
                                        Value: !!this.state.NotificationSetting[it] ? 1 : 0,
                                    }
                            ))}, FieldsManager.getFieldKeyValues(this.state.fields)),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                    });
                }
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data.Contact", null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    handleNotificationSettingChange = (id, val) => {
        let NotificationSetting = this.state.NotificationSetting;

        NotificationSetting[id] = val;

        this.setState({
            NotificationSetting: NotificationSetting,
            canSubmit: true
        })
    }

    handleCheckboxChange = (row, column, value) => {
        let checkboxes = this.state.checkboxes;
        checkboxes[row][column] = value;

        this.setState({
            checkboxes: checkboxes,
            canSubmit: true
        })
    }


    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.User;
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            FirstName: new Field('FirstName', "", ['empty']),
            LastName: new Field('LastName', "", ['empty']),
            Timezone: new Field('Timezone', "", ['empty'], false, 'select'),
            DateTimeFormat: new Field('DateTimeFormat', "", ['empty'], false, 'select')
        }

        if (!!item && item.Timezone === "UTC") {
            item.Timezone = "";
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getCheckBoxes = (item = null) => {
        let checkboxes =  [
            {
                name: "Info",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0,
            },{
                name: "Notice",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0
            }, {
                name: "Important",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0,
            }
        ];

        return item || checkboxes;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            Timezone: timezone,
            DateTimeFormat: dateTimeFormat
        };

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center z-10"}>
                        <Loader/>
                    </div>
                )}

                <form className="space-y-8 divide-y divide-gray-200">
                    {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}
                </form>

                {!isLoading && (
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Button type="outline"
                                    className="btn btn-outline focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.handleCancel}
                            >
                                {translate("btn.cancel")}
                            </Button>

                            <Button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                disabled={!this.state.canSubmit}
                                onClick={this.state.canSubmit && this.onSubmit}
                            >
                                {translate("btn.save")}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
