import React from 'react';
import {Menu, Transition} from "@headlessui/react";
import {DotsVerticalIcon} from "@heroicons/react/solid";

export default function NavResponsiveMenu(props) {
    const {menuList, onTabChange} = props;

    const menuItems = menuList.map(it => {
        return (
            <Menu.Item>
                {({active}) => (
                    <span
                        onClick={() => onTabChange(it.resource)}
                        className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                    >
                    {it.name}
                </span>
                )}
            </Menu.Item>
        )
    })

    return (
        <Menu>
            {({open}) => (
                <React.Fragment>
                    <Menu.Button
                        className="absolute text-white w-10 h-10 w-8 h-8 p-0 flex justify-center items-center right-0 top-5 hover:bg-secondary-100 rounded-full focus:outline-none focus:ring-2 ring-primary-600"
                    >
                        <DotsVerticalIcon className="w-5 h-5 text-primary-600"/>
                    </Menu.Button>

                    <Transition
                        show={open}
                    >
                        <Menu.Items
                            className="z-10 absolute right-0 top-12 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-secondary-300"
                        >
                            {menuItems}
                            <div
                                className="absolute w-2 h-2 -top-1 right-3 border-l border-t transform rotate-45 border-solid border-secondary-300 bg-white -z-1"/>
                        </Menu.Items>
                    </Transition>
                </React.Fragment>
            )}
        </Menu>
    )
}