const notificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: true,
                unreadCount: 0
            });
        case 'DONE_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: false,
                data: action.data
            });
        case 'ERROR_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: false,
                errorNotificationList: true
            });
        default:
            return state;
    }
};

export default notificationsReducer;