import React, {Component} from 'react'
import {
    checkPerm,
    fillFieldsFromData,
    getProp,
    UPDATE_PERM
} from "../../../../util/util";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import {Field} from "../../../../data/services/fields";
import SimpleTable from "../../../../components/simple-table";
import SingleQuestionTab from "./single-question";
import Resources from "../../../../data/services/resources";


export default class QuestionsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            paginationPage: 1,

            showSingleQuestion: false,
            selectedItem: null
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */
    handleSingleQuestion = (item = null) => {
        this.setState({
            selectedItem: item,
            showSingleQuestion: !this.state.showSingleQuestion
        })
    };

    handleBackToQuestions = () => {
        this.setState({
            selectedItem: null,
            showSingleQuestion: !this.state.showSingleQuestion
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = () => {
        const item = getProp(this.props, "resource.data", {});

        const fieldTemplates = {
            ConcussionType: new Field("ConcussionType", '', [], false, 'text'),
            Status: new Field("Status", '', [], false, 'text')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {tabTitle} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <div>

                {!this.state.showSingleQuestion && !this.state.selectedItem && (
                    <div>
                        <div className="mt-6 mb-6 flex flex-wrap">
                            <h2 className="text-xl font-bold text-secondary-600">{tabTitle}</h2>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}

                            hideCaption={true}
                            onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.handleSingleQuestion}
                        />
                    </div>
                )}

                {this.state.showSingleQuestion && this.state.selectedItem && (
                    <SingleQuestionTab
                        tabTitle={"Question"}
                        id={this.state.selectedItem.ClientAssessmentConcussionQuestionID}
                        formClassName="grid grid-cols-1 gap-4"
                        dispatch={this.props.dispatch}
                        translate={this.props.translate}
                        resource={this.props.resource}
                        resourceName={Resources.ClientConcussionAssessmentsQuestion}
                        primaryKeyName={"ClientAssessmentConcussionQuestionID"}
                        handleInputChange={this.handleInputChange}
                        metadata={this.state.metadata}
                        handleBackToQuestions={this.handleBackToQuestions}
                    />
                )}

            </div>
        )
    }
}
