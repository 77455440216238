import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getHomePage, getProp, userBelongsToType} from "../../util/util";
import FieldSearch from "../../components/field-search";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import PageHeader from "../../components/layout-dashboard/page-header";
import ModalViewResource from "../../components/modal/modal-view-resource";

class SurveysPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "SurveyID",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            viewModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (userBelongsToType(this.props.user.data, ["Client"])) {
            this.props.history.push(getHomePage(this.props.user.data));
        } else {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen,
            offset: 0,
            limit: 10,
            paginationPage: 1
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            limit: 10,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleToggleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    handleToggleEditModal = (item = null) => {
        this.props.history.push("assessments/" + item.SurveyID);
    };

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "SurveyID";
    }

    getResourceName = () => {
        return Resources.Surveys;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const fieldTemplates = {
            Survey: new Field("Survey", '', ['empty'], false, "text"),
            Description: new Field("Description", '', ['empty'], false, "textarea"),
        };

        if (includeFields) {
            for (const [key] of Object.entries(fieldTemplates)) {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key];
                }
            }
        }

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getReadOnlyFields = (item = null) => {
        const fieldTemplates = {
            Survey: new Field("Survey", '', ['empty'], false, "readonly", {formGroupClass: "col-span-2"}),
            Description: new Field("Description", '', ['empty'], false, "readonly", {formGroupClass: "sm:col-span-1"}),
        };

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = "";

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto min-h-[calc(100vh-8.75rem)]">
                    <PageHeader
                        title={translate("page.title.surveys")}
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />

                            <div className="ml-auto mb-3">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    className="btn btn-primary focus:ring-offset-inverse"
                                >
                                    <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.add")}
                                </button>
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.getFields(null, ['Address2', 'Zip', 'Notes'])}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleToggleViewModal}
                            onEdit={this.handleToggleEditModal}
                            onDelete={this.handleToggleConfirmModal}
                        />
                    </div>

                    <ModalSaveResource
                        title={"Add survey"}
                        addClass="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalViewResource
                        title={"Survey Information"}
                        subTitle={""}
                        className="max-w-lg"
                        visible={this.state.viewModalOpen}
                        onClose={this.handleToggleViewModal}
                        fields={this.getReadOnlyFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleViewModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete this survey?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(SurveysPage);
