import React, {Component} from "react";
import Layout from "../../components/layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {Field, FieldsManager} from "../../data/services/fields";
import {register} from "../../data/actions/user";
import Button from "../../components/button/index";
import FieldText from "../../components/field-text/index";

class RegisterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                Email: new Field('Email', '', ['email', 'empty'])
            }
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(register({
                    params: Object.assign({
                        Token: this.props.match.params && this.props.match.params.token
                    }, FieldsManager.getFieldKeyValues(this.state.fields))
                }));
            }
        });
    };

    render() {
        const {translate} = this.props;

        return (
            <Layout {...this.props}>
                <div className="pt-24 px-14 pb-7 layout-min-h">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg p-8 max-w-sm mx-auto">
                        <div className="p-8 text-center">
                            <img className="inline-block w-40" src="/images/logos/logo.png" alt="GoLeir"/>
                        </div>

                        {!this.props.user.isLoading && !this.props.user.register && (
                            <form onKeyPress={this.submitForm}>
                                <div className="mb-6 ">
                                    <label className="block font-bold mb-2 text-secondary-900" htmlFor="email">Email
                                        Address</label>
                                    <FieldText
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.Email}
                                        placeholder={"email@domain.com"}
                                        type={"email"}
                                        errorLabelMessage={"Email required"}
                                    />
                                </div>


                                <div className="">
                                    <Button
                                        className="w-full justify-center cursor-pointer"
                                        type="primary"
                                        onClick={this.submit}>
                                        {translate("btn.register")}
                                    </Button>
                                </div>
                            </form>
                        )}

                        {this.props.user.isLoading && (
                            <LoaderSmall/>
                        )}

                        {this.props.user.register && (
                            <React.Fragment>
                                <div>{translate("text.register_success")}</div>
                                <Link to={`/login`}>{translate("btn.back_to_login")}</Link>
                            </React.Fragment>
                        )}

                        {this.props.user.error && (
                            <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-100 text-red-700">
                                <svg className="text-red-500 w-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                          clipRule="evenodd"/>
                                </svg>

                                {translate(this.props.user.errorMessage)}
                            </div>
                        )}
                    </div>

                    <div className="text-secondary-600 mt-6 text-center">
                        Sign in with another account? <Link
                        className="no-underline border-b border-primary text-primary-700 hover:opacity-70"
                        to={"/login"}>Login</Link>.
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(state => state)(RegisterView);