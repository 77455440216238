import React, {Component, Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline";
import {FieldsManager} from "../../../data/services/fields";
import {fieldsToHtml, scrollErrorIntoView} from "../../../util/util";
import FieldText from "../../../components/field-text";
import FieldCheckbox from "../../../components/field-checkbox";

export default class SurveyBuilderSlideOver extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.props.fields,
            questionItems: [""],
            dropDownOptions: [{}],
            selectedDropdownOptions: [],
            rankingOptions: [""],
            itemIndexDragged: -1,
        };

        this.questionMatrixID = "3";
        this.ratingScalesID = "1";
        this.likertScalesID = "2";
        this.dropDownID = "4";
        this.rankingQuestionsID = "5";
        this.textQuestionID = "6";
        this.numberQuestionID = "7";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fields !== prevProps.fields && !!this.props.show) {
            let fields = this.props.fields;


            const QuestionOrderNumPreFill = !!fields.OrderNum.value ? fields.OrderNum.value : this.props.questionNum + 1;
            fields.OrderNum.value = QuestionOrderNumPreFill;

            fields = this.updateFieldsBySurveyType(fields, fields.SurveyQuestionTypeID.value)

            let dropdownOptions = [{}];
            if (!!this.props.questionItems && fields.SurveyQuestionTypeID.value == this.dropDownID) {
                let desiredValues = fields.DesiredAnswer.value.split(",");

                dropdownOptions = this.props.questionItems.map((it, i) => {
                    const itemOrderNumber = i + 1;
                    let item = {};
                    item.label = it;
                    item.selected = desiredValues.includes(itemOrderNumber.toString())
                    return item
                })
            }

            this.setState({
                fields: fields,
                questionItems: !!this.props.questionItems && fields.SurveyQuestionTypeID.value == this.questionMatrixID ? this.props.questionItems : [""],
                dropDownOptions: dropdownOptions,
                rankingOptions: !!this.props.questionItems && fields.SurveyQuestionTypeID.value == this.rankingQuestionsID ? this.props.questionItems : [""],
                selectedDropdownOptions: (this.state.fields.SurveyQuestionTypeID.value == this.dropDownID && fields.DesiredAnswer.value) ? fields.DesiredAnswer.value.split(",").map(i => Number(i)) : []
            })
        }
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields;

        if (name === "SurveyQuestionTypeID") {
            fields = this.updateFieldsBySurveyType(fields, value)
        }

        this.setState({canSubmit: true, fields: FieldsManager.updateField(fields, name, value)});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        validatedFields = !!this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    getQuestionOptions = () => {
        const questionID = this.state.fields.SurveyQuestionTypeID.value;

        switch (questionID) {
            case 4:
            case this.dropDownID:
                return {Items: this.state.dropDownOptions.map(it => it.label)}
                break;
            case 3:
            case this.questionMatrixID:
                return {Items: this.state.questionItems}
                break;
            case 5:
            case this.rankingQuestionsID:
                return {Items: this.state.rankingOptions}
                break;
            default:
                return null
        }
    }

    addMatrixQuestion = () => {
        const questionItems = this.state.questionItems;

        questionItems.push("");

        this.setState({
            questionItems
        })
    }

    removeMatrixQuestion = (i) => {
        const questionItems = this.state.questionItems;

        questionItems.splice(i, 1);

        this.setState({
            questionItems
        })
    }

    handleMatrixQuestionChange = (name, value, i) => {
        const questionItems = this.state.questionItems;

        questionItems[i] = value;

        this.setState({
            questionItems
        })
    }

    handleDropdownOptionsChange = (name, value, i) => {
        const dropDownOptions = this.state.dropDownOptions;

        dropDownOptions[i] = {label: value, selected: false};

        this.setState({
            dropDownOptions
        })
    }

    handleRankingOptionsChange = (name, value, i) => {
        const rankingOptions = this.state.rankingOptions;

        rankingOptions[i] = value;

        this.setState({
            rankingOptions
        })
    }

    addDropdownOption = () => {
        const dropDownOptions = this.state.dropDownOptions;

        dropDownOptions.push({label: "", selected: false});

        this.setState({
            dropDownOptions
        })
    }

    addRankingOption = () => {
        const rankingOptions = this.state.rankingOptions;

        rankingOptions.push("");

        this.setState({
            rankingOptions
        })
    }

    removeRankingOption = (i) => {
        const rankingOptions = this.state.rankingOptions;

        rankingOptions.splice(i, 1);

        this.setState({
            rankingOptions
        })
    }

    removeDropdownOption = (i) => {
        const dropDownOptions = this.state.dropDownOptions;

        dropDownOptions.splice(i, 1);

        this.setState({
            dropDownOptions
        })
    }

    getSelects = () => {
        switch (this.state.fields.SurveyQuestionTypeID.value) {
            case 2:
            case this.likertScalesID:
                return {
                    DesiredAnswer: {
                        1: "Strongly disagree",
                        2: "Disagree",
                        3: "Neither agree nor disagree",
                        4: "Agree",
                        5: "Strongly Agree"
                    }
                }
                break;
            case 1:
            case this.ratingScalesID:
                return {
                    DesiredAnswer: [...Array(this.props.topRating).keys()].reduce((memo, it) => {
                        memo[it + 1] = it + 1;
                        return memo
                    }, {})
                }
                break;
            default:
                return {};
        }

    }

    handleDragStart = (i) => {
        this.setState({itemIndexDragged: i})
    }

    handleDragEnter = (i, draggingOptionsStateName) => {
        // Names:
        // 1. dropDownOptions
        // 2. rankingOptions

        let rowOrder = JSON.parse(JSON.stringify(this.state[draggingOptionsStateName]));

        [rowOrder[this.state.itemIndexDragged], rowOrder[i]] = [rowOrder[i], rowOrder[this.state.itemIndexDragged]];

        let fields = this.state.fields;

        if (draggingOptionsStateName === "dropDownOptions") {
            const selectedDropdownOptions = rowOrder.reduce((memo, it, i) => {
                if (!!it.selected) memo.push(i + 1);
                return memo
            }, []);

            fields.DesiredAnswer.value = selectedDropdownOptions.length && selectedDropdownOptions.join();
        }

        this.setState({
            [draggingOptionsStateName]: rowOrder,
            itemIndexDragged: i,
            fields
        })
    }

    handleDragEnd = () => {

    }

    handleDragLeave = () => {

    }

    handleDrop = () => {
        this.setState({itemIndexDragged: -1})
    }

    getMatrixQuestions = () => {
        return (
            <React.Fragment>
                {this.state.questionItems.map((it, i) => (
                    <div className="flex justify-between relative">
                        <FieldText
                            className="form-control"
                            onChange={(name, value) => this.handleMatrixQuestionChange(name, value, i)}
                            name={"matrix_question_" + it + 1}
                            value={it}
                            placeholder={this.props.translate("field.placeholder.type_question")}
                        />

                        <button
                            type="button"
                            className="absolute right-2 top-2 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => this.removeMatrixQuestion(i)}
                        >
                            <span className="sr-only">Remove question</span>
                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                ))}

                <div className="text-right">
                    <button
                        className="btn btn-outline"
                        onClick={this.addMatrixQuestion}
                    >
                        Add question
                    </button>
                </div>
            </React.Fragment>
        )
    }

    getDropdownOptions = () => {
        return (
            <React.Fragment>
                <div className="border-t">
                    <p className="text-sm pt-5">Add questions for the dropdown and select preferred question</p>
                </div>

                {this.state.dropDownOptions.map((it, i) => (
                    <div
                        className={"flex justify-between relative select-none p-1 rounded" + (this.state.itemIndexDragged === i ? " bg-primary-50" : "")}
                        onDragEnter={() => this.handleDragEnter(i, 'dropDownOptions')}
                        onDragLeave={this.handleDragLeave}
                        onDrop={this.handleDrop}
                    >
                        <label className="px-2 mt-1 w-12 flex justify-between items-center">
                            <div className="mr-2 cursor-move select-none" draggable
                                 onDragStart={() => this.handleDragStart(i)}>
                                <div className="w-3 grid grid-cols-2 gap-1">
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                </div>
                            </div>
                        </label>

                        <label className="mt-1 w-12 text-center">
                            <FieldCheckbox
                                className="mr-3 cursor-pointer mt-1"
                                name={"prefered"}
                                value={it.selected}
                                onChange={() => this.togglePreferredQuestion(i)}
                            />
                        </label>

                        <FieldText
                            className="form-control"
                            onChange={(name, value) => this.handleDropdownOptionsChange(name, value, i)}
                            name={"dropdown_option_" + it + 1}
                            value={it.label}
                            placeholder={this.props.translate("field.placeholder.type_question")}
                        />

                        <button
                            type="button"
                            className="absolute right-2 top-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => this.removeDropdownOption(i)}
                        >
                            <span className="sr-only">Remove question</span>
                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                ))}

                <div className="text-right">
                    <button
                        className="btn btn-outline"
                        onClick={this.addDropdownOption}
                    >
                        Add option
                    </button>
                </div>
            </React.Fragment>
        )
    }

    getRankingOptions = () => {
        return (
            <React.Fragment>
                <div className="border-t">
                    <p className="text-sm pt-5">Add options for ranking and sort them by desired order</p>
                </div>

                {this.state.rankingOptions.map((it, i) => (
                    <div
                        className={"flex justify-between relative select-none p-1 rounded" + (this.state.itemIndexDragged === i ? " bg-primary-50" : "")}
                        onDragEnter={() => this.handleDragEnter(i, 'rankingOptions')}
                        onDragLeave={this.handleDragLeave}
                        onDrop={this.handleDrop}
                    >
                        <label className="px-2 mt-1 w-12 flex justify-between items-center">
                            <div className="mr-2 cursor-move select-none" draggable
                                 onDragStart={() => this.handleDragStart(i)}>
                                <div className="w-3 grid grid-cols-2 gap-1">
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"/>
                                </div>
                            </div>

                            {i + 1}.
                        </label>

                        <FieldText
                            className="form-control bg-transparent"
                            onChange={(name, value) => this.handleRankingOptionsChange(name, value, i)}
                            name={"ranking_option_" + it + 1}
                            value={it}
                            placeholder={this.props.translate("field.placeholder.type_question")}
                        />

                        <button
                            type="button"
                            className="bg-transparent absolute right-2 top-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => this.removeRankingOption(i)}
                        >
                            <span className="sr-only">Remove question</span>
                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                ))}

                <div className="text-right">
                    <button
                        className="btn btn-outline"
                        onClick={this.addRankingOption}
                    >
                        Add option
                    </button>
                </div>
            </React.Fragment>
        )
    }

    updateFieldsBySurveyType = (fields, value) => {
        return fields;

        switch (value) {
            case 3:
            case this.questionMatrixID:
            // fall through
            case 6:
            case this.textQuestionID:
                fields.DesiredAnswer.type = "hidden";
                fields.DesiredAnswer.validate = [""];
                fields.RankingPoints.type = "hidden";
                fields.RankingPoints.validate = [""];
                break;
            case 5:
            case this.rankingQuestionsID:
                fields.DesiredAnswer.type = "hidden";
                fields.DesiredAnswer.validate = [""];
                break;
            case 7:
            case this.numberQuestionID:
                fields.DesiredAnswer.type = "text";
                fields.DesiredAnswer.validate = ["integer_positive"];
                if (!fields.DesiredAnswer.metadata) {
                    fields.DesiredAnswer.metadata = {}
                }
                fields.DesiredAnswer.metadata.fieldNote = "Equal or more than this number.";
                break;
            case 4:
            case this.dropDownID:
                fields.DesiredAnswer.type = "hidden";
                fields.DesiredAnswer.validate = [""];
                break;
            default:
                fields.DesiredAnswer.type = "select";
                fields.DesiredAnswer.validate = [""];
                fields.RankingPoints.type = "text";
                fields.RankingPoints.validate = ["integer_positive"];
                if (!!fields.DesiredAnswer?.metadata?.fieldNote) {
                    fields.DesiredAnswer.metadata.fieldNote = null
                }
        }

        return fields;
    }

    togglePreferredQuestion = (i) => {
        let dropDownOptions = this.state.dropDownOptions;
        dropDownOptions[i].selected = !dropDownOptions[i].selected

        // Update field state
        let fields = this.state.fields;

        const selectedDropdownOptions = dropDownOptions.reduce((memo, it, i) => {
            if (!!it.selected) memo.push(i + 1);
            return memo
        }, []);
        fields.DesiredAnswer.value = selectedDropdownOptions.length && selectedDropdownOptions.join();

        this.setState({
            dropDownOptions,
            fields
        })
    }

    render() {
        const {onClose, show, panelTitle, translate, canSubmit, onSubmit} = this.props;

        return (
            <Transition.Root show={show} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" open={show} onClose={onClose}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0"/>

                        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-2xl">
                                    <div className="h-full flex flex-col pt-6 bg-white shadow-xl overflow-y-scroll">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title
                                                    className="text-lg font-medium text-gray-900">{panelTitle}</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                        onClick={() => onClose()}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 relative flex-1 flex flex-col justify-between">
                                            <div className="px-4 py-2 sm:px-6">
                                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                                    {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, this.getSelects())}

                                                    {this.state.fields.SurveyQuestionTypeID.value == this.questionMatrixID && (
                                                        this.getMatrixQuestions()
                                                    )}

                                                    {this.state.fields.SurveyQuestionTypeID.value == this.dropDownID && (
                                                        this.getDropdownOptions()
                                                    )}

                                                    {this.state.fields.SurveyQuestionTypeID.value == this.rankingQuestionsID && (
                                                        this.getRankingOptions()
                                                    )}
                                                </div>
                                            </div>

                                            <footer
                                                className="mt-auto text-right px-4 py-2 sm:px-6 border-t border-t-secondary-200">
                                                <button
                                                    className="btn btn-outline focus:ring-offset-inverse"
                                                    disabled={!canSubmit}
                                                    onClick={onClose}
                                                >
                                                    {translate("btn.cancel")}
                                                </button>

                                                <button
                                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                                    disabled={!canSubmit}
                                                    onClick={() => onSubmit(this.state.fields, this.getValidFields(), this.getQuestionOptions())}
                                                >
                                                    {translate("btn.save")}
                                                </button>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}
