import React, {Component} from 'react'
import {
    checkPerm,
    fillFieldsFromData,
    getLookup,
    getProp,
    scrollErrorIntoView,
    UPDATE_PERM
} from "../../../../util/util";
import {getDialogResource, updateDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Loader from "../../../../components/loader";
import FieldTextarea from "../../../../components/field-textarea";
import FieldSelectSearch from "../../../../components/field-select-search";
import FieldDropdownSelect from "../../../../components/field-dropdown-select";
import axios from "axios";
import Env from "../../../../util/env";
import {processResponse} from "../../../../data/services/api-util";
import Resources from "../../../../data/services/resources";
import HumanBody from "../../../../components/human-body";
import {getSecondResource} from "../../../../data/actions/secondResource";

export default class QuestionsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    handleSubmitClick = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            const params = Object.assign({}, FieldsManager.getFieldKeyValues(validatedFields), {
                id: this.getId()
            });

            if (!!this.props.onSubmit) {
                this.props.onSubmit(params)
            }  else {
                this.props.dispatch(updateDialogResource({
                    user: LocalStorage.get("user"),
                    query: {id: this.getId()},
                    params: params,
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName()
                }));
            }
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        if (name === "PartOfBodyID") {
            fields.SubPartOfBodyID.value = '';
            fields.SubPlacePartOfBodyID.value = '';
            fields.SubPartOfBodyWhenID.value = '';
            fields.SubPartOfBodyVisualID.value = '';
            fields.SubPartOfBodyPalpateID.value = '';
            fields.SubPartOfBodyPalpateID.value = '';
        }
        if (name === "SubPartOfBodyID") {
            fields.SubPlacePartOfBodyID.value = '';
            fields.SubPartOfBodyWhenID.value = '';
            fields.SubPartOfBodyVisualID.value = '';
            fields.SubPartOfBodyPalpateID.value = '';
        }
        if (name === "SubPlacePartOfBodyID") {
            fields.SubPartOfBodyPalpateID.value = '';
        }
        this.setState({fields: fields, canSubmit: true});
    };

    handleCancelClick = () => {
        this.setState({
            fields: this.getFields(getProp(this.props, "resource.data", {})),
            canSubmit: false
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = () => {
        const item = getProp(this.props, "resource.data", {});

        let SubPartOfBodyID = {}

        let disabled = !!item.AilmentEdit || !checkPerm(Resources.ClientPainAssessmentsQuestions, UPDATE_PERM);

        if (item) {
            let PartOfBody = LocalStorage.get('lookup').PartOfBody.find(it => it.PartOfBodyID == item.PartOfBodyID)
            if (PartOfBody) {
                SubPartOfBodyID = getProp(PartOfBody, "PartOfBodySubItems", []).reduce((memo, it) => {
                    memo[it.PartOfBodySubItemID] = it.PartOfBodySubItem
                    return memo
                }, {})
            }
        }

        const fieldTemplates = {
            ClientAssessmentPainQuestionID: new Field("ClientAssessmentPainQuestionID", '', [''], disabled, '', {
                hideDialog: true
            }),
            PartOfBodyID: new Field("PartOfBodyID", '', ['empty'], disabled, 'select'),
            SubPartOfBodyID: new Field("SubPartOfBodyID", '', ['empty'], disabled, 'select', {}, {
                values: SubPartOfBodyID
            }),
            SubPlacePartOfBodyID: new Field("SubPlacePartOfBodyID", '', [], disabled, 'select-search'),
            SubPartOfBodyWhenID: new Field("SubPartOfBodyWhenID", '', [], disabled, 'select-search'),
            SubPartOfBodyVisualID: new Field("SubPartOfBodyVisualID", '', [], disabled, 'select-search'),
            SubPartOfBodyPalpateID: new Field("SubPartOfBodyPalpateID", '', [], disabled, 'select-search', {
            }, {
                isClearable: true
            }),
            SubPartOfBodyNeurology: new Field("SubPartOfBodyNeurology", '', [], disabled, 'text'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, tabTitle, className} = this.props;
        const {canSubmit} = this.state;

        const disabled = getProp(this.props, "resource.data.AilmentEdit", false);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        let formGroupClass = "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5";
        let labelClass = "block text-sm font-semibold text-secondary-700 sm:mt-px sm:pt-2";

        let item = getProp(this.props, "resource.data", {});

        let SubPartOfBodyID = {}

        let PartOfBody = LocalStorage.get('lookup').PartOfBody.find(it => it.PartOfBodyID == this.state.fields.PartOfBodyID?.value)

        if (item) {
            if (PartOfBody) {
                SubPartOfBodyID = getProp(PartOfBody, "PartOfBodySubItems", []).reduce((memo, it) => {
                    memo[it.PartOfBodySubItemID] = it.PartOfBodySubItem
                    return memo
                }, {})
            }
        }

        return (
            <div className={className}>
                {!isLoading && (
                    <div className="">
                        <div className="flex flex-wrap sticky top-0 bg-white z-10 border-b border-primary-500 pt-1">
                            {tabTitle && (
                                <h2 className="text-xl font-bold text-secondary-600">{tabTitle}</h2>
                            )}

                            <div className="flex-shrink-0 ml-auto mb-3">
                                {!this.props.disableSave && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.handleInputChange("PartOfBodyID", null);
                                        }}
                                        className={"btn focus:ring-offset-inverse mr-3 btn-outline"}
                                    >
                                        {translate("btn.reset")}
                                    </button>
                                )}

                                {!this.props.disableCancel && (
                                    <button
                                        type="button"
                                        onClick={canSubmit ? this.handleCancelClick : null}
                                        className={"btn focus:ring-offset-inverse mr-3" + (canSubmit ? " btn-outline" : " btn-disabled")}
                                    >
                                        {translate("btn.cancel")}
                                    </button>
                                )}

                                {!this.props.disableSave && (
                                    <button
                                        type="button"
                                        onClick={canSubmit ? this.handleSubmitClick : null}
                                        className={"btn focus:ring-offset-inverse" + (canSubmit ? " btn-primary" : " btn-disabled")}
                                    >
                                        {translate("btn.save")}
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className={"grid grid-cols-3 space-y-8 divide-y divide-gray-200 mx-auto -mx-4 sm:-mx-6 px-4 sm:px-6"}>
                            <div className={"human-body-wrap"}>
                                <HumanBody
                                    onSelection={(val) => {
                                        this.handleInputChange("PartOfBodyID", val);
                                    }}
                                    onSubSelection={(val) => {
                                        this.handleInputChange("SubPartOfBodyID", val);
                                    }}
                                    value={this.state.fields?.PartOfBodyID?.value}
                                    subvalue={this.state.fields?.SubPartOfBodyID?.value}
                                    subpieces={getProp(PartOfBody, "PartOfBodySubItems", [])}
                                    disabled={disabled}
                                />
                            </div>
                            <form key={this.props.bodyKey} className={"col-span-2 space-y-8 divide-y divide-gray-200 max-h-[calc(100vh-1rem)] overflow-y-auto mx-6 px-4"}>
                                <div
                                    className={formGroupClass}>
                                    <label
                                        className={labelClass}>
                                        {translate("field.PartOfBodyID")}*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <FieldSelectSearch
                                            className="form-react-select h-9"
                                            values={getLookup('PartOfBody')}
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.PartOfBodyID}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={formGroupClass}>
                                    <label
                                           className={labelClass}>
                                        {translate("field.SubPartOfBodyID")}*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <FieldSelectSearch
                                            className="form-react-select h-9"
                                            values={SubPartOfBodyID}
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SubPartOfBodyID}
                                        />
                                    </div>
                                </div>

                                {this.state.fields?.SubPartOfBodyID?.value && (
                                    <div
                                        className={formGroupClass}>
                                        <label
                                               className={labelClass}>
                                            {translate("field.SubPlacePartOfBodyID")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <FieldDropdownSelect
                                                setKey={this.state.fields.SubPartOfBodyID.value}
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.SubPlacePartOfBodyID}
                                                className="form-react-select h-9 w-56"
                                                defaultOptions={true}
                                                loadOptions={
                                                    (inputValue, callback) => {
                                                        axios.get(
                                                            Env.getApiUrl("api/" + Resources.LookupSubPart, {id: this.state.fields.SubPartOfBodyID.value}),
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + LocalStorage.get('user')?.access_token
                                                                }
                                                            }
                                                        )
                                                            .then((response) => {
                                                                const result = processResponse(response);
                                                                if (result && result.status === 0) {
                                                                    const list = result.data.SubPlacePartOfBody.map((it) => {
                                                                        return {
                                                                            label: it.SubPlacePartOfBody,
                                                                            value: it.SubPlacePartOfBodyID
                                                                        };
                                                                    });
                                                                    callback(list);
                                                                }
                                                            })
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {this.state.fields?.SubPartOfBodyID?.value && (
                                    <div className={formGroupClass}>
                                        <label className={labelClass}>
                                            {translate("field.SubPartOfBodyWhenID")}
                                        </label>
                                        <FieldDropdownSelect
                                            setKey={this.state.fields.SubPartOfBodyID.value}
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SubPartOfBodyWhenID}
                                            className="form-react-select h-9 w-56"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.LookupSubPart, {id: this.state.fields.SubPartOfBodyID.value}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user')?.access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.SubPartOfBodyWhen.map((it) => {
                                                                    return {
                                                                        label: it.SubPartOfBodyWhen,
                                                                        value: it.SubPartOfBodyWhenID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </div>
                                )}

                                {this.state.fields?.SubPartOfBodyID?.value && (
                                    <div className={formGroupClass}>
                                        <label className={labelClass}>
                                            {translate("field.SubPartOfBodyVisualID")}
                                        </label>
                                        <FieldDropdownSelect
                                            setKey={this.state.fields.SubPartOfBodyID.value}
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SubPartOfBodyVisualID}
                                            className="form-react-select h-9 w-56"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.LookupSubPart, {id: this.state.fields.SubPartOfBodyID.value}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user')?.access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.SubPartOfBodyVisual.map((it) => {
                                                                    return {
                                                                        label: it.SubPartOfBodyVisual,
                                                                        value: it.SubPartOfBodyVisualID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </div>
                                )}

                                {this.state.fields?.SubPlacePartOfBodyID?.value && (
                                    <div className={formGroupClass}>
                                        <label className={labelClass}>
                                            {translate("field.SubPartOfBodyPalpateID")}
                                        </label>
                                        <FieldDropdownSelect
                                            setKey={this.state.fields.SubPlacePartOfBodyID.value}
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SubPartOfBodyPalpateID}
                                            className="form-react-select h-9 w-56"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.LookupSubPart, {id: this.state.fields.SubPlacePartOfBodyID?.value?.value}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + LocalStorage.get('user')?.access_token
                                                            }
                                                        }
                                                    )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.SubPartOfBodyPalpate.map((it) => {
                                                                return {
                                                                    label: it.SubPartOfBodyPalpate,
                                                                    value: it.SubPartOfBodyPalpateID
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                                }
                                            }
                                        />
                                    </div>
                                )}

                                <div
                                    className={formGroupClass}>
                                    <label
                                        className={labelClass}>
                                        {translate("field.SubPartOfBodyNeurology")}
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <FieldTextarea
                                            className="form-control h-28"
                                            onChange={this.handleInputChange}
                                            addClass={"form-control"}
                                            {...this.state.fields.SubPartOfBodyNeurology}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {!!isLoading && (
                    <div className="text-center">
                        <Loader/>
                    </div>
                )}
            </div>
        )
    }
}
