import React, {useEffect, useState} from 'react'
import {EyeIcon, PencilIcon, RefreshIcon} from "@heroicons/react/solid";
import Pagination from "../pagination"
import Loader from "../loader";
import {ArchiveIcon} from "@heroicons/react/outline";
import Table from "./table"
import TableMobile from "./table-mobile"


export default function SimpleTable({
                                        fields,
                                        data,
                                        onRowClick,
                                        onView,
                                        onEdit,
                                        onDelete,
                                        onRestore,
                                        onCustomAction,
                                        onOffsetChange,
                                        onSortChange,
                                        sort,
                                        sortBy,
                                        onUpdateColumnFilter,
                                        translate,
                                        limit,
                                        offset,
                                        count,
                                        paginationPage,
                                        isLoading,
                                        disablePagination,
                                        hideCaption
                                    }) {

    const [isMobileView, setIsMobileView] = useState(undefined);

    useEffect(() => {
        const smallScreenBreakpoint = 768;

        const watchWindowSize = () => {

            if (window.innerWidth >= smallScreenBreakpoint) {
                if (isMobileView) {
                    setIsMobileView(false);
                }
            }

            if (window.innerWidth < smallScreenBreakpoint) {
                if (!isMobileView) {
                    setIsMobileView(true);
                }
            }
        }

        window.addEventListener('resize', watchWindowSize);

        if (isMobileView === undefined) {
            watchWindowSize();
        }

        return () => window.removeEventListener('resize', watchWindowSize);
    }, [isMobileView])


    const haveActions = (!!onEdit || !!onView || !!onDelete || !!onCustomAction);

    const getActions = (it) => {
        return (
            <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                {!!onCustomAction && onCustomAction(it)}
                {onView && (
                    <button
                        title="View"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onView(it);
                        }}
                        className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-primary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <EyeIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                 aria-hidden="true"/>
                        <span className="hidden md:inline text-secondary-400">View</span>
                    </button>
                )}
                {onEdit && (
                    <button
                        title="Edit"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onEdit(it);
                        }}
                        className="ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-primary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                    aria-hidden="true"/>

                        <span className="hidden md:inline text-secondary-400">Edit</span>
                    </button>
                )}
                {onDelete && !it.ArchivedDate && (
                    <button
                        title="Delete"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onDelete(it);
                        }}
                        className="ml-2 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-primary-700 bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"
                    >
                        <ArchiveIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                     aria-hidden="true"/>

                        <span className="hidden md:inline text-secondary-400">Delete</span>
                    </button>
                )}

                {onRestore && !!it.ArchivedDate && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onRestore(it);
                        }}
                        className="ml-2 inline-flex items-center px-4 py-2 border border-primary-300 rounded-md shadow-sm text-sm font-medium text-primary-700 hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <RefreshIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" aria-hidden="true"/>
                        Restore
                    </button>
                )}
            </td>
        )
    };

    return (
        <>
            <div className="">
                <div className="mx-auto">
                    <div className="flex flex-col">
                        <div
                            className={"align-middle min-w-full overflow-x-auto md:shadow overflow-hidden md:rounded-lg relative"}>

                            {!isMobileView && (
                                <Table
                                    onSortChange={onSortChange}
                                    sort={sort}
                                    data={data}
                                    sortBy={sortBy}
                                    onUpdateColumnFilter={onUpdateColumnFilter}
                                    haveActions={haveActions}
                                    onView={onView}
                                    onEdit={onEdit}
                                    onRowClick={onRowClick}
                                    getActions={getActions}
                                    isLoading={isLoading}
                                    fields={fields}
                                    translate={translate}
                                />
                            )}

                            {!!isMobileView && (
                                <TableMobile
                                    fields={fields}
                                    data={data}
                                    haveActions={haveActions}
                                    getActions={getActions}
                                    isLoading={isLoading}
                                    translate={translate}
                                />
                            )}

                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}

                            {/* Pagination */}
                            {!disablePagination && (
                                <Pagination
                                    className="md:sticky left-0 rounded-md md:rounded-none"
                                    limit={limit}
                                    offset={offset}
                                    count={count}
                                    paginationPage={paginationPage}
                                    updateOffset={onOffsetChange}
                                    hideCaption={hideCaption}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
