import React, {Component} from 'react';
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {CloudIcon, TemplateIcon} from "@heroicons/react/solid";
import Resources from "../../data/services/resources";
import {Field} from "../../data/services/fields";
import ResourceListTab from "../../components/resource-list-tab";
import HumanBody from "../../components/human-body";
import HumanBodyEdit from "../../components/human-body/edit";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class LookupAilmentsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subNavigation:[
                {
                    name: 'Part of Body',
                    description: 'Set Main Parts of the Body',
                    href: '#',
                    icon: TemplateIcon
                },
                {
                    name: 'Sub Part of Body',
                    description: 'Set Sub Parts of the Body, depending of the part of the Body',
                    href: '#',
                    icon: TemplateIcon
                },

                {
                    name: 'Sub Part of Body - Visual',
                    description: 'Set Visual Part of the Body, depending of the sub part of the body',
                    href: '#',
                    icon: TemplateIcon
                },
                {
                    name: 'Sub Part of Body - When',
                    description: 'Set When Part of the Body, depending of the sub part of the body',
                    href: '#',
                    icon: TemplateIcon
                },
                {
                    name: 'Sub Part of Body - Place',
                    description: 'Set Place Part of the Body, depending of the sub part of the body\'',
                    href: '#',
                    icon: TemplateIcon
                },
                {
                    name: 'Sub Part of Body - Palpate',
                    description: 'Set Palpate Part of the Body, depending of the Place sub part of the body\'',
                    href: '#',
                    icon: TemplateIcon
                },

            ],
            selectedTab: 'Part of Body'
        };
    }

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <LayoutDashboard footerBorder={true} {...this.props}>
                <main className="flex flex-col md:flex-row custom-min-h-page-2">
                    {/* Secondary sidebar */}
                    <nav
                        aria-label="Sections"
                        className="flex-shrink-0 md:w-96 bg-white border-r border-primary-gray-200 xl:flex xl:flex-col page-content-height"
                    >
                        <div className="flex-shrink-0 h-16 px-6 border-b border-primary-gray-200 flex items-center">
                            <p className="text-lg font-medium text-primary-gray-900">Settings - Ailments</p>
                        </div>
                        <div className="flex-1 min-h-0 overflow-y-auto">
                            {this.state.subNavigation.map((item, i) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        (this.state.selectedTab === item.name) ? 'bg-primary-50 bg-opacity-50' : 'hover:bg-primary-50 hover:bg-opacity-50',
                                        'flex p-6 border-b border-gray-200'
                                    )}
                                    aria-current={(this.state.selectedTab === item.name) ? 'page' : undefined}
                                    onClick={() => {
                                        this.setState({
                                            selectedTab: item.name
                                        })
                                    }}
                                >
                                    <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400"
                                               aria-hidden="true"/>
                                    <div className="ml-3 text-sm">
                                        <p className="font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-gray-500">{item.description}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </nav>

                    <div className="flex-1 xl:overflow-y-auto">
                        <div className="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                            {(this.state.selectedTab === ('Part of Body')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"PartOfBody"}
                                        primaryKey={"PartOfBodyID"}
                                        resourceName={Resources.LookupPartOfBody}
                                        disableCreate={true}
                                        disableDefaultEdit={true}
                                        disableDefaultDelete={true}
                                        fields={{
                                            PartOfBody: new Field("PartOfBody", '', ['empty'], false, 'text')
                                        }}
                                    />
                                </>
                            )}
                            {(this.state.selectedTab === ('Sub Part of Body')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"SubPartOfBody"}
                                        primaryKey={"SubPartOfBodyID"}
                                        resourceName={Resources.LookupSubPartOfBody}
                                        searchFields={{
                                            PartOfBodyID: new Field("PartOfBodyID", '', [], false, 'select-search')
                                        }}
                                        metadataSearch={{
                                            PartOfBodyID: {
                                                api: 'api/' + Resources.LookupPartOfBody,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.PartOfBodyID,
                                                    label: item.PartOfBody
                                                })
                                            }
                                        }}
                                        fields={{
                                            PartOfBodyID: new Field("PartOfBodyID", '', ['empty'], false, 'select-search'),
                                            SubPartOfBody: new Field("SubPartOfBody", '', ['empty'], false, 'text'),

                                            HumanBody: new Field("HumanBody", '', ['empty'], false, 'custom', {
                                                hideTable: true
                                            }),
                                        }}
                                        metadata={{
                                            PartOfBodyID: {
                                                api: 'api/' + Resources.LookupPartOfBody,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.PartOfBodyID,
                                                    label: item.PartOfBody
                                                })
                                            },
                                            HumanBody: (fieldsCpy, handleInputChange) => {
                                                const human = fieldsCpy[2].value;

                                                return (
                                                    <HumanBodyEdit
                                                        onSelection={(val) => {
                                                            handleInputChange("PartOfBodyID", val)
                                                        }}
                                                        value={fieldsCpy[0]?.value?.value}
                                                        editMode={true}
                                                        disabled={false}
                                                        enableEdit={true}
                                                        X={human?.X}
                                                        Y={human?.Y}
                                                        R={human?.R}
                                                        Side={human?.Side}
                                                        onEdit={(value) => {
                                                            handleInputChange("HumanBody", value)
                                                        }}
                                                    />
                                                )
                                            }
                                        }}
                                    />
                                </>
                            )}

                            {(this.state.selectedTab === ('Sub Part of Body - Visual')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"SubPartOfBodyVisual"}
                                        primaryKey={"SubPartOfBodyVisualID"}
                                        resourceName={Resources.LookupSubPartOfBodyVisual}
                                        fields={{
                                            SubPartOfBodyID: new Field("SubPartOfBodyID", '', [''], false, 'select-search'),
                                            SubPartOfBodyVisual: new Field("SubPartOfBodyVisual", '', ['empty'], false, 'text'),
                                        }}
                                        metadata={{
                                            SubPartOfBodyID: {
                                                api: 'api/' + Resources.LookupSubPartOfBody,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.SubPartOfBodyID,
                                                    label: item.SubPartOfBody
                                                })
                                            },
                                        }}
                                    />
                                </>
                            )}
                            {(this.state.selectedTab === ('Sub Part of Body - When')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"SubPartOfBodyWhen"}
                                        primaryKey={"SubPartOfBodyWhenID"}
                                        resourceName={Resources.LookupSubPartOfBodyWhen}
                                        fields={{
                                            SubPartOfBodyID: new Field("SubPartOfBodyID", '', [''], false, 'select-search'),
                                            SubPartOfBodyWhen: new Field("SubPartOfBodyWhen", '', ['empty'], false, 'text'),
                                        }}
                                        metadata={{
                                            SubPartOfBodyID: {
                                                api: 'api/' + Resources.LookupSubPartOfBody,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.SubPartOfBodyID,
                                                    label: item.SubPartOfBody
                                                })
                                            },
                                        }}
                                    />
                                </>
                            )}
                            {(this.state.selectedTab === ('Sub Part of Body - Place')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"SubPlacePartOfBody"}
                                        primaryKey={"SubPlacePartOfBodyID"}
                                        resourceName={Resources.LookupSubPartOfBodyPlace}
                                        fields={{
                                            SubPartOfBodyID: new Field("SubPartOfBodyID", '', [''], false, 'select-search'),
                                            SubPlacePartOfBody: new Field("SubPlacePartOfBody", '', ['empty'], false, 'text'),
                                        }}
                                        metadata={{
                                            SubPartOfBodyID: {
                                                api: 'api/' + Resources.LookupSubPartOfBody,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.SubPartOfBodyID,
                                                    label: item.SubPartOfBody
                                                })
                                            },
                                        }}
                                    />
                                </>
                            )}

                            {(this.state.selectedTab === ('Sub Part of Body - Palpate')) && (
                                <>
                                    <h3 className={"text-2xl mb-4"}>{this.state.selectedTab}</h3>
                                    <ResourceListTab
                                        id={this.getId()}
                                        dispatch={this.props.dispatch}
                                        translate={translate}
                                        resource={this.props.resource}
                                        dialogTitle={this.state.selectedTab}
                                        sortBy={"SubPartOfBodyPalpate"}
                                        primaryKey={"SubPartOfBodyPalpateID"}
                                        resourceName={Resources.LookupSubPartOfBodyPalpate}
                                        fields={{
                                            SubPlacePartOfBodyID: new Field("SubPlacePartOfBodyID", '', [''], false, 'select-search', {
                                                joinLabels: [
                                                    {
                                                        fieldName: "SubPlacePartOfBody",
                                                        prefix: "",
                                                        suffix: "",
                                                    },
                                                    {
                                                        fieldName: "SubPartOfBody",
                                                        prefix: " (",
                                                        suffix: ")",
                                                    }
                                                ]
                                            }),
                                            SubPartOfBodyPalpate: new Field("SubPartOfBodyPalpate", '', ['empty'], false, 'text'),
                                        }}
                                        metadata={{
                                            SubPlacePartOfBodyID: {
                                                api: 'api/' + Resources.LookupSubPartOfBodyPlace,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.SubPlacePartOfBodyID,
                                                    label: item.SubPlacePartOfBody + " (" + item.SubPartOfBody + ")"
                                                })
                                            },
                                        }}
                                    />
                                </>
                            )}

                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(LookupAilmentsPage);