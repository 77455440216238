import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {classNames, getProp, getUserLabel, setDocumentTitle} from "../../util/util";
import Notification from "../notification";
import {hideNotification} from "../../data/actions/ui";
import moment from "moment";
import {Menu, Transition} from "@headlessui/react";
import Logout from "../logout";
import LocalStorage from "../../util/localStorage";
import {logoutClear} from "../../data/actions/user";

export default class WelcomeLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== this.props.location.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }
    }

    render() {
        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg = it.message;
            // eslint-disable-next-line default-case
            switch (it.message) {
                case 'CREATE_RESOURCE':
                case 'CREATE_DIALOG_RESOURCE':
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                case 'UPDATE_DIALOG_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                case 'DELETE_DIALOG_RESOURCE':
                    msg = "Entry deleted";
                    break;
            }
            return (
                <Notification
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    title={it.notificationTitle ?? "Notification"}
                    message={msg}
                />
            )
        });

        const data = getProp(this.props.resource, "data", []);
        let logoLink = "/client-profile"

        if (!data.Registered) {
            logoLink = "/welcome"
        }

        const userMenuBtnLabel = getUserLabel();

        let userNavigation = [
        ];

        return (
            <React.Fragment>
                <div className="bg-secondary-100 min-h-full text-default">
                    <div className="relative z-10 flex-shrink-0 flex h-16 bg-secondary-100 border-b-2 border-primary-600">

                        <div className="flex-1 px-4 sm:px-6 lg:px-8 flex justify-between">

                            <div className="sm:mr-4 flex items-center md:ml-6 lg:w-60 justify-start">
                                <div className="w-32 sm:w-48 rounded-xl overflow-hidden text-base sm:text-3xl">
                                    <Link to={logoLink}>
                                        <img src="/images/logos/zenden-logo-black.png" alt="ZenDen"/>
                                    </Link>
                                </div>
                            </div>

                            <div className="flex">
                                <nav
                                    className="hidden justify-center lg:pt-6 lg:flex lg:space-x-8"
                                    aria-label="Global"
                                >
                                    {!!this.props.navigation && this.props.navigation}
                                </nav>
                            </div>

                            <div className="flex-1 flex justify-center">
                                <div className="sm:ml-4 flex items-center md:ml-6 lg:w-60 justify-end">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative dark">
                                        {({open}) => (
                                            <>
                                                <div>
                                                    <Menu.Button
                                                        className="max-w-xs bg-transparent flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ring-offset-secondary-100 text-secondary-900">
                                                        <span className="sr-only">Open user menu</span>
                                                        <div
                                                            className="w-10 h-10 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                                            <div
                                                                className="text-inverse font-black">{userMenuBtnLabel.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                                        </div>

                                                        <span className="select-none">
                                                            {userMenuBtnLabel}
                                                        </span>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                             viewBox="0 0 10 6" className="ml-2">
                                                            <path fill="currentColor"
                                                                  d="M8.292893.292893c.390525-.390524 1.023689-.390524 1.414214 0 .390524.390525.390524 1.023689 0 1.414214l-4 4c-.390525.390524-1.023689.390524-1.414214 0l-4-4c-.390524-.390525-.390524-1.023689 0-1.414214.390525-.390524 1.023689-.390524 1.414214 0L5 3.585786 8.292893.292893z"/>
                                                        </svg>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="theme-dark-popup origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-inverse ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        {userNavigation.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({active}) => {
                                                                    if (item.action) {
                                                                        return (
                                                                            <div
                                                                                onClick={item.action}
                                                                                className={classNames(
                                                                                    active ? 'bg-secondary-100' : '',
                                                                                    'cursor-pointer block px-4 py-2 text-sm text-secondary-700'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Link
                                                                            to={item.href}
                                                                            className={classNames(
                                                                                active ? 'bg-secondary-100' : '',
                                                                                'block px-4 py-2 text-sm text-secondary-700'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    );
                                                                }}
                                                            </Menu.Item>
                                                        ))}
                                                        <Menu.Item key={"logout"}>
                                                            {({active}) => (
                                                                <Logout
                                                                    className={classNames(
                                                                        active ? 'bg-secondary-100' : '',
                                                                        'block w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-100'
                                                                    )}
                                                                    history={this.props.history}
                                                                    translate={this.props.translate}
                                                                    dispatch={this.props.dispatch}/>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        {this.props.children}
                    </div>
                    <div className="text-secondary-900 py-3 text-center text-sm px-4">
                        <div
                            className="border-t pt-5"
                        >
                            <p className="mb-3">©{moment().year()} ZenDen | Developed in partnership with, LUKASA, a
                                modernization business technology consulting firm. </p>

                            <p>
                                All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}