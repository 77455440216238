import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {checkPerm, fillFieldsFromData, getProp, READ_PERM, UPDATE_PERM} from "../../../util/util";
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import DialogDefault from "../../../components/dialog-default";
import {Field, FieldsManager} from "../../../data/services/fields";
import ResourceFormTab from "../../../components/resource-form-tab";
import ProgressBar from "../../../components/progress-bar";
import QuestionsTab from './questions-tab'
import {CheckCircleIcon, QuestionMarkCircleIcon} from "@heroicons/react/outline";

export default class PainAssessmentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [],
            submitConfirmModal: false,
            confirmModalOpen: false,
            metadata: {}
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dialogResource !== prevProps.dialogResource && this.props.dialogResource.data && !this.props.dialogResource.isLoading) {
            let item = getProp(this.props, "dialogResource.data", {});

            const tabs = [
                {name: 'Info', resource: Resources.ClientAssessmentsInfo, isDisabled: !!item.AilmentEdit, isDone: !!item.PartOfBodyID || !!item.QuestionEdit, onClick: (resource) => this.handleTabChange(resource)},
                {name: 'Questions', resource: Resources.ClientPainAssessmentsQuestions, isDisabled: !!item.AilmentEdit, isDone: !!item.QuestionEdit, onClick: (resource) => this.handleTabChange(resource)},
                {name: 'Ailment', resource: Resources.ClientPainAssessmentsAilments, isDisabled: !item.QuestionEdit, isDone: !!item.AilmentEdit, onClick: (resource) => this.handleTabChange(resource)},
            ]

            this.setState({tabs})
        }

        if (!prevProps.visible && this.props.visible) {
            this.setState({
                selectedTab: null
            }, () => {
                // this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.ClientAssessmentsInfo)
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: {
                ClientAssessmentID: this.getId()
            },
            resource: Resources.ClientAssessmentsInfo
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Data events
     ================================================================= */
    handleInputChange = (fields, name, value) => {
        let fieldsTmp = FieldsManager.updateField(fields, name, value)
        if ("AssessmentTypeID" === name) {
            this.setState({
                submitConfirmModal: true
            })
        }

        return fieldsTmp;
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getInfoFields = () => {
        let item = getProp(this.props, "dialogResource.data", {});

        const fieldTemplates = {
            AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], true, 'select'),
            AssessmentDate: new Field("AssessmentDate", '', [''], true, 'date'),
            ProviderName: new Field("ProviderName", '', [''], true, 'text'),
            Summary: new Field("Summary", '', [''], !!item.AilmentEdit || !checkPerm(Resources.ClientAssessmentsInfo, UPDATE_PERM), 'textarea'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    }

    getAilmentsFields = () => {
        let item = getProp(this.props, "dialogResource.data", {});

        let disabled = !!item.UpdatedByContactID || !checkPerm(Resources.ClientPainAssessmentsAilments, UPDATE_PERM);

        const fieldTemplates = {
            SuggestedAilments: new Field("SuggestedAilments", '', [''], disabled, 'custom'),
            AilmentIDs: new Field("AilmentIDs", '', [''], disabled, 'multi-select-search', {}, {
                isMulti: true
            }),
            Summary: new Field("Summary", '', [''], disabled, 'textarea'),
            DiagnoseDate: new Field("DiagnoseDate", '', [''], true, 'datetime'),
            ProviderName: new Field("ProviderName", '', [''], true, 'readonly'),
            ClientAssessmentPainAilmentID: new Field("ClientAssessmentPainAilmentID", '', [''], true, 'hidden'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        return (
            <DialogDefault
                title={translate("modal.title.pain_assessment")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)]"
                visible={!!visible}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>

                    <ProgressBar
                        tabs={this.state.tabs}
                    />

                    {/* Content */}
                    <div className="mt-10">
                        {this.state.selectedTab === Resources.ClientAssessmentsInfo && (
                            <ResourceFormTab
                                tabTitle={"Info"}
                                id={this.getId()}
                                className="mx-auto"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentID"}
                                handleInputChange={this.handleInputChange}
                                fields={this.getInfoFields()}
                                metadata={{
                                }}
                                disableSave={this.state.tabs[0]?.isDisabled || !checkPerm(Resources.ClientAssessmentsInfo, UPDATE_PERM)}
                                disableCancel={this.state.tabs[0]?.isDisabled || !checkPerm(Resources.ClientAssessmentsInfo, UPDATE_PERM)}
                            />
                        )}

                        {this.state.selectedTab === Resources.ClientPainAssessmentsQuestions && (
                            <QuestionsTab
                                tabTitle={"Questions"}
                                id={this.getId()}
                                formClassName="grid grid-cols-1 gap-4"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentPainQuestionID"}
                                handleInputChange={this.handleInputChange}
                                metadata={this.state.metadata}
                                disableSave={this.state.tabs[1]?.isDisabled || !checkPerm(Resources.ClientPainAssessmentsQuestions, UPDATE_PERM)}
                                disableCancel={this.state.tabs[1]?.isDisabled || !checkPerm(Resources.ClientPainAssessmentsQuestions, UPDATE_PERM)}
                            />
                        )}

                        {this.state.selectedTab === Resources.ClientPainAssessmentsAilments && (
                            <ResourceFormTab
                                tabTitle={"Ailment"}
                                id={this.getId()}
                                className="mx-auto"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentPainAilmentID"}
                                fields={this.getAilmentsFields()}
                                metadata={{
                                    AilmentIDs: {
                                        api: 'api/' + Resources.Ailments,
                                        query: {
                                            searchFields: JSON.stringify({PartOfBodyID: getProp(this.props, "dialogResource.data.PartOfBodyID", 0)})
                                        },
                                        searchMap: (item) => ({
                                            value: item.AilmentID,
                                            label: item.Ailment
                                        })
                                    },
                                    SuggestedAilments: (fields, handleInputChange) => {
                                        let SuggestedAilments = fields.find(it => it.name === "SuggestedAilments")
                                        let Ailments = fields.find(it => it.name === "AilmentIDs")

                                        const keywords = Array.isArray(SuggestedAilments.value) && getProp(SuggestedAilments, "value", []).map((item) => {
                                            let isSelected = false
                                            if (Ailments.value.some(it => it.value == item.AilmentID)) isSelected = true

                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (!getProp(this.props, "dialogResource.data.UpdatedByContactID", 0)) {
                                                            if (isSelected) {
                                                                Ailments.value = Ailments.value.filter(it => it.value != item.AilmentID)
                                                            } else {
                                                                Ailments.value = [...Ailments.value, {
                                                                    value: item.AilmentID,
                                                                    label: item.Ailment,
                                                                }]
                                                            }
                                                            handleInputChange("AilmentIDs", Ailments.value);
                                                        }
                                                    }}
                                                    className={"hover:cursor-pointer border-2 border-secondary-300 flex"}>
                                                    {isSelected && (
                                                        <span>
                                                           <CheckCircleIcon className="text-green-400 w-7 h-7 mt-3 inline-block ml-2 mr-2"
                                                                        aria-hidden="true"/>
                                                        </span>
                                                    )}
                                                    {!isSelected && (
                                                        <span>
                                                           <QuestionMarkCircleIcon className="text-secondary-400 mt-3 w-7 h-7 inline-block ml-2 mr-2"
                                                                            aria-hidden="true"/>
                                                        </span>
                                                    )}
                                                    <span className="flex-1 text-lg pl-2 pr-2 pt-3 pb-2 border-r-2 border-secondary-300 font-bold">
                                                        {item.Ailment}
                                                    </span>
                                                    <span className=" flex-0 text-lg pl-2 pr-2 pt-1 pb-2 border-r-2 border-secondary-300">
                                                        <img className="inline-block h-10 w-20 object-cover" src={"/images/gauge/gauge-" + item.MatchGaugeID + ".svg"} title={item.Match + " %"}/>
                                                    </span>
                                                    <span className=" flex-1 text-sm pl-2 pr-2 pt-1 pb-2">
                                                        {item.MatchDesc}
                                                    </span>
                                                </div>
                                            )
                                        })
                                        return (
                                            <div className={'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5'}>
                                                <label
                                                       className="block text-sm font-semibold text-secondary-700 sm:mt-px sm:pt-2">
                                                    {translate("field.SuggestedAilments")}
                                                </label>
                                                <div className={'sm:col-span-2'}>{keywords}</div>
                                            </div>
                                        )
                                    }
                                }}
                                onSubmit={(params) => {
                                    params.AilmentIDs = params.AilmentIDs.join(', ')
                                    this.props.dispatch(updateDialogResource({
                                        user: LocalStorage.get("user"),
                                        query: {id: this.getId()},
                                        params: params,
                                        resource: Resources.ClientPainAssessmentsAilments,
                                        piggyResource: Resources.ClientPainAssessmentsAilments
                                    }));
                                }}
                                disableSave={this.state.tabs[2]?.isDisabled || getProp(this.props, "dialogResource.data.UpdatedByContactID", 0) || !checkPerm(Resources.ClientPainAssessmentsAilments, UPDATE_PERM)}
                                disableCancel={this.state.tabs[2]?.isDisabled || getProp(this.props, "dialogResource.data.UpdatedByContactID", 0) || !checkPerm(Resources.ClientPainAssessmentsAilments, UPDATE_PERM)}
                            />
                        )}
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}
