import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {
    createDialogResource,
    deleteDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import DialogDefault from "../../../components/dialog-default";
import DialogResourceListTab from "../../../components/resource-dialog-list-tab";
import {Field} from "../../../data/services/fields";

export default class AppointmentNotesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /** Lifecycle
     ================================================================= */

    /** Data Events
     ================================================================= */
    handleUpdateResource = (params, query) => {
        params.ClientID = this.getClientID();
        params.ClientAppointmentID = this.getClientAppointmentID();
        delete params.ProviderName
        delete params.AssessmentDate
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get("user"),
            query: query,
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    handleCreateResource = (params, query) => {
        params.ClientID = this.getClientID();
        params.ClientAppointmentID = this.getClientAppointmentID();
        delete params.ProviderName
        delete params.AssessmentDate
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get("user"),
            query: query,
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    handleDeleteResource = (item, query) => {
        this.props.dispatch(deleteDialogResource({
            user: LocalStorage.get("user"),
            query: {
                ClientAssessmentID: item.ClientAssessmentID
            },
            piggyQuery: query,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    /** UI Events
     ================================================================= */

    /** Helpers
     ================================================================= */
    getClientID = () => {
        return this.props.item?.ClientID;
    };

    getClientAppointmentID = () => {
        return this.props.item?.ClientAppointmentID;
    };

    getResourceName = () => {
        return this.props.resourceName ?? Resources.ClientAppointmentNotes
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        return (
            <DialogDefault
                title={translate("modal.title.appointment_notes")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)]"
                visible={!!visible}
                disableOverflow={true}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>
                    <div className="">
                        {/* Content */}
                        <div className="mt-10">
                            <DialogResourceListTab
                                query={{
                                    ClientID: this.getClientID(),
                                    ClientAppointmentID: this.getClientAppointmentID()
                                }}
                                dispatch={this.props.dispatch}
                                translate={translate}
                                dialogResource={this.props.dialogResource}

                                dialogCreateTitle={translate("modal.title.create_appointment_notes")}
                                dialogEditTitle={translate("modal.title.update_appointment_notes")}

                                sortBy={"NoteTitle"}
                                primaryKey={"ClientNoteID"}

                                resourceName={this.getResourceName()}
                                getFields={(item) => {
                                    return {
                                        NoteTitle: new Field("NoteTitle", '', ['empty'], false, 'text'),
                                        Description: new Field("Description", '', ['empty'], false, 'textarea'),
                                        IsVisibleToClient: new Field("IsVisibleToClient", '', [''], false, 'checkbox')
                                    }
                                }}
                                onUpdateResource={this.handleUpdateResource}
                                onCreateResource={this.handleCreateResource}
                                onDeleteResource={this.handleDeleteResource}
                            />
                        </div>
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}
