import React, {Component} from "react";
import PublicLayout from "../../components/layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPassword, resetUserMessage} from "../../data/actions/user";
import FieldText from "../../components/field-text";
import Button from "../../components/button";
import Loader from "../../components/loader";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                Email: new Field('Email', '', ['email', 'empty'])
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            this.submit();
        }
    }

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.Email.errorMessage) {
                this.props.dispatch(resetPassword({
                    username: this.state.fields.Email.value
                }))
            }
        })
    };

    resetUserMessage = () => {
        this.props.dispatch(resetUserMessage());
    }

    render() {

        return (
            <PublicLayout {...this.props}>
                <div className="pt-24 px-14 pb-7">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg p-8 max-w-sm mx-auto">
                        <div className="p-8 text-center text-4xl text-primary-500">
                            <img className="mx-auto w-56" src="/images/logos/zenden-logo-black.png" alt="ZenDen"/>
                        </div>

                        {!this.props.user.isLoading && (this.props.user.reset !== true) && (
                            <React.Fragment>
                                <p className="text-center mb-8 text-secondary-500 text-sm">
                                    Enter your email to continue.
                                    <br/>
                                    Email needs to be valid and used before.
                                </p>

                                <div className="mb-6 ">
                                    <label className="block font-bold mb-2 text-secondary-900" htmlFor="email">Email
                                        Address</label>
                                    <FieldText
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                        handleKeyDown={this.handleEnterPress}
                                        {...this.state.fields.Email}
                                        placeholder={"email@domain.com"}
                                        type={"email"}
                                        errorLabelMessage={"Email required"}
                                    />
                                </div>

                                <div className="">
                                    <Button
                                        className="w-full cursor-pointer justify-center"
                                        type="primary"
                                        onClick={this.submit}>
                                        Reset password
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}

                        {this.props.user.isLoading && (
                            <React.Fragment>
                                <div className={"text-center mt-5 mb-5"}>
                                    <Loader/>
                                </div>
                            </React.Fragment>
                        )}

                        {this.props.user.error && (
                            <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-100 text-red-700"
                                 onClick={() => this.resetUserMessage()}>
                                <svg className="text-red-700 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                          clipRule="evenodd"/>
                                </svg>

                                {this.props.translate(this.props.user.errorMessage)}
                            </div>
                        )}

                        {this.props.user.reset && (
                            <div className="px-6 py-3 mt-6 flex rounded-btn bg-green-100 text-green-700"
                                 onClick={() => this.resetUserMessage()}>
                                <svg className="text-green-700 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                          clipRule="evenodd"/>
                                </svg>

                                {this.props.translate("text.reset_email_sent")}
                            </div>
                        )}
                    </div>

                    <div className="text-secondary-600 mt-6 text-center">
                        Sign in with another account? <Link
                        className="no-underline border-b border-primary text-primary-700 hover:opacity-70"
                        to={"/login"}>Login</Link>.
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);