import {Switch} from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ArchivedSwitch({translate, value, onChange, classNameContainer}) {
    classNameContainer = classNameContainer ? " " + classNameContainer : "";

    return (
        <Switch.Group as="div" className={"flex items-center cursor-pointer select-none " + classNameContainer}>
            <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-900 mr-2">{translate("field.show_archived")}</span>
            </Switch.Label>

            <Switch
                checked={!!value}
                onChange={onChange}
                className={classNames(
                    !!value ? 'bg-primary-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                )}
            >
                <span className="sr-only">{translate("field.show_archived")}</span>
                <span
                    aria-hidden="true"
                    className={classNames(
                        !!value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
        </Switch.Group>
    )
}