import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    CREATE_PERM, DELETE_PERM,
    fillFieldsFromData,
    getHomePage,
    getProp,
    UPDATE_PERM,
    userBelongsToType
} from "../../util/util";
import FieldSearch from "../../components/field-search";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import PageHeader from "../../components/layout-dashboard/page-header";

class AilmentsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            viewModalOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (userBelongsToType(this.props.user.data, ["Client"])) {
            this.props.history.push(getHomePage(this.props.user.data));
        } else {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            limit: 10,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleToggleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    handleClientClick = item => {
        this.props.history.push("ailment/" + item.AilmentID);
    }

    handleInputChange = (fields, name, value) => {
        if ("LocationID" === name) {
            const LocationID = this.state.LocationID ;
            fields = FieldsManager.updateField(fields, "PrimaryPhysicianID", null);
            fields.PrimaryPhysicianID.disabled = false;
            if (value != LocationID) {
                this.setState({
                    LocationID: value?.value,
                    remountFields: this.state.remountFields + 1
                })
            }
        }

        return FieldsManager.updateField(fields, name, value);
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "AilmentID";
    }

    getResourceName = () => {
        return Resources.Ailments;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const fieldTemplates = {
            Ailment: new Field("Ailment", '', [''], false, "text"),
            PartOfBodyID: new Field("PartOfBodyID", '', [''], false, "select-search"),
            Summary: new Field("Summary", '', [''], false, "textarea"),
            Details: new Field("Details", '', [''], false, "textarea"),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (includeFields) {
            for (const [key] of Object.entries(fieldTemplates)) {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key];
                }
            }
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto min-h-[calc(100vh-8.75rem)]">
                    <PageHeader
                        title={translate("page.title.ailments")}
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />

                            {checkPerm(this.getResourceName(), CREATE_PERM) && (
                                <div className="ml-auto mb-3">
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.add")}
                                    </button>
                                </div>
                            )}
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.handleClientClick}
                            onDelete={checkPerm(this.getResourceName(), DELETE_PERM) && this.handleToggleConfirmModal}
                        />
                    </div>

                    <ModalSaveResource
                        bodyKey={this.state.remountFields}
                        title={"Add Ailment"}
                        addClass="max-w-xl"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        handleInputChange={this.handleInputChange}
                        fields={this.getFields(null, ["Birthdate", "GenderID", "Address1", "Address2", "City", "StateID", "Zip", "Registered"])}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.Ailment,
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={{
                            PartOfBodyID: {
                                api: 'api/' + Resources.LookupPartOfBody,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.PartOfBodyID,
                                    label: item.PartOfBody
                                })
                            },
                        }}
                    />


                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: Resources.Ailment,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(AilmentsPage);
