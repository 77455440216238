import React, {Component} from "react";
import {Link} from "react-router-dom";
import LocalStorage from "../../util/localStorage";
import moment from "moment";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-secondary-100 min-h-full text-default">
                    <div
                        className="relative z-10 flex-shrink-0 flex h-16 bg-secondary-100 border-b-2 border-primary-600">
                        <div className="flex-1 px-4 sm:px-6 lg:px-8 flex justify-between">
                            <div
                                className="h-16 from-secondary-100 to-inverse flex items-center flex-shrink-0 text-white">
                                <div className="w-48 text-3xl text-primary-500">
                                    <a href="/">
                                        <img src="/images/logos/zenden-logo-black.png" alt="ZenDen"/>
                                    </a>
                                </div>
                            </div>

                            <div className="flex">
                                <nav
                                    className="hidden justify-center lg:pt-6 lg:flex lg:space-x-8"
                                    aria-label="Global"
                                >
                                    {!!this.props.navigation && this.props.navigation}
                                </nav>
                            </div>


                            <div className="w-48 flex items-center">
                                {!!this.props.loginBtn && !LocalStorage.get("user") && (
                                    <Link to="/login"
                                          className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-primary-600 bg-origin-border px-4 py-2 border border-transparent shadow-sm text-base font-semibold text-white hover:from-purple-700 hover:to-primary-700">
                                        Log in
                                    </Link>
                                )}

                            </div>
                        </div>
                    </div>

                    <div>
                        {this.props.children}
                    </div>
                    <div className="bg-primary-700 text-white py-3 text-center text-sm px-4 footer-wrapper-public">
                        <p className="mb-3">©{moment().year()} ZenDen | Developed in partnership with, LUKASA, a modernization business technology consulting firm. </p>

                        <p>
                            All rights reserved.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}