import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {checkPerm, fillFieldsFromData, getProp, UPDATE_PERM} from "../../../util/util";
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import DialogDefault from "../../../components/dialog-default";
import {Field, FieldsManager} from "../../../data/services/fields";
import ResourceFormTab from "../../../components/resource-form-tab";
import ProgressBar from "../../../components/progress-bar";
import QuestionsTab from "./questions-tab";
import {CheckCircleIcon, QuestionMarkCircleIcon} from "@heroicons/react/outline";

export default class ConcussionAssessmentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [],
            submitConfirmModal: false,
            confirmModalOpen: false,
            metadata: {}
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dialogResource !== prevProps.dialogResource && this.props.dialogResource.data && !this.props.dialogResource.isLoading) {
            let item = getProp(this.props, "dialogResource.data", {});

            const tabs = [
                {name: 'Info', resource: Resources.ClientAssessmentsInfo, isDisabled: false, isDone: true, onClick: (resource) => this.handleTabChange(resource)},
                {name: 'Assessment', resource: Resources.ClientConcussionAssessments, isDisabled: false, isDone: !!item.AilmentEdit || item.ClientAssessmentConcussionID, onClick: (resource) => this.handleTabChange(resource)},
                {name: 'Questions', resource: Resources.ClientConcussionAssessmentsQuestions, isDisabled: !item.ClientAssessmentConcussionID && !item.AilmentEdit, isDone: !!item.QuestionEdit && item.QuestionEdit, onClick: (resource) => this.handleTabChange(resource)},
                {name: 'Ailment', resource: Resources.ClientConcussionAssessmentsAilments, isDisabled: !item.QuestionEdit, isDone: !!item.AssessmentDone && item.AssessmentDone, onClick: (resource) => this.handleTabChange(resource)},
            ]

            this.setState({tabs})
        }

        if (!prevProps.visible && this.props.visible) {
            this.setState({
                selectedTab: null
            }, () => {
                // this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.ClientAssessmentsInfo)
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: {
                ClientAssessmentID: this.getId()
            },
            resource: Resources.ClientAssessmentsInfo
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Data events
     ================================================================= */
    handleInputChange = (fields, name, value) => {
        let fieldsTmp = FieldsManager.updateField(fields, name, value);
        if ("AssessmentTypeID" === name) {
            this.setState({
                submitConfirmModal: true
            })
        }

        return fieldsTmp;
    }

    handleInputChangeAssessment = (fields, name, value) => {
        let fieldsTmp = FieldsManager.updateField(fields, name, value);
        if ("Pain" === name) {
            fieldsTmp.AboveTheEyes.type = (value == 1) ? "select" : "hidden";
            fieldsTmp.AboveTheEyes.validate = (value == 1) ? ["empty"] : [''];

            fieldsTmp.GeneralHeadache.type = (value == 1) ? "select" : "hidden";
            fieldsTmp.GeneralHeadache.validate = (value == 1) ? ["empty"] : [''];
        }

        return fieldsTmp;
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getInfoFields = () => {
        let item = getProp(this.props, "dialogResource.data", {});

        const fieldTemplates = {
            AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], true, 'select'),
            AssessmentDate: new Field("AssessmentDate", '', [''], true, 'date'),
            ProviderName: new Field("ProviderName", '', [''], true, 'text'),
            Summary: new Field("Summary", '', [''], !checkPerm(Resources.ClientConcussionAssessments, UPDATE_PERM), 'textarea'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    }

    getAssessmentsFields = () => {
        let item = getProp(this.props, "dialogResource.data", {});

        const fullRowMetadata = {formGroupClass: "col-span-6", labelClass: "block text-sm font-medium text-gray-700"}

        const isDisabled = (!checkPerm(Resources.ClientConcussionAssessments, UPDATE_PERM) || !!item.ClientAssessmentConcussionID);

        const defaultMetadata = {
            formGroupClass: "sm:col-span-3",
            labelClass: "block text-sm font-medium text-gray-700"
        };

        const fieldTemplates = {
            Dizziness: new Field("Dizziness", '', ['empty'], isDisabled, 'select', defaultMetadata),
            Nausea: new Field("Nausea", '', ['empty'], isDisabled, 'select', defaultMetadata),
            LossOfBalance: new Field("LossOfBalance", '', ['empty'], isDisabled, 'select', defaultMetadata),
            Pain: new Field("Pain", '', ['empty'], isDisabled, 'select', fullRowMetadata),

            AboveTheEyes: new Field("AboveTheEyes", '', item?.Pain == 1 ? ['empty'] : [''], isDisabled, item?.Pain == 1 ? 'select' : 'hidden', defaultMetadata),
            GeneralHeadache: new Field("GeneralHeadache", '', item?.Pain == 1 ? ['empty'] : [''], isDisabled,  item?.Pain == 1 ? 'select' : 'hidden', defaultMetadata),

            BlurredVision: new Field("BlurredVision", '', ['empty'], isDisabled, 'select', defaultMetadata),
            SensitivityToLight: new Field("SensitivityToLight", '', ['empty'], isDisabled, 'select', defaultMetadata),
            SensitivityToScreens: new Field("SensitivityToScreens", '', ['empty'], isDisabled, 'select', defaultMetadata),
            NeckStiffness: new Field("NeckStiffness", '', ['empty'], isDisabled, 'select', defaultMetadata),
            Fatigue: new Field("Fatigue", '', ['empty'], isDisabled, 'select', defaultMetadata),
            Disorientation: new Field("Disorientation", '', ['empty'], isDisabled, 'select', defaultMetadata),

            RingingInEars: new Field("RingingInEars", '', ['empty'], isDisabled, 'select', defaultMetadata),
            SlurredSpeech: new Field("SlurredSpeech", '', ['empty'], isDisabled, 'select', defaultMetadata),
            LossOfFocusConcentration: new Field("LossOfFocusConcentration", '', ['empty'], isDisabled, 'select', defaultMetadata, {menuPlacement: "top"}),
            Iritableness: new Field("Iritableness", '', ['empty'], isDisabled, 'select', defaultMetadata, {menuPlacement: "top"}),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getAilmentsFields = () => {
        let item = getProp(this.props, "dialogResource.data", {});

        const fieldTemplates = {
            SuggestedAilments: new Field("SuggestedAilments", '', [''], !!item.UpdatedByContactID, 'custom'),
            AilmentIDs: new Field("AilmentIDs", '', [''], !!item.UpdatedByContactID, 'multi-select-search', {}, {
                isMulti: true
            }),
            Summary: new Field("Summary", '', [''], !!item.UpdatedByContactID, 'textarea'),
            DiagnoseDate: new Field("DiagnoseDate", '', [''], true, 'datetime'),
            ProviderName: new Field("ProviderName", '', [''], true, 'readonly'),
            ClientAssessmentConcussionAilmentID: new Field("ClientAssessmentConcussionAilmentID", '', [''], true, 'hidden'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        const yesNoOptions = {1: "Yes", 2: "No"};

        return (
            <DialogDefault
                title={translate("modal.title.concussion_assessment")}
                widthClass="max-w-[1860px]"
                addClass="min-h-[calc(100vh-3rem)] overflow-auto"
                visible={!!visible}
                blurBackdrop={false}
                onClose={onClose}
                translate={translate}
            >
                <React.Fragment>

                    <ProgressBar
                        tabs={this.state.tabs}
                    />

                    {/* Content */}
                    <div className="mt-10">
                        {this.state.selectedTab === Resources.ClientAssessmentsInfo && (
                            <ResourceFormTab
                                tabTitle={"Info"}
                                id={this.getId()}
                                className="mx-auto"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentID"}
                                handleInputChange={this.handleInputChange}
                                fields={this.getInfoFields()}
                                metadata={{
                                }}
                                disableSave={this.state.tabs[0]?.isDisabled}
                                disableCancel={this.state.tabs[0]?.isDisabled}
                            />
                        )}

                        {this.state.selectedTab === Resources.ClientConcussionAssessments && (
                            <ResourceFormTab
                                tabTitle={translate("tab.ClientConcussionAssessments")}
                                id={this.getId()}
                                className="mx-auto"
                                formClassName="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-6"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}

                                fields={this.getAssessmentsFields()}
                                metadata={{
                                    Dizziness: yesNoOptions,
                                    Nausea: yesNoOptions,
                                    LossOfBalance: yesNoOptions,
                                    Pain: yesNoOptions,
                                    BlurredVision: yesNoOptions,
                                    SensitivityToLight: yesNoOptions,
                                    SensitivityToScreens: yesNoOptions,
                                    NeckStiffness: yesNoOptions,
                                    Fatigue: yesNoOptions,
                                    Disorientation: yesNoOptions,

                                    RingingInEars: yesNoOptions,
                                    SlurredSpeech: yesNoOptions,
                                    LossOfFocusConcentration: yesNoOptions,
                                    Iritableness: yesNoOptions,

                                    AboveTheEyes: yesNoOptions,
                                    GeneralHeadache: yesNoOptions
                                }}
                                disableSave={this.state.tabs[1]?.isDisabled}
                                disableCancel={this.state.tabs[1]?.isDisabled}
                                handleInputChange={this.handleInputChangeAssessment}
                            />
                        )}

                        {this.state.selectedTab === Resources.ClientConcussionAssessmentsQuestions && (
                            <QuestionsTab
                                tabTitle={"Questions"}
                                id={this.getId()}
                                formClassName="grid grid-cols-1 gap-4"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentPainQuestionID"}
                                handleInputChange={this.handleInputChange}
                                metadata={this.state.metadata}
                                disableSave={this.state.tabs[1]?.isDisabled}
                                disableCancel={this.state.tabs[1]?.isDisabled}
                            />
                        )}

                        {this.state.selectedTab === Resources.ClientConcussionAssessmentsAilments && (
                            <ResourceFormTab
                                tabTitle={"Ailment"}
                                id={this.getId()}
                                className="mx-auto"
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.dialogResource}
                                resourceName={this.state.selectedTab}
                                primaryKeyName={"ClientAssessmentConcussionAilmentID"}
                                fields={this.getAilmentsFields()}
                                metadata={{
                                    AilmentIDs: {
                                        api: 'api/' + Resources.Ailments,
                                        query: {
                                            searchFields: JSON.stringify({PartOfBodyID: getProp(this.props, "dialogResource.data.PartOfBodyID", 0)})
                                        },
                                        searchMap: (item) => ({
                                            value: item.AilmentID,
                                            label: item.Ailment
                                        })
                                    },
                                    SuggestedAilments: (fields, handleInputChange) => {
                                        let SuggestedAilments = fields.find(it => it.name === "SuggestedAilments")
                                        let Ailments = fields.find(it => it.name === "AilmentIDs")

                                        const keywords = Array.isArray(SuggestedAilments.value) && getProp(SuggestedAilments, "value", []).map((item) => {
                                            let isSelected = false
                                            if (Ailments.value.some(it => it.value == item.AilmentID)) isSelected = true

                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (!getProp(this.props, "dialogResource.data.UpdatedByContactID", 0)) {
                                                            if (isSelected) {
                                                                Ailments.value = Ailments.value.filter(it => it.value != item.AilmentID)
                                                            } else {
                                                                Ailments.value = [...Ailments.value, {
                                                                    value: item.AilmentID,
                                                                    label: item.Ailment,
                                                                }]
                                                            }
                                                            handleInputChange("AilmentIDs", Ailments.value);
                                                        }
                                                    }}
                                                    className={"hover:cursor-pointer border-2 border-secondary-300 flex"}>
                                                    {isSelected && (
                                                        <span>
                                                           <CheckCircleIcon className="text-green-400 w-7 h-7 mt-3 inline-block ml-2 mr-2"
                                                                            aria-hidden="true"/>
                                                        </span>
                                                    )}
                                                    {!isSelected && (
                                                        <span>
                                                           <QuestionMarkCircleIcon className="text-secondary-400 mt-3 w-7 h-7 inline-block ml-2 mr-2"
                                                                                   aria-hidden="true"/>
                                                        </span>
                                                    )}
                                                    <span className="flex-1 text-lg pl-2 pr-2 pt-3 pb-2 border-r-2 border-secondary-300 font-bold">
                                                        {item.Ailment}
                                                    </span>
                                                    <span className=" flex-0 text-lg pl-2 pr-2 pt-1 pb-2 border-r-2 border-secondary-300">
                                                        <img className="inline-block h-10 w-20 object-cover" src={"/images/gauge/gauge-" + item.MatchGaugeID + ".svg"} title={item.Match + " %"}/>
                                                    </span>
                                                    <span className=" flex-1 text-sm pl-2 pr-2 pt-1 pb-2">
                                                        {item.MatchDesc}
                                                    </span>
                                                </div>
                                            )
                                        })
                                        return (
                                            <div className={'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5'}>
                                                <label
                                                    className="block text-sm font-semibold text-secondary-700 sm:mt-px sm:pt-2">
                                                    {translate("field.SuggestedAilments")}
                                                </label>
                                                <div className={'sm:col-span-2'}>{keywords}</div>
                                            </div>
                                        )
                                    }
                                }}
                                onSubmit={(params) => {
                                    params.AilmentIDs = params.AilmentIDs.join(', ')
                                    this.props.dispatch(updateDialogResource({
                                        user: LocalStorage.get("user"),
                                        query: {id: this.getId()},
                                        params: params,
                                        resource: Resources.ClientConcussionAssessmentsAilments,
                                        piggyResource: Resources.ClientConcussionAssessmentsAilments
                                    }));
                                }}
                                disableSave={this.state.tabs[2]?.isDisabled || getProp(this.props, "dialogResource.data.UpdatedByContactID", 0)}
                                disableCancel={this.state.tabs[2]?.isDisabled || getProp(this.props, "dialogResource.data.UpdatedByContactID", 0)}
                            />
                        )}
                    </div>
                </React.Fragment>
            </DialogDefault>
        )
    }
}
