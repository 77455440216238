import React, {Component} from 'react'
import {
    BriefcaseIcon,
    DocumentTextIcon,
    LocationMarkerIcon,
    PencilIcon,
    PhoneIcon,
} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    CLIENT_ASSESSMENT_STATUS_ID,
    getHomePage,
    getProp,
    READ_PERM, stateToCrud,
    userBelongsToType
} from "../../util/util";
import {getSecondResource} from "../../data/actions/secondResource";
import {Field} from "../../data/services/fields";
import NavResponsive from "../../components/nav-responsive";
import PageHeader from "../../components/layout-dashboard/page-header";
import ResourceListTab from "../../components/resource-list-tab";
import Loader from "../../components/loader";
import ExtClientModal from "./ext-client-modal";
import IntakeAssessmentModal from "../client-single-page/intake-assessment-modal";
import PainAssessmentModal from "../client-single-page/pain-assessment-modal";
import OneOffAssessmentModal from "../client-single-page/one-off-assessment-modal";
import ExtClientAppointmentNotesModal from "./ext-client-appointment-notes-modal";
import ConcussionAssessmentModal from "../client-single-page/concussion-assesment-modal";

class ClientProfilePage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Appointments', resource: Resources.ExtClientAppointments},
            {name: "Assessments", resource: Resources.ExtClientAssessments},
            {name: 'Ailments', resource: Resources.ExtClientAilments},
            {name: 'Tests', resource: Resources.ExtClientTests},
            {name: 'Plans', resource: Resources.ExtClientPlans},
            {name: 'Notes', resource: Resources.ExtClientNotes},
        ].filter((it) => checkPerm(it.resource, READ_PERM));
        tabs[0].current = true;

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,

            intakeAssessmentModal: false,
            painAssessmentModal: false,
            oneOffAssessmentModal: false,
            concussionAssessmentModal: false,
            editModalOpen: false,
            clientAppointmentModalOpen: false,
            clientAppointmentNotesModalOpen: false
        };

        this.assessmentTabRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (!userBelongsToType(this.props.user.data, ["Client"])) {
            this.props.history.push(getHomePage(this.props.user.data));
        } else {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.ExtClient
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        })
    };

    handleToggleAssessmentModal = (item = null) => {
        switch (item.AssessmentTypeID) {
            case 1:
                // Intake Assessment
                this.handleToggleAssessmentModalHelper(item, 'intakeAssessmentModal')
                break;
            case 2:
                // Pain Assessment
                this.handleToggleAssessmentModalHelper(item, 'painAssessmentModal')
                break;
            case 4:
                // One-Off Assessment
                this.handleToggleAssessmentModalHelper(item, 'oneOffAssessmentModal')
                break;
            case 5:
                // Concussion Assessment
                this.handleToggleAssessmentModalHelper(item, 'concussionAssessmentModal');
                break;
            default:
                break
        }
    }

    handleToggleAssessmentModalHelper = (item, state) => {
        this.setState({
            selectedItem: item,
            [state]: !this.state[state]
        }, () => {
            if (!this.state[state] && (this.state.selectedTab === Resources.ClientAssessments)) {
                this.assessmentTabRef?.current?.fetchData();
            }
        });
    }

    handleToggleClientAppointmentNotesView = (item = null) => {
        this.setState({
            selectedItem: item,
            clientAppointmentNotesModalOpen: !this.state.clientAppointmentNotesModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getQuery = () => {
        return {
            id: this.getId(),
            ...(stateToCrud(this.state))
        }
    };

    returnAilments = (item) => {
        return getProp(item, "Ailments.Ailments", []).map(it => {

            return <>
                {it.Ailment}
                <br/>
            </>
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const Client = getProp(this.props, "secondResource.data", {});

        const isLoading = getProp(this.props, "secondResource.isLoading", null)

        const clientLocation = [];

        for (const [key, value] of Object.entries(Client)) {
            if (["Address1", "City", "StateID", "Zip"].includes(key) && value) {
                clientLocation.push(value)
            }
        }

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.client")}
                >
                    <button
                        onClick={this.handleToggleEditModal}
                        className="btn btn-primary mt-5 sm:mt-0"
                    >
                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                        Edit
                    </button>
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between"
                    >
                        {(!!isLoading) && (
                            <Loader/>
                        )}

                        {(!isLoading) && (
                            <React.Fragment>
                                {!Client.ImagePath && (
                                    <div
                                        className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                        <div
                                            className="text-inverse font-black">{(Client.FirstName + " " + Client.LastName + "").split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                    </div>
                                )}
                                {Client.ImagePath && (
                                    <div
                                        className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none overflow-hidden">
                                    </div>
                                )}

                                <div className="flex-1 min-w-0">
                                    <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                        {Client.FirstName + " " + Client.LastName}
                                    </h1>

                                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                        {(Client.AreaCode || Client.Phone || Client.PhoneExtension) && (
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">

                                                <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                           aria-hidden="true"/>
                                                {Client.AreaCode} {Client.Phone} {Client.PhoneExtension}
                                            </div>
                                        )}

                                        {!!clientLocation.length && (
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <LocationMarkerIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"
                                                />
                                                {clientLocation.join(', ')}
                                            </div>
                                        )}

                                        {Client.OrganizationType && (
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <BriefcaseIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"
                                                />

                                                {Client.OrganizationType}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mt-5 flex xl:mt-0 xl:ml-4">
                                    <span className="hidden sm:block">

                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </header>

                <main className="pb-16 relative custom-min-h-tab bg-inverse">
                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("tab." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>

                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                    onChange={e => this.handleTabChange(e.target.value)}
                                >
                                    {this.state.tabs.map((tab) => (
                                        <option
                                            key={tab.name}
                                            value={tab.resource}
                                        >
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="border-b-2 border-primary-500 mt-6"></div>
                            </div>

                            <div className="hidden sm:block">
                                <div className="border-b border-secondary-200">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* Content */}
                        <div className="mt-6">
                            {this.state.selectedTab === Resources.ExtClientAssessments && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dialogTitle={"Assessment"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    onView={(item) => this.handleToggleAssessmentModal(item)}

                                    sort={"DESC"}
                                    sortBy={"AppointmentDate"}
                                    primaryKey={"ClientAssessmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AssessmentTypeID: new Field("AssessmentTypeID", '', ['empty'], false, 'select'),
                                        ClientAssessmentStatusID: new Field("ClientAssessmentStatusID", '', ['empty'], false, 'select', {
                                            render: (item) => (
                                                <td className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12'}>
                                                    {CLIENT_ASSESSMENT_STATUS_ID[item.ClientAssessmentStatusID]}
                                                </td>
                                            ),
                                            hideDialog: true,
                                        }),
                                        Ailments: new Field("Ailments", '', ['empty'], false, 'custom', {
                                            render: (item) => (
                                                <td className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12'}>
                                                    {this.returnAilments(item)}
                                                </td>
                                            ),
                                            omitSort: true,
                                            hideDialog: true
                                        }),
                                        AppointmentDate: new Field("AppointmentDate", '', [''], false, 'date'),
                                        ProviderName: new Field("ProviderName", '', [''], false, 'text', {
                                            hideDialog: true
                                        }),
                                        Summary: new Field("Summary", '', [''], false, 'textarea'),
                                    }}
                                    searchFields={{
                                        StartDate: new Field("StartDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        }),
                                        EndDate: new Field("EndDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        })
                                    }}
                                    ref={this.assessmentTabRef}
                                    metadata={{
                                        ClientAssessmentStatusID: CLIENT_ASSESSMENT_STATUS_ID
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtClientAppointments && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dialogTitle={"Appointment"}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sort={"DESC"}
                                    sortBy={"AppointmentDate"}
                                    primaryKey={"ClientAppointmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AppointmentTypeID: new Field("AppointmentTypeID", '', ['empty'], false, 'select'),
                                        AppointmentDate: new Field("AppointmentDate", '', [''], false, 'date'),
                                        AppointmentStatusID: new Field("AppointmentStatusID", '', [''], false, 'select'),
                                        Cost: new Field("Cost", '', [''], false, 'text'),
                                        Description: new Field("Description", '', [''], false, 'textarea')
                                    }}

                                    searchFields={{
                                        StartDate: new Field("StartDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        }),
                                        EndDate: new Field("EndDate", '', [''], false, 'date', {}, {
                                            isClearable: true
                                        }),
                                        AppointmentStatusID: new Field("AppointmentStatusID", '', [''], false, 'select', {}, {
                                            isClearable: true
                                        }),
                                    }}

                                    onCustomAction={(it) => {
                                      return (
                                          <button
                                              onClick={() => this.handleToggleClientAppointmentNotesView(it)}
                                              type="button"
                                              className="ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                          >
                                              <DocumentTextIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                                aria-hidden="true"/>
                                              <span className="hidden md:inline">Notes</span>
                                          </button>
                                      )
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtClientTests && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Test"}
                                    sortBy={"TestTypeID"}
                                    primaryKey={"TestTypeID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        TestTypeID: new Field("TestTypeID", '', ['empty'], false, 'select'),
                                        TestDate: new Field("TestDate", '', [''], false, 'date'),
                                        Summary: new Field("Summary", '', [''], false, 'textarea'),
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtClientAilments && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Ailment"}
                                    sortBy={"AilmentID"}
                                    primaryKey={"ClientAilmentID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        AilmentID: new Field("AilmentID", '', ['empty'], false, 'select-search'),
                                        AilmentDate: new Field("AilmentDate", '', [''], false, 'date'),
                                        Active: new Field("Active", '', [''], false, 'checkbox'),
                                        Description: new Field("Description", '', [''], false, 'textarea'),
                                    }}
                                    metadata={{
                                        AilmentID: {
                                            api: 'api/' + Resources.Ailments,
                                            query: {},
                                            searchMap: (item) => ({
                                                value: item.AilmentID,
                                                label: item.Ailment
                                            })
                                        },
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtClientPlans && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Plan"}
                                    sortBy={"ClientPlanID"}
                                    primaryKey={"ClientPlanID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        PlanTypeID: new Field("PlanTypeID", '', ['empty'], false, 'select'),
                                        PlanDate: new Field("PlanDate", '', [''], false, 'date'),
                                        PlanFrequency: new Field("PlanFrequency", '', [''], false, 'text'),
                                        PlanStatusID: new Field("PlanStatusID", '', [''], false, 'select'),
                                        Description: new Field("Description", '', [''], false, 'text')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtClientNotes && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableDefaultEdit={true}
                                    disableDefaultDelete={true}
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    dialogTitle={"Note"}
                                    sortBy={"NoteTitle"}
                                    primaryKey={"ClientNoteID"}
                                    resourceName={this.state.selectedTab}
                                    fields={{
                                        NoteTitle: new Field("NoteTitle", '', ['empty'], false, 'text'),
                                        Description: new Field("Description", '', ['empty'], false, 'textarea')
                                    }}
                                />
                            )}

                        </div>
                    </div>

                    <ExtClientModal
                        {...this.props}
                        id={this.getId()}
                        visible={!!this.state.editModalOpen}
                        onClose={this.handleToggleEditModal}
                    />

                    <IntakeAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.intakeAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <PainAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.painAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <OneOffAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.oneOffAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <ConcussionAssessmentModal
                        {...this.props}
                        id={this.state.selectedItem?.ClientAssessmentID}
                        visible={!!this.state.concussionAssessmentModal}
                        onClose={() => this.handleToggleAssessmentModal(this.state.selectedItem)}
                    />

                    <ExtClientAppointmentNotesModal
                        {...this.props}
                        item={this.state.selectedItem}
                        visible={!!this.state.clientAppointmentNotesModalOpen}
                        onClose={this.handleToggleClientAppointmentNotesView}
                    />

                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ClientProfilePage);